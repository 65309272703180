import { Room } from 'V3Models/Domain/Room';
import SearchEntitiesRequest, { SiteWithTZ } from 'V3Models/EntitiesService/SearchEntitiesRequest';
import EntitiesServiceApiClient from 'V3Services/EntitiesService/EntitiesServiceApiClient';
import ErrorHandlingService from 'V3Services/ErrorHandlingService';
import SiteService from 'V3Services/SiteService';
import appointment from 'V3Stores/appointment';
import bookingActions from 'V3Stores/Thunks';
import bookingForm from 'V3Stores/booking';
import filters from 'V3Stores/filters';
import navigation from 'V3Stores/navigation';
import organisationalStructure from 'V3Stores/organisationalStructure';
import { LOADING_STATUS } from 'V3Utilities/constants';
import sortAreasRaw from 'V3Utilities/sortAreasRaw';
import sortEquipmentTypes from 'V3Utilities/sortEquipmentTypes';
import { AppThunk } from '..';

export const loadOrganisationStructureData = (): AppThunk => async (dispatch, getState) => {    
    dispatch(organisationalStructure.store.referenceDataLoadStart());

    try {
        await loadSites(dispatch, getState);
        dispatch(organisationalStructure.store.referenceDataLoadEnd());

        if (bookingForm.store.getIsEdit(getState())) {
            dispatch(bookingActions.populateBookingData());
        }

    } catch (e) {
        ErrorHandlingService.HandleSystemError('RDA/LRD');
    }
};

const loadSites = (dispatch, getState) =>
    new Promise(async (resolve) => {
        try {
            const sitesLoadingStatus = organisationalStructure.store.getSitesLoadingStatus(getState());
            if (sitesLoadingStatus === LOADING_STATUS.REQUIRED) {
                const sites = await SiteService.getSites();
                dispatch(organisationalStructure.store.sitesLoadComplete(sites));

                // if not editing a booking, select default site
                if (!bookingForm.store.getIsEdit(getState()) || organisationalStructure.store.getSelectedSites(getState()).length == 0) {
                    const defaultSite = sites.find((item) => item.isDefault);
                    if (defaultSite) {
                        dispatch(organisationalStructure.store.setSelectedSites([defaultSite.siteID]));
                    }
                }
            }
            resolve(true);
            await loadRooms(dispatch, getState);
        } catch (e) {
            ErrorHandlingService.HandleSystemError('RDA/LSI', e);
        }
    });

export const setAreaSelection =
    (rooms: Room[]) =>
    async (dispatch, getState): Promise<any> => {
        const state = getState();
        const SelectedSites = organisationalStructure.store.getSelectedSiteIDs(state)?.join();

        try {
            if (SelectedSites?.length > 0) {
                const areas = sortAreasRaw(rooms);
                dispatch(filters.store.setSelectedAreas(areas));
                const equipmentTypes = sortEquipmentTypes(rooms);
                dispatch(filters.store.setEquipmentTypes(equipmentTypes));
            }
        } catch (e) {
            ErrorHandlingService.HandleSystemError('RDA/LFR', e);
        }
    };

const loadRooms = (dispatch, getState) =>
    new Promise(async (resolve) => {
        try {
            const roomsLoadingStatus = organisationalStructure.store.getRoomsLoadingStatus(getState());

            if (roomsLoadingStatus === LOADING_STATUS.REQUIRED) {
                const attendeeCount = filters.store.getSelectedAttendees(getState());

                const selectedSites = organisationalStructure.store.getSelectedSites(getState())
                                      .map((s) => <SiteWithTZ>{siteId: s.siteID, timezone: s.timeZoneName});

                const appointmentInfo = appointment.store.getAppointment(getState());

                const request = <SearchEntitiesRequest>{
                    startDTM: appointmentInfo?.startDTMUTC.toISO() ?? '',
                    endDTM: appointmentInfo?.endDTMUTC.toISO() ?? '',
                    attendeeCount,
                    sites: selectedSites
                }

                //console.log(' search entities request >>> ', { ...request });
                const response = await EntitiesServiceApiClient.search(request);
                //console.log(' search entities Results >>> ', { ...response });
                const rooms = response.results.map(
                    (res) =>
                        <Room>{
                            areaDisplayOrder: res.areaDisplayOrder,
                            areaID: res.areaID,
                            areaName: res.areaname,
                            capacity: res.capacity,
                            equipment: res.equipment,
                            isFilterMatch: true,
                            isSelected: false,
                            roomID: res.itemID,
                            roomName: res.itemName,
                            siteID: res.siteID,
                            siteName: res.siteName,
                            restrictedTo: res.restrictedUsers,
                            effectiveMaxBookingDateUTC: res.effectiveMaxBookingDate,
                            itemDisplayOrder: res.itemDisplayOrder,
                            disabledAccess: res.disabledAccess,
                            resourceImageId: res.resourceImageId,
                            description: res.description
                        }
                );
                dispatch(organisationalStructure.store.roomsLoadComplete(rooms));
                dispatch(setAreaSelection(rooms));
            }
            resolve(true);
        } catch (e) {
            ErrorHandlingService.HandleSystemError('RDA/LR', e);
        }
    });

export const onChangeAttendee =
    ({ value }) =>
    (dispatch) => {
        dispatch(bookingActions.cancelExistingBookingReservations());
        dispatch(filters.store.filtersSetAttendees(value));
        dispatch(bookingForm.store.setBookingAttendees(value));
        dispatch(organisationalStructure.store.roomsLoadReset());
        dispatch(organisationalStructure.store.referenceDataLoadReset());
        dispatch(bookingForm.store.resetSelectedRoom());
        dispatch(navigation.actions.setCreateBookingTabs());
    };
