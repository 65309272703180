import React from 'react';
import forms from 'V3Components/Forms';
import OnBehalfOf from 'V3Components/Forms/OnBehalfOf';
import LocalisationService from 'V3Services/LocalisationService';
import { TextBox } from '../../common';
import { BookingDetailsProps } from '.';
import local from './local.scss';

const BookingForm = ({
    capacity,
    booking,
    roomPermissions,
    onBehalfOfSelector,
    setBookingAttendees,
    setBookingNotes,
    setBookingFormOnBehalfOf,
}: BookingDetailsProps): JSX.Element => {
    
    const localisationService = LocalisationService.getInstance();    
    return (
        <div className={local.container}>
            <forms.components.AttendeeCount
                id={forms.constants.FORM_FIELDS.ATTENDEE_COUNT}
                selector={() => booking.attendeesCount}
                capacity={capacity}
                className={local.formItem}
                value={booking?.attendeesCount}
                onChange={(value) => setBookingAttendees(value)}
            />
            {roomPermissions.BookOnBehalf ? (
                <div className={local.formItem}>
                    <OnBehalfOf
                        idName='onBehalfOf'
                        id='onBehalfOfSelector'
                        onChange={(onBehalfOf) => {
                            setBookingFormOnBehalfOf(onBehalfOf);
                        }}
                        selector={(state) => onBehalfOfSelector(state)}
                        className=""
                        value={booking.onBehalfOf}
                    />
                </div>
            ) : null}
            {/* notes */}
            <TextBox
                label={localisationService.strings.notes}
                name="notes"
                className={local.formItem}
                rows={4}
                value={booking?.notes}
                onChange={(value) => setBookingNotes(value)}
                id={'bookingForm__textbox'}
            />
        </div>
    );
};

export default BookingForm;
