import LocalisedStrings from 'V3/utilities/LocalisedStrings';
import UIStrings from 'V3Utilities/UIStrings';
import { LanguageStrings } from 'V3Utilities/CBEnums';
import settingsUtil from 'V3Utilities/settingsUtil';

export default class LocalisationService {
    private static instance: LocalisationService;

    private defaultLanguageString = 'en-GB';

    public static getInstance(): LocalisationService {
        if (!LocalisationService.instance) {
            LocalisationService.instance = new LocalisationService();
        }

        return LocalisationService.instance;
    }

    public strings: UIStrings;

    constructor() {
        const language = this.getLanguage();
        this.setLanguageStrings(language);
    }

    private setLanguageStrings = (language: string): void => {
        switch (language) {
            case LanguageStrings.EnglishUnitedState:
                this.strings = LocalisedStrings.EN;
                break;
            default:
                this.strings = LocalisedStrings.EN;
                break;
        }
    };

    public getLanguage = (): string => {
        try {
            return localStorage.getItem(settingsUtil.constants.LANGUAGE_STORAGE_KEY);
        } catch (ex) {
            return null;
        }
    };

    public setLanguage = (langKey: string): void => {
        const langStorageKey = langKey || this.defaultLanguageString;
        this.isValidLanguage(langStorageKey);
        localStorage.setItem(settingsUtil.constants.LANGUAGE_STORAGE_KEY, langStorageKey);
        this.setLanguageStrings(langStorageKey);
    };

    public removeLanguage = (): void => {
        localStorage.removeItem(settingsUtil.constants.LANGUAGE_STORAGE_KEY);
    };

    private isValidLanguage = (langKey: string): void => {
        const objectValues = Object.values(LanguageStrings);
        const isValid = objectValues.includes(langKey as LanguageStrings);

        if (!isValid) throw new Error('You must supply a valid language');
    };
}
