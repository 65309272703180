import { connect } from 'react-redux';
import { PersonInterface } from 'V3Models/PersonSearchService/PersonSearchItem';
import bookingForm from 'V3Stores/booking';
import { RootState } from 'V3Stores/index';
import OnBehalfOf from './OnBehalfOf';

interface OwnProps {
    id: string;
    idName:string;
    selector: (state: RootState) => PersonInterface;
    onChange: (onBehalfOf: any) => void;
    className: string;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
    return {
        value: ownProps.selector(state),
        ...ownProps,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onChange: (onBehalfOf) => dispatch(bookingForm.actions.setBookingsOnBehalfOf(onBehalfOf)),
    };
};

export type OnBehalfOfProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(OnBehalfOf);
