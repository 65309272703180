import { AuthSchemesSelectionInterface, CBLoginErrorInterface, TenantsSelectionInterface } from 'V3Stores/auth/types';
import { RootState } from 'V3Stores/index';
import * as constants from 'V3Utilities/constants';

export const getInProgress = (state: RootState): boolean => state[constants.REDUCER_NAMES.AUTH].inProgress;
export const getTenantsSelection = (state: RootState): TenantsSelectionInterface => state[constants.REDUCER_NAMES.AUTH].tenantsSelection;
export const getUsername = (state: RootState): string => state[constants.REDUCER_NAMES.AUTH].username;
export const getCBLoginError = (state: RootState): CBLoginErrorInterface => state[constants.REDUCER_NAMES.AUTH].CBLoginError;
export const getIsCBLoginSuccess = (state: RootState): boolean => state[constants.REDUCER_NAMES.AUTH].isCBLoginSuccess;
export const getIsOfficeLoginError = (state: RootState): boolean => state[constants.REDUCER_NAMES.AUTH].isOffice365LoginError;
export const getIsOfficeLoginSuccess = (state: RootState): boolean => state[constants.REDUCER_NAMES.AUTH].isOffice365LoginSuccess;
export const getAuthSchemeSelection = (state: RootState): AuthSchemesSelectionInterface => state[constants.REDUCER_NAMES.AUTH].authSchemesSelection;
