import React, { useState } from 'react';
import { groupBy, orderBy } from 'lodash-es';
import PubSub from 'pubsub-js';
import { Site } from 'V3Models/Domain/Site';
import { CheckBox } from '../../common';
import local from './local.scss';
import { PUB_SUB_TOPICS } from 'V3Utilities/constants';

const SiteSelectorSitesList = ({ items, selectedSites, onSitesSelect }: SiteSelectorSitesListInterface): JSX.Element => {
    const [selected, setSelected] = useState<string[]>(selectedSites);

    PubSub.unsubscribe(PUB_SUB_TOPICS.SITE_SELECTOR_SAVE);
    PubSub.unsubscribe(PUB_SUB_TOPICS.SITE_SELECTOR_RESET);
    PubSub.subscribe(PUB_SUB_TOPICS.SITE_SELECTOR_SAVE, () => onSitesSelect(selected));
    PubSub.subscribe(PUB_SUB_TOPICS.SITE_SELECTOR_RESET, () => setSelected([...selectedSites]));

    const groupedSites = orderBy(
        Object.values(groupBy(items, 'siteGroupID')).map((groupedList) => ({
            groupId: groupedList[0].siteGroupID,
            groupName: groupedList[0].siteGroup,
            items: [...groupedList],
        })),
        ['groupName'],
        'asc'
    );

    const onSelectToggle = (siteId) => {
        const selectedItems = [...selected.filter((item) => item !== siteId), !selected.includes(siteId) ? siteId : undefined].filter(
            (item) => item !== undefined
        );
        setSelected(selectedItems);
    };

    return (
        <>
            <h1 id="site-selector-list__h4_select-sites">Select sites</h1>
            {groupedSites.map((groupedSiteList) => (
                <div key={groupedSiteList.groupId} className={local.siteListContainer}>
                    <h4 id={`site-selector-list__h4_${groupedSiteList}`}>{groupedSiteList.groupName}</h4>
                    {groupedSiteList.items.map((item) => (
                        <CheckBox
                            key={`item_${item.siteID}`}
                            name={`item_${item.siteID}`}
                            checked={selected.includes(item.siteID)}
                            label={item.siteName}
                            onChange={() => onSelectToggle(item.siteID)}
                            idName={`item_${item.siteID}`}
                        />
                    ))}
                </div>
            ))}
        </>
    );
};

export default SiteSelectorSitesList;

export interface SiteSelectorSitesListInterface {
    items: Site[];
    selectedSites: string[];
    onSitesSelect: Function;
}
