import React from 'react';
import AppointmentInfo from './AppointmentInfo';
import BookingForm from './BookingForm';
import SelectedRoomPanel from './SelectedRoomPanel';

const BookingDetails = () : JSX.Element => {

    return (
        <>
            <h1 id='booking-details__h1_details'>Details</h1>
            <AppointmentInfo />
            <SelectedRoomPanel />
            <BookingForm />
        </>
    );
};

export default BookingDetails;
