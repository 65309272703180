import React from 'react';
import classNames from 'classnames';

import local from './local.scss';

export interface LabelProps {
    text: string;
    className?: string;
    id?: string;
}

const Label = (props: LabelProps): JSX.Element => {
    const { text, className, id } = props;
    return <div id={id} className={classNames(local.label, className)}>{text}</div>;
};

export default Label;
