import { ACTION_TYPES, SetIsWebClientAction, SetRouteAction } from './types';

export const setRoute = (route: string): SetRouteAction => ({
    type: ACTION_TYPES.SET_ROUTE,
    route
});

export const setIsWebClient = (isWebClient: boolean): SetIsWebClientAction => ({
    type: ACTION_TYPES.SET_IS_WEB_CLIENT,
    isWebClient
});
