import AppTenant from 'V3Models/TenantsService/AppTenant';
import AuthScheme from 'V3Models/TenantsService/AuthScheme';
import {
    ACTION_TYPES,
    LoginAuthSchemesSelectionActiveAction,
    LoginAuthSchemesSelectionDoneAction,
    LoginCBFailAction,
    LoginCBSuccessAction,
    LoginLoadingAction,
    LoginTenantsSelectionActiveAction,
    LoginTenantsSelectionDoneAction,
    LogoutAction,
    OptionType,
    SetLoginTenantsSelectionIsActiveAction,
} from 'V3Stores/auth/types';

export const setLoginCBSuccess = (payload: { username: string }): LoginCBSuccessAction => ({
    type: ACTION_TYPES.LOGIN_CB_SUCCESS,
    payload,
});

export const setLoginAuthSchemesSelectionActive = (payload: {
    authSchemes: AuthScheme[];
    authSchemesOptions: OptionType[];
}): LoginAuthSchemesSelectionActiveAction => ({
    type: ACTION_TYPES.LOGIN_AUTH_SCHEMES_SELECTION_ACTIVE,
    payload,
});

export const setLoginAuthSchemesSelectionDone = (): LoginAuthSchemesSelectionDoneAction => ({
    type: ACTION_TYPES.LOGIN_AUTH_SCHEMES_SELECTION_DONE,
});

export const setLoginTenantsSelectionActive = (payload: {
    tenants: AppTenant[];
    tenantsOptions: OptionType[];
}): LoginTenantsSelectionActiveAction => ({
    type: ACTION_TYPES.LOGIN_TENANTS_SELECTION_ACTIVE,
    payload,
});

export const setLoginTenantsSelectionIsActive = (payload: {isActive: boolean}): SetLoginTenantsSelectionIsActiveAction => ({
    type: ACTION_TYPES.LOGIN_TENANTS_SELECTION_IS_ACTIVE,
    payload
})

export const setLoginTenantsSelectionDone = (payload: { tenant: AppTenant }): LoginTenantsSelectionDoneAction => ({
    type: ACTION_TYPES.LOGIN_TENANTS_SELECTION_DONE,
    payload
});

export const setLoginLoading = (loading: boolean): LoginLoadingAction => ({
    type: ACTION_TYPES.LOGIN_LOADING,
    payload: { loading },
});

export const setLoginCBFailed = (error: string): LoginCBFailAction => ({
    type: ACTION_TYPES.LOGIN_CB_FAIL,
    payload: { isCBLoginError: true, type: error },
});

export const logout = (): LogoutAction => ({
    type: ACTION_TYPES.LOGOUT,
});
