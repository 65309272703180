import React, { useEffect } from 'react';
import TimezoneService from 'V3Services/TimezoneService';
import { DateTimeField, Progress, TextBox } from '../../common';
import { AppointmentInfoProps } from '.';
import local from './local.scss';

const AppointmentInfo = ({
    appointment,
    focusedSite,
    setBookingsTitle,
    selectedRoomID,
    is24HoursTimeFormat,
    roomBookingInfo,
}: AppointmentInfoProps): JSX.Element => {
    const { startDTMUTC, endDTMUTC, title } = appointment;
    const timeZoneService = TimezoneService.getInstance();
    const outlookTimeZone = Office.context.mailbox.userProfile.timeZone;
    const outlookTimeZoneIANA = timeZoneService.getIANAFromWindows(outlookTimeZone);
    const outlookStartTime = timeZoneService.getSiteTimeFromUTC(startDTMUTC, outlookTimeZoneIANA);
    const outlookEndTime = timeZoneService.getSiteTimeFromUTC(endDTMUTC, outlookTimeZoneIANA);
    
    useEffect(() => {
        setBookingsTitle(title);
    }, [selectedRoomID, title]);

    const SiteDateTime = (): JSX.Element => {
        
        if (!roomBookingInfo) {
            return <Progress size="small" id="AppointmentInfo__ProgressIcon" />;
        }

        const { timeZoneIANAName } = focusedSite;
        const siteStartTime = timeZoneIANAName ? timeZoneService.getSiteTimeFromUTC(startDTMUTC, timeZoneIANAName) : startDTMUTC;
        const siteEndTime = timeZoneIANAName ? timeZoneService.getSiteTimeFromUTC(endDTMUTC, timeZoneIANAName) : endDTMUTC;
    
        return (
            <>
                <DateTimeField
                    label={`Site Time - ${timeZoneIANAName}`}
                    name="BookingDateTimeSite"
                    value={{ siteStartDTM: siteStartTime, siteEndDTM: siteEndTime }}
                    className={local.appointmentInfoItem}
                    timeFormatIs12Hr={!is24HoursTimeFormat}
                    idPrefix="BookingDateTimeSite"
                />

                {timeZoneIANAName !== outlookTimeZoneIANA && (
                    <DateTimeField
                        label={`Outlook Time - ${outlookTimeZone}`}
                        name="BookingDateTime"
                        value={{ siteStartDTM: outlookStartTime, siteEndDTM: outlookEndTime }}
                        className={local.appointmentInfoItem}
                        timeFormatIs12Hr={!is24HoursTimeFormat}
                        idPrefix="BookingDateTime"
                    />
                )}
            </>
        );
    };

    return (
        <div className={local.appointmentInfo}>
            <TextBox
                name="BookingTitle"
                label="Title"
                onChange={(value) => setBookingsTitle(value)}
                value={title}
                className={local.appointmentInfoItem}
                isDisabled
                id="BookingDateTime__textbox"
            />
            <SiteDateTime />
        </div>
    );
};

export default AppointmentInfo;
