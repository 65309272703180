import React from 'react';

import local from './buttonBar.scss';


interface ButtonBarProps{
    children: any;
    id?: string;
}

const ButtonBar = ({ children, id }: ButtonBarProps): JSX.Element => {
    if (!children) {
        return null;
    }

    return <div id={id} className={local.buttonBar}>{children}</div>;
};

export default ButtonBar;
