import React from 'react';
import classnames from 'classnames';
import InputWrapper, { InputWrapperProps } from '../InputWrapper';
import local from './local.scss';

const lineHeightInPixels = 1.4 * 16;
const paddingHeightInPixels = 2 * 0.75 * 16;
const delayUpdate = 2000;

export interface TextBoxProps extends InputWrapperProps {
    error?: string;
    value: string;
    maxLength?: number;
    rows?: number;
    isDisabled?: boolean;
    autoAdjustHeight?: boolean;
    isDelayUpdate?: boolean;
    delayUpdateCorrection?: (value: string) => string;
    onChange?: (e: string) => void;
    type?: string;
}

export default (props: TextBoxProps): JSX.Element => {
    const {
        name,
        label,
        className,
        hideLabel,
        maxLength,
        isDisabled,
        rows = 1,
        error,
        required,
        value = '',
        autoAdjustHeight = false,
        isDelayUpdate = false,
        delayUpdateCorrection = (value: string) => value,
        onChange,
        type = 'text',
        id
    } = props;

    const [stateValue, setStateValue] = React.useState<string>(value);
    const textAreaRef = React.useRef(null);
    const delayUpdateTimeout = React.useRef(null);

    React.useEffect(() => {
        // if value has changed (e.g. parent component does not accept non-numeric value)
        if (value !== stateValue) {
            setStateValue(delayUpdateCorrection(value));
        }
    }, [value]);

    React.useEffect(() => {
        if (!isDisabled) {
            // when stateValue is updated, decide whether to notify parent component immediatey or after a delay
            if (isDelayUpdate) {
                clearTimeout(delayUpdateTimeout.current);
                delayUpdateTimeout.current = setTimeout(() => onChange(stateValue), delayUpdate);
            } else {
                onChange(stateValue);
            }
        }

        return () => clearTimeout(delayUpdateTimeout.current);
    }, [stateValue]);

    const handleOnChange = (e: any): void => {
        setStateValue(e.target.value);
    };

    const handleTextAreaKeyUp = (): void => {
        // if autoAdjustHeight is set, extend the height of the textarea
        const element = textAreaRef.current;
        element.style.height = '1px';
        const newHeight = Math.max(8 + element.scrollHeight, rows * lineHeightInPixels + paddingHeightInPixels);
        element.style.height = `${newHeight}px`;
    };

    const onBlur = (): void => {
        // update parent immediately when focus leaves input field with delayed update
        if (isDelayUpdate) {
            clearTimeout(delayUpdateTimeout.current);
            onChange(stateValue);
        }
    };

    return (
        <InputWrapper
            label={label}
            name={name}
            className={classnames(local.TextBox, className)}
            hideLabel={hideLabel}
            required={required}
            error={error}
        >
            {!rows || rows === 1 ? (
                <input
                    className={classnames({ [local.hasValue]: value?.length > 0, [local.error]: error })}
                    value={stateValue}
                    disabled={isDisabled}
                    onChange={handleOnChange}
                    onBlur={onBlur}
                    maxLength={maxLength}
                    type={type}
                    id={`${id}_input`}
                />
            ) : (
                <textarea
                    className={classnames({ [local.hasValue]: value?.length > 0, [local.error]: error })}
                    disabled={isDisabled}
                    onChange={handleOnChange}
                    onBlur={onBlur}
                    maxLength={maxLength}
                    rows={rows}
                    value={stateValue}
                    ref={textAreaRef}
                    onKeyUp={autoAdjustHeight ? handleTextAreaKeyUp : undefined}
                    id={`${id}_textarea`}
                />
            )}
        </InputWrapper>
    );
};
