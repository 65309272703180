import { ACTION_TYPES, ClearErrorsAction, ErrorStateInterface, SetErrorAction } from './actionTypes';

export const setError = (error: ErrorStateInterface): SetErrorAction => ({
    type: ACTION_TYPES.SET_ERROR,
    error,
});

export const clearErrors = () : ClearErrorsAction => ({
    type: ACTION_TYPES.CLEAR_ERRORS
})
