export const LOADING_STATUS = {
    REQUIRED: 'REQUIRED',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    FAILED: 'FAILED',
};

export const BOOKING_STATUS = {
    NOT_RESERVED: 'NOT_RESERVED',
    RESERVED: 'RESERVED',
    CONFIRMED: 'CONFIRMED'
}

export const REDUCER_NAMES = {
    AUTH: 'auth',
    ROUTE: 'route',
    USER: 'user',
    ROOMS: 'rooms',
    LOADED_BOOKING: 'loadedBooking',
    BOOKING: 'booking',
    ORGANISATION_STRUCTURE: 'organisationalStructure',
    FILTERS: 'filters',
    NAVIGATION: 'navigation',
    NOTIFICATION: 'notification',
    APPOINTMENT: 'appointment',
    ERROR: 'error',
};

export const ROUTES = {
    PROGRESS: 'Progress',
    SERVICE_SELECTION: 'ServiceSelection',
    ROOM_BOOKING: 'RoomBooking',
    LOGIN: 'Login',
    NOT_FOUND: 'NotFound',
};
export const TABS = {
    HOME: 'home',
    BOOKING: 'booking',
    HISTORY: 'history',
    EQUIPMENT: 'equipment',
    ERROR: 'error',
};
export const PUB_SUB_TOPICS = {
    SITE_SELECTOR_SAVE: 'SITE_SELECTOR_SAVE',
    SITE_SELECTOR_RESET: 'SITE_SELECTOR_RESET',
    HOSPITALITY_SPECIAL_ITEM_PANEL_OPEN: 'HOSPITALITY_SPECIAL_ITEM_PANEL_OPEN',
};

export const ERRORS = {
    loginToCB: {
        CREDENTIALS: 'credentials',
        TENANTS: 'tenants',
    },
};
