import { connect } from 'react-redux';
import { Area } from 'V3Models/Domain/Area';
import { RootState } from 'V3Stores/index';
import filters from 'V3Stores/filters';
import AreaFilter from './AreaFilter';

const mapStateToProps = (state: RootState) => {
    const areas = filters.store.getSelectedAreas(state);
    return {
        items: areas.map<Area>((area) => ({
            areaDisplayOrder: area.areaDisplayOrder,
            areaID: area.areaID,
            areaName: area.areaName,
            selected: area.selected,
            siteID: area.siteID,
            siteName: area.siteName,
        })),
    };
};

const mapActionsToProps = {
    toggleAreaFilterItem: filters.actions.toggleAreaFilterItem,
};

export type AreaFilterProps = ReturnType<typeof mapStateToProps> & typeof mapActionsToProps;
export default connect(mapStateToProps, mapActionsToProps)(AreaFilter);
