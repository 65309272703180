import Booking from 'V3Models/BookingService/Booking/Booking';
import ConfirmBookingRequest from 'V3Models/BookingService/ConfirmBookingRequest';
import UpdateBookingRequest from 'V3Models/BookingService/UpdateBookingRequest';
import AppointmentInfoInterface from 'V3Models/Domain/AppointmentInfo';
import { SiteWithTZ } from 'V3Models/EntitiesService/SearchEntitiesRequest';

export const convertBookingFormToBookRequestDTO = (
    bookingForm: Booking,
    selectedRoom: string,
    appointmentInfo: AppointmentInfoInterface,
    userData: string,
    siteWithTZ: SiteWithTZ
    ): ConfirmBookingRequest => {
    const { id, attendeesCount, onBehalfOf, notes } = bookingForm;
    const onBehalfOfDetails = onBehalfOf
        ? {
              onBehalfOf: {
                  personId: onBehalfOf.personId,
                  visitorId: null,
                  displayName: `${onBehalfOf.firstname} ${onBehalfOf.surname}`,
              },
          }
        : null;
    const resource = {
        resourceId: selectedRoom,
        startDateTime: appointmentInfo?.startDTMUTC.toISO(),
        endDateTime: appointmentInfo?.endDTMUTC.toISO(),
    };
    return {
        bookingId: id,
        displayName: ((appointmentInfo?.title == '' || appointmentInfo?.title == null ) ? userData : appointmentInfo?.title),
        attendeesCount,
        resources: [resource],
        onBehalfOfDetails,
        notes,
        site: siteWithTZ,
        suppressPublishSyncEvent: true,
    };
};

export const convertBookingDataToUpdateRequestDTO = (
    booking: Booking,
    appointmentInfo: AppointmentInfoInterface,
    siteWithTZ: SiteWithTZ
): UpdateBookingRequest => {
    const { id, resources, attendeesCount, onBehalfOf, notes  } = booking;
    const requestBody: UpdateBookingRequest = {
        bookingId: id,
        displayName: appointmentInfo.title,
        resources: [
            {
                resourceId: resources[0].resourceId,
                startDateTime: appointmentInfo?.startDTMUTC.toISO(),
                endDateTime: appointmentInfo?.endDTMUTC.toISO(),
            },
        ],
        site: siteWithTZ,
        suppressPublishSyncEvent: true,
    };

    // if we changed some properties - we adding them in request data object
    if (attendeesCount) requestBody.attendeesCount = attendeesCount;
    if (notes) requestBody.notes = notes;
    if (onBehalfOf)
        requestBody.onBehalfOfDetails = {
            onBehalfOf: {
                personId: onBehalfOf.personId,
                visitorId: null,
                displayName: `${onBehalfOf.firstname} ${onBehalfOf.surname}`,
            },
        };

    return requestBody;
};
