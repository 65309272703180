import Booking from 'V3Models/BookingService/Booking/Booking';
import BookingHistoryItemWithUser from 'V3Models/BookingService/BookingHistoryItemWithUser';
import { Room } from 'V3Models/Domain/Room';
import { PersonInterface } from 'V3Models/PersonSearchService/PersonSearchItem';
import { Action } from 'V3Utilities/Action';
import { REDUCER_NAMES } from 'V3Utilities/constants';

const reducerName = `${REDUCER_NAMES.ROOMS}/${REDUCER_NAMES.BOOKING}`;

export const enum BOOKING_TYPE {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}

export const enum BOOKING_STATUS_TYPES {
    RESERVED,
    CONFIRMED,
    NOT_STARTED,
}

export const ACTION_TYPES = {
    SET_BOOKING: `${reducerName}/SET_BOOKING`,
    SET_BOOKING_STATUS: `${reducerName}/SET_BOOKING_STATUS`,
    SET_LOADING_STATUS: `${reducerName}/SET_LOADING_STATUS`, 
    ROOM_BOOKING_INFO_RESET: `${reducerName}/ROOM_BOOKING_INFO_RESET`,
    ROOM_BOOKING_INFO_LOAD_COMPLETE: `${reducerName}/ROOM_BOOKING_INFO_LOAD_COMPLETE`,
    REFERENCE_DATA_ROOM_BOOKING_INFO_LOAD_RESET: `${reducerName}/ROOM_BOOKING_INFO_LOAD_COMPLETE`,
    SET_BOOKING_ATTENDEES: `${reducerName}/SET_BOOKING_ATTENDEES`,
    SET_BOOKING_NOTES: `${reducerName}/SET_BOOKING_NOTES`,
    SET_BOOKINGS_ON_BEHALF_OF: `${reducerName}/SET_BOOKINGS_ON_BEHALF_OF`,
    SET_BOOKING_TITLE: `${reducerName}/SET_BOOKING_TITLE`,
    SET_BOOKING_ID: `${reducerName}/SET_BOOKING_ID`,
    SET_BOOKING_COMPLETE: `${reducerName}/SET_BOOKING_COMPLETE`,
    RESET_BOOKING: `${reducerName}/RESET_BOOKING`,
    BOOKING_FORM_RESET: `${reducerName}/BOOKING_INITIALISE`,
    SET_BOOKING_IN_PROGRESS: `${reducerName}/SET_BOOKING_IN_PROGRESS`,
    SET_SELECTED_ROOM: `${reducerName}/SET_SELECTED_ROOM`,
    SELECTED_ROOM_RESET: `${reducerName}/SELECTED_ROOMS_RESET`,
    SET_BOOKING_HISTORY: `${reducerName}/SET_BOOKING_HISTORY`,
    SET_HAS_RUN_POPULATE_BOOKING: `${reducerName}/SET_HAS_RUN_POPULATE_BOOKING`
};

export interface BookingResponseInterface {
    type: BOOKING_TYPE;
    item?: Booking;
}

export interface BookingStateInterface {
    booking: Booking;
    response: BookingResponseInterface;
    loadingStatus: string;
    selectedRoom: Room;
    bookingHistory: BookingHistoryItemWithUser[];
    inProgress: boolean;
    hasRunPopulateBooking: boolean;
}

export interface SetBookingAttendeesAction extends Action {
    attendees: number;
}

export interface SetBookingNotesAction extends Action {
    bookingNotes: string;
}

export interface SetBookingsOnBehalfOfAction extends Action {
    onBehalfOf: PersonInterface;
}

export interface SetBookingTitleAction extends Action {
    title: string;
}

export interface SetBookingIDAction extends Action {
    bookingID: string;
}

export interface SetBookingInProgressAction extends Action {
    inProgress: boolean;
}

export interface SetSelectedRoomAction extends Action {
    selectedRoom: Room;
}

export interface SetBookingCompleteAction extends Action {
    payload: {
        type: BOOKING_TYPE;
        item?: Booking;
    };
}

export interface SetBookingAction extends Action {
    booking: Booking
}

export interface SetBookingHistoryAction extends Action {
    history: BookingHistoryItemWithUser[];
}

export interface SetBookingStatusAction extends Action {
    bookingStatus: BOOKING_STATUS_TYPES;
}

export interface SetLoadingStatusAction extends Action {
    loadingStatus: string
}
export type RoomBookingInfoLoadResetAction = Action;
export type ResetBookingAction = Action;
export type ResetSelectedRoomsAction = Action;
export type BookingInitialiseAction = Action;
export type SetHasRunPopulateBookingAction = Action;

export type BookingActionType =
      SetBookingStatusAction
    | SetHasRunPopulateBookingAction
    | SetBookingHistoryAction
    | SetBookingAction
    | SetBookingAttendeesAction
    | SetBookingsOnBehalfOfAction
    | SetBookingAttendeesAction
    | SetBookingNotesAction
    | SetBookingTitleAction
    | SetBookingIDAction
    | ResetBookingAction
    | SetSelectedRoomAction
    | RoomBookingInfoLoadResetAction;
