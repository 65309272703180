import UIStrings from 'V3Utilities/UIStrings';

interface LocalisedStringsInterface {
    EN: UIStrings;
}

const LocalisedStrings: LocalisedStringsInterface = {
    EN: {
        cloudbooking: 'Cloudbooking',
        booking: 'Booking',
        loginSignIn: 'Sign in',
        loginErrorMessage: "The user name or password you entered isn't correct. Try entering it again.",
        loginTenantsErrorMessage: 'You have no tenants available to login.',
        attendees: 'Attendees',
        home: 'Home',
        timezone: 'Time Zone',
        service: 'Service',
        sites: 'Sites',
        signOut: 'Sign out',
        settings: 'Settings',
        refresh: 'Refresh',
        capacity: 'Capacity',
        location: 'Location',
        site: 'Site',
        description: 'Description',
        restrictions: 'Restrictions',
        title: 'Title',
        time: 'Time',
        colour: 'Colour',
        resources: 'Resources',
        config: 'Config',
        history: 'History',
        error: 'Error',
        room: 'Room',
        roomBooking: 'Room Booking',
        deskBooking: 'Desk Booking',
        desk: 'Desk',
        notes: 'Notes',
        private: 'Private',
        option: 'Option',
        selectOption: 'Select an option',
        notice: 'Notice',
        open: 'Open',
        setUp: 'Setup',
        timezones: 'Time Zones',
        portals: 'Portals',
        services: 'Services',
        close: 'Close',
        onbehalfOf: 'On behalf of',
        bookingType: 'Booking Type',
        bookingTypes: 'Booking Type',
        searchByName: 'Search by name',
        noResultFound: 'No Results Found',
        colours: 'Colours',
        add: 'Add',
        Required: 'Required',
        bookingSuccess: 'New booking created',
        bookingId: 'Booking ID:',
        edit: 'Edit',
        search: 'Search',
        total: 'Total',
        yes: 'Yes',
        no: 'No',
        ok: 'Ok',
        support: 'Support',
        about: 'About',
        subTotal: 'Sub Total',
        updateSuccessMessage: 'Booking updated',
        deleteSuccessMessage: 'Booking deleted',
        selectSites: 'Select Sites',
        selectRoom: 'Select Room',
        selectRooms: 'Select Rooms',
        rooms: 'Rooms',
        outlookTime: 'Outlook Time',
        siteTime: 'Site Time',
        information: 'Info',
        applyFilter: 'Apply',
        resetFilter: 'Reset Filter',
        srbCancellationWarning: 'Are you sure you want to cancel this booking?',
        defaultErrorCreatingBooking: 'There was a problem creating your booking',
        conflictErrorCreatingBooking: ' is already booked. Please select a different date or time.',
        forbiddenErrorCreatingBooking: 'Cannot create booking, please select a different date or time for this booking.',
    },
};

export default LocalisedStrings;
