import React from 'react';
import classnames from 'classnames';
import { ReactComponent as ArrowIcon } from 'V3/assets/SVGs/DatePickerArrow.svg';
import { ReactComponent as DateIcon } from 'V3/assets/SVGs/DatePickerCalendar.svg';
import { ReactComponent as TimeIcon } from 'V3/assets/SVGs/DatePickerTime.svg';
import InputWrapper from '../InputWrapper';
import local from './local.scss';
import { DateTime } from 'luxon';

export interface FieldProps {
    label: string;
    name: string;
    value: { siteStartDTM: DateTime; siteEndDTM: DateTime };
    disabled?: boolean;
    className?: string;
    hideLabel?: boolean;
    timeFormatIs12Hr?: boolean;
    idPrefix?: string;
}

const DateTimeField = ({ value, name, label, className, hideLabel, disabled = true, timeFormatIs12Hr= false,  idPrefix }: React.PropsWithChildren<FieldProps>): JSX.Element => {

    const displayDate = `${(value.siteStartDTM).toFormat('d LLL yy')}`;
    const displayTime = timeFormatIs12Hr ?  `${(value.siteStartDTM).toFormat('hh:mm a')} - ${(value.siteEndDTM).toFormat('hh:mm a')}`: `${(value.siteStartDTM).toFormat('HH:mm')} - ${(value.siteEndDTM).toFormat('HH:mm')}`;
    //const allDay = displayTime === '00:00 - 00:00';

    return (
        <div className={className}>
            <InputWrapper label={label} name={name} hideLabel={hideLabel}>
                <div className={classnames(local.dateTimePickerField, disabled ? local.dateTimePickerFieldDisabled : null)}>
                    <div className={local.dateIcon} id={`${idPrefix}__block_date-icon`}>
                        <DateIcon />
                    </div>
                    <div className={local.dateText} id={`${idPrefix}__block_displayDate`}>{displayDate}</div>
                        <>
                            <div id={`${idPrefix}__block_arrow-icon`} className={local.arrowIcon}>
                                <ArrowIcon  transform='rotate(90)'/>
                            </div>

                            <div id={`${idPrefix}__block_time-icon`} className={local.timeIcon}>
                                <TimeIcon />
                            </div>

                            <div id={`${idPrefix}__block_time-text`}  className={local.timeText}>{displayTime}</div>
                        </>
                </div>
            </InputWrapper>
        </div>
    );
};

export default DateTimeField;
