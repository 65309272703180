import { Area } from 'V3Models/Domain/Area';
import ResourceEquipment from 'V3Models/Domain/Equipment';
import { ACTION_TYPES, FiltersInitialiseAction, SetAttendeesAction, SetEquipmentAction, SetSelectedAreasAction } from './actionTypes';

export const filtersSetAttendees = (attendees: number): SetAttendeesAction => ({
    type: ACTION_TYPES.FILTERS_SET_ATTENDEES,
    attendees,
});

export const setEquipmentTypes = (equipmentTypes: ResourceEquipment[]): SetEquipmentAction => ({
    type: ACTION_TYPES.FILTERS_SET_EQUIPMENT_TYPES,
    equipmentTypes,
});

export const filtersInitialise = (): FiltersInitialiseAction => ({
    type: ACTION_TYPES.FILTERS_INITIALISE,
});

export const setSelectedAreas = (selectedAreas: Area[]): SetSelectedAreasAction => ({
    type: ACTION_TYPES.FILTERS_SET_SELECTED_AREAS,
    selectedAreas,
});

