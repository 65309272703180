import User from 'V3Models/Domain/User';
import OnBehalfOfUser from 'V3Models/Domain/OnBehalfOfUser';
import { REDUCER_NAMES } from 'V3Utilities/constants';

const reducerName = REDUCER_NAMES.USER;

export const ACTION_TYPES = {
    SET_USER: `${reducerName}/SET_USER`,
    SET_ON_BEHALF_OF_USER: `${reducerName}/SET_ON_BEHALF_OF_USER`,
};

export interface SetUserAction {
    type: typeof ACTION_TYPES.SET_USER;
    payload: User;
}

export interface SetOnBehalfOfUserAction {
    type: typeof ACTION_TYPES.SET_ON_BEHALF_OF_USER;
    payload: OnBehalfOfUser;
}

export type UserActionType = SetUserAction | SetOnBehalfOfUserAction;

export interface UserStateInterface {
    user: User;
    onBehalfOfUser: OnBehalfOfUser;
    subscriptionStatusMessage: string;
}
