import React from 'react';
import classnames from 'classnames';

import local from './local.scss';

interface Props {
    className?: string;
    id?: string;
}

export default (props: Props): JSX.Element => {
    const { className, id} = props;

    return (
        <div id={`${id}__loading-dots`} className={classnames(local.inputFieldLoadingDots, className)}>
            <div className={classnames(local.dot, local.dot0)} />
            <div className={classnames(local.dot, local.dot1)} />
            <div className={classnames(local.dot, local.dot2)} />
        </div>
    );
};
