import React, { useState } from 'react';
import { Shimmer } from '@fluentui/react';
import FilterListIcon from '@material-ui/icons/FilterList';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { Accordion, Panel, StyledShimmer } from 'V3/components/common';
import { ApplyButton, BlueButton, OkButton, ResetButton } from 'V3/components/common/buttons';
import RoomInfo from 'V3Components/Rooms/RoomInfo';
import SelectableRoom from 'V3Components/Rooms/SelectableRoom';
import { Site } from 'V3Models/Domain/Site';
import sortBy from 'V3Utilities/sortBy';
import AreaFilter from './AreaFilter';
import ResourceTypeFilter from './ResourceTypeFilter';
import { SelectableRoomsProps } from '.';
import local from './selectableRooms.scss';
import { LOADING_STATUS } from 'V3Utilities/constants';
import { Room } from 'V3Models/Domain/Room';

const SelectableRooms = (props: SelectableRoomsProps): JSX.Element => {
    const { loadingStatus, sites, selectedAreas, selectedRoom, resetFilters, resetSelectedRoom, selectRoom, setRoomInfo } = props;
    const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
    const [isRoomInfoPanelOpen, setIsRoomInfoPanelOpen] = useState(false);

    const toggleFilterPanel = (): void => {
        setIsFilterPanelOpen((current) => !current);
    };

    const toggleRoomInfoPanel = (): void => {
        setIsRoomInfoPanelOpen((isCurrentlyOpen) => {
            if (isCurrentlyOpen) {
                resetSelectedRoom();
            }
            return !isCurrentlyOpen;
        });
    };

    if (loadingStatus === LOADING_STATUS.REQUIRED) {
        return (
            <div className={local.shimmer}>
                <Shimmer customElementsGroup={StyledShimmer()} width="auto" isDataLoaded={false} id="selevtable-rooms__shimmer_loading-req" />
            </div>
        );
    }

    if (loadingStatus !== LOADING_STATUS.LOADED) {
        return null;
    }

    const filterPanelButtons = (
        <>
            <ApplyButton onClick={toggleFilterPanel} id="selectable-rooms__button_apply" />
            <ResetButton onClick={resetFilters} id="selectable-rooms__button_reset" />
        </>
    );

    const roomInfoPanelButtons = <OkButton onClick={toggleRoomInfoPanel} id='selected-rooms__button_ok'/>;

    const showRoomInfo = (room: Room): void => {     
        setRoomInfo(room);
        toggleRoomInfoPanel();
    };

    const filteredAreas = selectedAreas.filter((a) => a.selected === true);

    const filteredSites: string[] = sortBy(Array.from(new Set(filteredAreas.map((item) => item.siteID))), (res) => res.siteID);

    const sitesToDisplay: Site[] = sites.filter((site) => filteredSites.includes(site.siteID));


    const roomsWillDisplay = !!sitesToDisplay.find((s) => s.areas.find((area) => area.rooms.find((r) => r.isFilterMatch)));

    return (
        <>
            <div className={local.rooms}>
                <div className={local.sectionHeading}>
                    <div>
                        <h1 id="selectable-room__h1_select-room">Select Room</h1>
                    </div>
                    <div>
                        <BlueButton id="selectable-room__button_filter" startIcon={<FilterListIcon />} onClick={toggleFilterPanel}>
                            Filter
                        </BlueButton>
                    </div>
                </div>
                {roomsWillDisplay ? (
                    sitesToDisplay.map((site) => (
                        <Accordion type="site" title={`${site.siteName}`} parent expanded isFilter={false} id={site.siteName}>
                            <div key={`site_${site.siteID}`}>
                                {site.areas
                                    .filter((area) => area.rooms.find((r) => r.isFilterMatch))
                                    .sort((a, b) => a.areaDisplayOrder > b.areaDisplayOrder ? 1 : -1 )
                                    .map((area) => (
                                        <div key={`area_${area.areaID}`}>
                                            <Accordion type="floor" title={`${area.areaName}`} parent={false} expanded={false} isFilter={false} id={area.areaName}>
                                                {
                                                area.rooms
                                                    .filter((r) => r.isFilterMatch)
                                                    .sort((a, b) => a.itemDisplayOrder > b.itemDisplayOrder ? 1 : -1 )
                                                    .map((room) => (
                                                        <SelectableRoom
                                                            key={`room_${room.roomID}`}
                                                            Room={room}
                                                            OnSelectRoom={selectRoom}
                                                            OnShowRoomInfo={showRoomInfo}
                                                            Selected={selectedRoom?.roomID === room?.roomID}
                                                        />
                                                    ))}
                                            </Accordion>
                                        </div>
                                    ))}
                            </div>
                        </Accordion>
                    ))
                ) : (
                    <div className={local.noResults}>
                        <h6 id="selectable-h6_no-rooms-found">No rooms found.</h6>
                    </div>
                )}
            </div>

            <Panel icon={FilterListIcon} isOpen={isFilterPanelOpen} buttons={filterPanelButtons} onClose={toggleFilterPanel}>
                <Accordion title="Filter rooms by areas" parent={false} expanded isSubFilter id="selectable-rooms__accordion_filter-by-area">
                    <AreaFilter />
                </Accordion>
                <Accordion
                    title="Filter rooms by equipment"
                    parent={false}
                    expanded={false}
                    isSubFilter
                    id="selectable-rooms__accordion_filter-by-equipment"
                >
                    <ResourceTypeFilter />
                </Accordion>
            </Panel>

            <Panel
                icon={InfoOutlined}
                isOpen={isRoomInfoPanelOpen}
                buttons={roomInfoPanelButtons}
                onClose={toggleRoomInfoPanel}
                id="selectable-rooms__panel_room-info"
            >
                <RoomInfo />
            </Panel>
        </>
    );
};

export default SelectableRooms;
