import { connect } from 'react-redux';
import auth from 'V3Stores/auth';
import { RootState } from 'V3Stores/index';
import organisationalStructure from 'V3Stores/organisationalStructure';
import route from 'V3Stores/route';
import user from 'V3Stores/user';

import NavBarCommands from './NavBarCommands';

const mapStateToProps = (state: RootState) => {
    return {
        supportText: organisationalStructure.store.getSite(user.store.getUser(state)?.siteId)(state)?.supportText ?? '',
    };
};

const mapActionsToProps = {
    setRoute: route.actions.setRoute,
    setUser: user.store.setUser,
    logoutFromCB: auth.store.authActions.logoutFromCB,
};

export default connect(mapStateToProps, mapActionsToProps)(NavBarCommands);

export type NavBarCommandsProps = ReturnType<typeof mapStateToProps> & typeof mapActionsToProps;
