import { connect } from 'react-redux';
import bookingForm from 'V3Stores/booking';
import organisationalStructure from 'V3Stores/organisationalStructure';

import PanelContent from './PanelContent';

const mapStateToProps = (state, ownProps) => ({
    selectedSite:  organisationalStructure.store.getSelectedSiteIDs(state)[0],
    selectedContact: bookingForm.store.getBookingOnBehalfOf(state),
    ...ownProps,
});

export type PanelContentProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(PanelContent);
