import {
    ACTION_TYPES,
    AuthActionType,
    AuthStateInterface,
    LoginAuthSchemesSelectionActiveAction,
    LoginCBFailAction,
    LoginCBSuccessAction,
    LoginLoadingAction,
    LoginTenantsSelectionActiveAction,
    LoginTenantsSelectionDoneAction,
    SetLoginTenantsSelectionIsActiveAction,
} from 'V3Stores/auth/types';

export const initialState: AuthStateInterface = {
    inProgress: false,
    username: '',
    CBLoginError: {
        isCBLoginError: null,
        type: null,
    },
    isCBLoginSuccess: false,
    isOffice365LoginError: false,
    isOffice365LoginSuccess: false,
    tenant: null,
    tenantsSelection: {
        active: false,
        tenants: [],
        tenantsOptions: [],
    },
    authSchemesSelection: {
        active: false,
        authSchemes: [],
        authSchemesOptions: [],
    },
};

export default (state = initialState, action: AuthActionType): AuthStateInterface => {    
    switch (action.type) {
        case ACTION_TYPES.LOGIN_LOADING:
            return { ...state, inProgress: (action as LoginLoadingAction).payload.loading };

        case ACTION_TYPES.LOGIN_AUTH_SCHEMES_SELECTION_ACTIVE:
            return {
                ...state,
                inProgress: false,
                authSchemesSelection: {
                    active: true,
                    ...(action as LoginAuthSchemesSelectionActiveAction).payload,
                },
            };

        case ACTION_TYPES.LOGIN_AUTH_SCHEMES_SELECTION_DONE:
            return {
                ...state,
                inProgress: true,
                authSchemesSelection: { active: false, authSchemes: [], authSchemesOptions: [] },
            };

        case ACTION_TYPES.LOGIN_TENANTS_SELECTION_ACTIVE:
            return {
                ...state,
                inProgress: false,
                tenantsSelection: { active: true, ...(action as LoginTenantsSelectionActiveAction).payload },
            };

        case ACTION_TYPES.LOGIN_TENANTS_SELECTION_DONE:
            return {
                ...state,
                inProgress: true,
                tenantsSelection: { active: false, tenants: [], tenantsOptions: [] },
                ...(action as LoginTenantsSelectionDoneAction).payload,
            };

        case ACTION_TYPES.LOGIN_TENANTS_SELECTION_IS_ACTIVE:            
            return {
                ...state,
                tenantsSelection: { 
                    ...state.tenantsSelection, 
                    active: (action as SetLoginTenantsSelectionIsActiveAction).payload.isActive
                }
            };

        case ACTION_TYPES.LOGIN_CB_SUCCESS:
            return {
                ...state,
                inProgress: false,
                isCBLoginSuccess: true,
                ...(action as LoginCBSuccessAction).payload,
            };

        case ACTION_TYPES.LOGIN_CB_FAIL:
            return { ...state, inProgress: false, CBLoginError: { ...(action as LoginCBFailAction).payload } };

        case ACTION_TYPES.LOGOUT:
            return {
                ...initialState,
            };

        default: {
            return state;
        }
    }
};
