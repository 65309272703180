import AuthenticationServiceApi from 'V3Services/AuthenticationService';
import { logout, setLoginCBSuccess } from 'V3Stores/auth/storeActions';
import { AppThunk } from 'V3Stores/index';

export const loginToCBSuccess =
    (payload: { username: string; accessToken: string }): AppThunk =>
    async (dispatch) => {
        const { accessToken, username } = payload;
        localStorage.setItem('accessToken', accessToken);
        dispatch(setLoginCBSuccess({ username }));
    };

export const logoutFromCB = (): AppThunk => async (dispatch) => {
    localStorage.clear();
    Office.context.roamingSettings.remove('username');
    Office.context.roamingSettings.remove('msgraphToken');
    dispatch(logout());
    return await AuthenticationServiceApi.logout();
};
