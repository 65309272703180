import React from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { DoneButton } from '../../../common/buttons';
import PanelComponent from '../../../common/Panel';
import PanelContent from './PanelContent';

interface PanelInterfaceProps{
    isOpen: boolean;
    onClose: () => void;
    onSelect: Function;
    id: string;
}

const Panel = (props: PanelInterfaceProps): JSX.Element => {
    const { isOpen, onClose, onSelect, id } = props;

    return (
        <PanelComponent id={id} icon={InfoOutlinedIcon} buttons={<DoneButton onClick={onClose} />} isOpen={isOpen} onClose={onClose}>
            <PanelContent onSelect={onSelect} />
        </PanelComponent>
    );
};

export default Panel;
