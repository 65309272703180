
import { LOADING_STATUS } from 'V3Utilities/constants';
import * as ACTION_TYPES from './types';
import {
    OrganisationalStructureActionType,
    OrganisationalStructureStateInterface,
    RoomInfoLoadCompleteAction,
    RoomsLoadCompleteAction,
    SetRoomImageURLAction,
    SetRoomsLoadingStatusAction,
    SetSelectedSitesAction,
    SetSitesLoadingStatusAction,
    SitesLoadCompleteAction,
} from './types';

export const initialState = <OrganisationalStructureStateInterface>{
    loadingStatus: LOADING_STATUS.REQUIRED,
    sites: {
        items: [],
        loadingStatus: LOADING_STATUS.REQUIRED,
    },
    rooms: {
        items: [],
        loadingStatus: LOADING_STATUS.REQUIRED,
    },
    attendees: Array.from(Array(250).keys(), (x) => x + 1),
    roomInfo: {
        items: [],
        loadingStatus: LOADING_STATUS.REQUIRED,
        imageURL: ''
    },
    selectedSiteIDs: [],
};

export default (state = initialState, action: OrganisationalStructureActionType): OrganisationalStructureStateInterface => {
    switch (action.type) {
        // reference data
        case ACTION_TYPES.REFERENCE_DATA_LOAD_START:
            return {
                ...state,
                loadingStatus: LOADING_STATUS.LOADING,
            };

        case ACTION_TYPES.REFERENCE_DATA_LOAD_END:
            return {
                ...state,
                loadingStatus: LOADING_STATUS.LOADED,
            };

        case ACTION_TYPES.REFERENCE_DATA_LOAD_RESET:
            return {
                ...state,
                loadingStatus: LOADING_STATUS.REQUIRED,
            };

        case ACTION_TYPES.REFERENCE_DATA_INITIALISE:
            return {
                ...initialState,
            };

        // sites
        case ACTION_TYPES.REFERENCE_DATA_SITES_LOAD_COMPLETE:
            return {
                ...state,
                sites: {
                    ...state.sites,
                    items: (action as SitesLoadCompleteAction).sites,
                    loadingStatus: LOADING_STATUS.LOADED,
                },
            };

        case ACTION_TYPES.REFERENCE_DATA_SITES_LOAD_RESET:
            return {
                ...state,
                sites: {
                    ...initialState.sites,
                },
            };

        case ACTION_TYPES.REFERENCE_DATA_SET_SITES_LOADING_STATUS:
            return {
                ...state,
                sites: {
                    ...state.sites,
                    loadingStatus: (action as SetSitesLoadingStatusAction).loadingStatus,
                },
            };

        case ACTION_TYPES.REFERENCE_DATA_SET_ROOMS_LOADING_STATUS:
            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    loadingStatus: (action as SetRoomsLoadingStatusAction).loadingStatus,
                },
            };

        case ACTION_TYPES.REFERENCE_DATA_SET_SELECTED_SITES:
            return {
                ...state,
                selectedSiteIDs: (action as SetSelectedSitesAction).selectedSiteIDs,
            };

        case ACTION_TYPES.REFERENCE_DATA_SELECT_SITES_RESET:
            return {
                ...state,
                selectedSiteIDs: [],
            };

        // rooms
        case ACTION_TYPES.REFERENCE_DATA_ROOMS_LOAD_COMPLETE:
            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    items: (action as RoomsLoadCompleteAction).rooms,
                    loadingStatus: LOADING_STATUS.LOADED,
                },
            };

        case ACTION_TYPES.REFERENCE_DATA_ROOMS_LOAD_RESET:
            return {
                ...state,
                rooms: {
                    ...initialState.rooms,
                },
            };

        // room info
        case ACTION_TYPES.REFERENCE_DATA_ROOM_INFO_LOAD_COMPLETE:
            return {
                ...state,
                roomInfo: {
                    items: [...state.roomInfo.items, (action as RoomInfoLoadCompleteAction).room],
                    loadingStatus: LOADING_STATUS.LOADED,
                    imageURL: state.roomInfo.imageURL
                },
            };

        case ACTION_TYPES.REFERENCE_DATA_ROOM_INFO_LOAD_RESET:
            return {
                ...state,
                roomInfo: {
                    ...initialState.roomInfo,
                    loadingStatus: LOADING_STATUS.LOADING,
                },
            };

        case ACTION_TYPES.SET_ROOM_IMAGE_URL:
            return {
                ...state,
                roomInfo:{
                    ...state.roomInfo,
                    imageURL: (action as SetRoomImageURLAction).imageURL
                }
            };

        default:
            return state;
    }
};
