import { Action, applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkAction } from 'redux-thunk';
import { AuthStateInterface } from 'V3Stores/auth/types';
import { AppointmentStateInterface } from './appointment/types';
import { BookingStateInterface } from './booking/types';
import { NavigationStateInterface } from './navigation/types';
import { OrganisationalStructureStateInterface } from './organisationalStructure/types';
import { RouteStateInterface } from './route/types';
import { UserStateInterface } from './user/types';
import rootReducer from './rootReducer';
import { ErrorStateInterface } from './errorState/actionTypes';
import { FiltersStateInterface } from './filters/actionTypes';

let composedEnhancers = null;

const middlewares = applyMiddleware(thunk)

if(process?.env?.NODE_ENV ==  'development'){
    composedEnhancers = composeWithDevTools(middlewares)
}
else{
    composedEnhancers = compose(middlewares)
}

const store = createStore(rootReducer, composedEnhancers);

export type RootState = {
    auth: AuthStateInterface;
    route: RouteStateInterface;
    user: UserStateInterface;
    bookingForm: BookingStateInterface;
    appointment: AppointmentStateInterface;
    organisationalStructure: OrganisationalStructureStateInterface;
    navigation: NavigationStateInterface;
    filters: FiltersStateInterface;
    errorState: ErrorStateInterface;
};

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, any, Action<string>>;

export default store;
