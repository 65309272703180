export enum LanguageStrings {
    EnglishUnitedState = 'en-US',
    EnglishUK = 'en-GB',
    PortugueseBrazilian = 'pt-BR',
    Spanish = 'es-ES',
}

export enum RoomBooking {
    EditOtherUsers = 1 << 0,
    EditPastBookings = 1 << 1,
    BookOnBehalf = 1 << 2,
}
