import { connect } from 'react-redux';
import bookingActions from 'V3Stores/Thunks';
import bookingForm from 'V3Stores/booking';
import { RootState } from 'V3Stores/index';
import navigation from 'V3Stores/navigation';
import referenceData from 'V3Stores/organisationalStructure';
import Buttons from './Buttons';
import booking from 'V3Stores/booking';

const mapStateToProps = (state: RootState) => {
    return {
        currentTab: navigation.store.getCurrentTab(state),
        isEdit: bookingForm.store.getIsEdit(state),
        isHomeNextDisabled: bookingForm.store.getSelectedRoom(state) === null, 
        sitesLoadingStatus: referenceData.store.getSitesLoadingStatus(state),
        bookingLoadingStatus: booking.store.getLoadingStatus(state),
    };
};

const mapActionsToProps = {
    onDelete: bookingActions.onShowBookingDelete,
    onNext: bookingActions.moveNext,
    saveBooking: bookingActions.saveBooking,
};

export type ButtonsProps = ReturnType<typeof mapStateToProps> & typeof mapActionsToProps;
export default connect(mapStateToProps, mapActionsToProps)(Buttons);
