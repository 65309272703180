import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {initializeIcons} from '@fluentui/react';
import smoothscroll from 'smoothscroll-polyfill';
import store from 'V3Stores/index';
import App from './V3/components/App';

import './styles.scss';

smoothscroll.polyfill();
initializeIcons();
ReactDOM.render(
    <Provider store={store}>
      <App/>
    </Provider>,
    document.getElementById('container')
);
