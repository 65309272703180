import { Guid } from 'guid-typescript';
import BookingHistoryResponse from 'V3Models/BookingService/BookingHistoryResponse';
import ConfirmBookingRequest from 'V3Models/BookingService/ConfirmBookingRequest';
import ConfirmBookingResponse from 'V3Models/BookingService/ConfirmBookingResponse';
import GetBookingResponse from 'V3Models/BookingService/GetBookingResponse';
import PatchBookingRequest from 'V3Models/BookingService/PatchBookingRequest';
import PatchBookingResponse from 'V3Models/BookingService/PatchBookingResponse';
import { BookingCancelRequest } from 'V3Models/DTOs/Requests/BookingCancelRequest';
import { BookingReservationRequest } from 'V3Models/DTOs/Requests/BookingReservationRequest';
import { BaseResponse } from 'V3Models/DTOs/Responses/BaseResponse';
import { ReserveBookingResponse } from 'V3Models/DTOs/Responses/ReserveBookingResponse';

import { $api } from '../api';

export default class BookingServiceApiClient {
    public static getBooking = async (bookingId: string): Promise<GetBookingResponse> => {
        return $api.get<GetBookingResponse>(`/api/v1/Booking/${bookingId}`).then((response) => response.data);
    };

    public static makeBookingReservation = async (requestBody: BookingReservationRequest): Promise<ReserveBookingResponse> => {
        return $api.post<ReserveBookingResponse>('/api/v1/Booking/reserve', requestBody).then((response) => response.data);
    };

    public static cancelBookingReservation = async (requestBody: BookingCancelRequest): Promise<BaseResponse> => {
        return $api.put<BaseResponse>(`/api/v1/Booking/cancel`, requestBody).then((response) => response.data);
    };

    public static getBookingHistory = async (bookingId: string): Promise<BookingHistoryResponse> => {
        return $api.get<BookingHistoryResponse>(`/api/v1/Booking/${bookingId}/history`).then((response) => response.data);
    };

    public static confirmBookingReserve = async (requestBody: ConfirmBookingRequest): Promise<ConfirmBookingResponse> => {
        return $api.post<ConfirmBookingResponse>(`/api/v1/Booking/confirm`, requestBody).then((response) => response.data);
    };

    public static addEventIdToBooking = async (requestBody: PatchBookingRequest): Promise<PatchBookingResponse> => {
        return $api.patch<PatchBookingResponse>(`/api/v1/Booking/BookingSync`, requestBody).then((response) => response.data);
    };

    public static getBookingUsingInfo = async (startDateTime: string, endDateTime: string, mailbox: string, userId?: string): Promise<Guid> => {
        let uri = `/api/v1/Booking/bookingIdByInfo?StartDTM=${startDateTime}&EndDTM=${endDateTime}&Mailbox=${mailbox}`;

        if (userId) {
            uri += `&userId=${userId}`;
        }

        return $api.get<Guid>(uri).then((response) => response.data);
    };
}
