import React from 'react';
import local from './components.scss';

export interface RequiredLabelProps {
    required?: boolean;
}
const RequiredLabel = ({ required = false }: RequiredLabelProps): JSX.Element | null => {
    return required ? <div id='required-label__block' className={local.requiredLabel}>*</div> : null;
};

export default RequiredLabel;
