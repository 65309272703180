import React, { useEffect } from 'react';
import { LOADING_STATUS } from 'V3Utilities/constants';

import { Progress } from '..';

interface ContentLoaderProps {
    loadStatus: string;
    load: () => void;
    continueOnFailure?: boolean;
    failureContent?: JSX.Element;
}

const ContentLoader = ({
    load,
    loadStatus,
    children,
    continueOnFailure = true,
    failureContent = <div />,
}: React.PropsWithChildren<ContentLoaderProps>): JSX.Element => {
    useEffect(() => {
        if (loadStatus === LOADING_STATUS.REQUIRED) {
            load();
        }
    }, [loadStatus]);

    switch (loadStatus) {
        case LOADING_STATUS.REQUIRED:
            return <div id="content-loader__required" />;

        case LOADING_STATUS.LOADING:
            return <Progress id="content-loader__loading" />;

        case LOADING_STATUS.FAILED:
            if (!continueOnFailure) {
                return <>{failureContent}</>;
            }

            return <>{children}</>;

        default:
            return <>{children}</>;
    }
};

export default ContentLoader;
