import { connect } from 'react-redux';
import { RootState } from 'V3Stores/index';
import navigation from 'V3Stores/navigation';
import InfoPanel from './InfoPanel';

const mapStateToProps = (state: RootState) => ({
    infoMessage: navigation.store.getInfoMessage(state),
    isOpen: !!navigation.store.getInfoMessage(state),
});

const mapDispatchToProps = {
    onClose: () => navigation.store.setInfoMessage(),
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoPanel);
