import React from 'react';

const useScrollToTop = (prop): any => {
    const scrollRef = React.useRef(null);
    React.useEffect(() => {
        const ref = scrollRef.current;
        if (!ref) {
            return;
        }

        try {
            ref.scroll({ top: 0, left: 0, behavior: 'smooth' });
        } catch {
            try {
                ref.scrollTo(0, 0);
            } catch {
                ref.scrollTop = 0;
            }
        }
    }, [prop]);

    return scrollRef;
};

export default useScrollToTop;
