import { AxiosResponse } from 'axios';
import LoginToCBRequest from 'V3Models/Login/LoginToCBRequest';
import LoginToCBResponse from 'V3Models/Login/LoginToCBResponse';
import RefreshCBLoginResponse from 'V3Models/Login/RefreshLoginToCBResponse';
import { $api } from 'V3Services/api';

export default class AuthenticationServiceApi {
    public static async loginToCB(requestBody: LoginToCBRequest): Promise<LoginToCBResponse> {
        return $api.post<LoginToCBResponse>(`/api/v1/Identity/login`, requestBody).then((response) => response.data);
    }

    public static async refreshUserLoginData(username: string): Promise<RefreshCBLoginResponse> {
        return $api.get<RefreshCBLoginResponse>(`/api/v1/Authentication/refreshtoken?userName=${username}`).then((response) => response.data);
    }

    public static async logout(): Promise<AxiosResponse> {
        return $api.get('/api/v1/Authentication/logout');
    }
}
