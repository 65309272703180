import React from 'react';

export const BackIcon = (props) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        overflow="visible"
        width="15pt"
        height="15pt"
        viewBox="0 0 6.8 11.6"
    >
        <g>
            <path
                d="M6.45.32a1.06,1.06,0,0,1,0,1.57L2.7,5.8,6.45,9.71a1.06,1.06,0,0,1,0,1.57,1.18,1.18,0,0,1-1.61,0c-.42-.4-4.5-4.69-4.5-4.69A1.08,1.08,0,0,1,0,5.8,1.09,1.09,0,0,1,.34,5S4.42.72,4.84.32A1.18,1.18,0,0,1,6.45.32Z"
                fill={props.fill}
                stroke="none"
            />
        </g>
    </svg>
);
