import React, { useEffect } from 'react';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import VideocamIcon from '@material-ui/icons/Videocam';
import NavBar from 'V3Components/Navbar';
import navigation from 'V3Stores/navigation';
import { TABS } from 'V3Utilities/constants';

import { BookingIcon, HistoryIcon, HomeIcon } from './icons';
import { TabProps } from '.';

const TabIcons = {
    [TABS.HOME]: HomeIcon,
    [TABS.BOOKING]: BookingIcon,
    [TABS.HISTORY]: HistoryIcon,
    [TABS.EQUIPMENT]: VideocamIcon,
    [TABS.ERROR]: ErrorIcon,
};

const Tabs = (props: TabProps): JSX.Element => {
    const { availableTabs, currentTab, setTab, setCreateBookingTabsAction, onRefresh } = props;

    useEffect(() => {
        if (!availableTabs?.length) {
            navigation.actions.setCreateBookingTabs();
            setCreateBookingTabsAction();
        }
    }, []);

    if (!availableTabs?.length) return null;

    const tabs: TabInterface[] = availableTabs?.map((tab) => ({ value: tab, icon: TabIcons[tab] }));

    return <NavBar tabs={tabs} value={currentTab} onChange={setTab} onRefresh={onRefresh} showCommands isLoading={false} id="tabs__navbar" />;
};

export default Tabs;

export interface TabInterface {
    value: any;
    icon: JSX.Element;
    isDisabled?: boolean;
}

export interface ActiveTabInterface extends TabInterface {
    isActive: boolean;
}
