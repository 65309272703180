import React, { useState } from 'react';
import { Shimmer } from '@fluentui/react';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { SelectedRoomsProps } from 'V3Components/BookingDetails/SelectedRoomPanel/index';
import { OkButton } from 'V3Components/common/buttons';
import RoomInfo from 'V3Components/Rooms/RoomInfo';
import { Panel, StyledShimmer } from '../../common';
import SelectedRoom from '../SelectedRoom';
import local from './selectedRooms.scss';
import { Room } from 'V3Models/Domain/Room';

const SelectedRoomPanel = ({ sites, loadRoomInfo}: SelectedRoomsProps): JSX.Element => {
    const [isRoomInfoPanelOpen, setIsRoomInfoPanelOpen] = useState(false);

    const handleOpenRoomInfoPanel = (room: Room) => {
        loadRoomInfo(room);
        toggleRoomInfoPanel();
    };

    const toggleRoomInfoPanel = (): void => {
        setIsRoomInfoPanelOpen((isCurrentlyOpen) => {
            return !isCurrentlyOpen;
        });
    };

    const roomInfoPanelButtons = <OkButton onClick={toggleRoomInfoPanel} id='selected-rooms__button_ok'/>;

    return (
        <div className={local.container}>
            <div id='selected-rooms__block' className={local.rooms}>
                {sites.length > 0 ? (
                    sites.map((site) => (
                        <div key={`site_${site.siteID}`}>
                            {site.areas.map((area) => (
                                <div key={`area_${area.areaID}`}>
                                    <h6 id={`selected-rooms__h6_${area.areaID}`}>
                                        {site.siteName} - {area.areaName}
                                    </h6>
                                    {area.rooms.map((room) => (
                                        <SelectedRoom key={`room_${room.roomID}`} Room={room} onLoadRoom={handleOpenRoomInfoPanel} />
                                    ))}
                                </div>
                            ))}
                        </div>
                    ))
                ) : (
                    <div className={local.shimmer}>
                        <Shimmer id='selected-rooms__shimmer' customElementsGroup={StyledShimmer()} width="auto" isDataLoaded={false} />
                    </div>
                )}
            </div>
            <Panel icon={InfoOutlined} isOpen={isRoomInfoPanelOpen} buttons={roomInfoPanelButtons} onClose={toggleRoomInfoPanel} id='selected-rooms__panel'>
                <RoomInfo />
            </Panel>
        </div>
    );
};

export default SelectedRoomPanel;
