import { ROUTES } from 'V3Utilities/constants';

import { ACTION_TYPES, RouteActionType, RouteStateInterface, SetIsWebClientAction, SetRouteAction } from './types';

export const initialState: RouteStateInterface = {
    route: ROUTES.PROGRESS,
    isWebClient: null,
};

export const reducerName = 'route';

export default (state: RouteStateInterface = initialState, action: RouteActionType): RouteStateInterface => {
    switch (action.type) {
        case ACTION_TYPES.SET_ROUTE:
            return {
                ...state,
                route: (action as SetRouteAction).route,
            };

        case ACTION_TYPES.SET_IS_WEB_CLIENT:
            return {
                ...state,
                isWebClient: (action as SetIsWebClientAction).isWebClient,
            };

        default: {
            return state;
        }
    }
};
