
import PersonGetByIdsResponse from 'V3Models/PersonSearchService/PersonGetByIdsResponse';
import { PersonSearchResponse } from 'V3Models/PersonSearchService/PersonSearchResponse';
import { $api } from 'V3Services/api';

export default class PersonSearchServiceApiClient {
    public static lookupPerson = async (text: string): Promise<PersonSearchResponse> => {
        return $api.get<PersonSearchResponse>(`/api/v1/Person/search?searchText=${text}`).then((response) => response.data);
    };

    public static getByIds = async (body: { personIds: string[] }): Promise<PersonGetByIdsResponse> => {
        return $api.post(`/api/v1/Person/getbyids`, body).then((response) => response.data);
    };
}
