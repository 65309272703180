import React from 'react';
import { ErrorStateProps } from '.';
import { ReactComponent as ErrorIcon } from '../../assets/SVGs/ErrorExclaim.svg';
import local from './local.scss';


const ErrorScreen = ({error}: ErrorStateProps): JSX.Element => {
    return (
        <>    
        <div className={local.errorContainer}>
            <span className={local.errorIconContainer} id='error-screen__h3_container'>
                <ErrorIcon style={{ width: '30%', height: 'auto', textAlign:'center'}} id='error-screen__Icon'/>
            </span>           
            <h3 className={local.errorMessage} id='error-screen__h3_message'>
                {error?.errorMessage}
            </h3>                   
        </div>
        </>      
    );
};

export default ErrorScreen;
