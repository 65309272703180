import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ButtonBar } from 'V3Components/common';
import { GrayButton } from 'V3Components/common/buttons';
import { TABS } from 'V3Utilities/constants';

import { EquipmentProps } from '.';

import local from './local.scss';

const Equipment = ({ handleEquipmentDone, equipment }: EquipmentProps): JSX.Element => {
    const handleDoneClick = (): void => {
        handleEquipmentDone(TABS.BOOKING);
    };

    return (
        <>
            <div className={local.equipmentContainer}>
                <div className={local.equipmentHeader}>
                    <h1 id="equipment__h1_room-equipment">Room Equipment</h1>
                    <div id="equipment__h1_room-equipment-list" className={local.equipmentList}>
                        {equipment?.length > 0 ? (
                            <ul>
                                {equipment.map((equipment) => (
                                    <li>{equipment?.name}</li>
                                ))}
                            </ul>
                        ) : (
                            <div id="equipment__no-fixed-equipment">No fixed equipment</div>
                        )}
                    </div>
                </div>
                <ButtonBar>
                    <GrayButton
                        id="equipment__button_done"
                        className={local.equipmentButtonPanel}
                        onClick={handleDoneClick}
                        startIcon={<ChevronLeftIcon />}
                    >
                        DONE
                    </GrayButton>
                </ButtonBar>
            </div>
        </>
    );
};

export default Equipment;
