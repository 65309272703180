import { connect } from 'react-redux';
import { RootState } from 'V3Stores/index';
import bookingActions from 'V3Stores/Thunks';
import navigation from 'V3Stores/navigation';
import Tabs from './Tabs';

const mapStateToProps = (state: RootState) => {
    return {
        availableTabs: navigation.store.getAvailableTabs(state),
        currentTab: navigation.store.getCurrentTab(state),
    };
};

const mapActionsToProps = {
    setTab: navigation.actions.setTab,
    setCreateBookingTabsAction: navigation.actions.setCreateBookingTabs,
    onRefresh: bookingActions.refresh,
};

export type TabProps = ReturnType<typeof mapStateToProps> & typeof mapActionsToProps;

export default connect(mapStateToProps, mapActionsToProps)(Tabs);
