import { Guid } from 'guid-typescript';
import EntityInfoResponse from 'V3Models/DTOs/Responses/EntityInfoResponse';
import ResourceImageResponse from 'V3Models/DTOs/Responses/ResourceImageResponse';
import SearchEntitiesResponse from 'V3Models/DTOs/Responses/SearchEntitiesResponse';
import SearchEntitiesRequest from 'V3Models/EntitiesService/SearchEntitiesRequest';
import { $api } from 'V3Services/api';

export default class EntitiesServiceApiClient {
    
    public static search = async (requestBody: SearchEntitiesRequest): Promise<SearchEntitiesResponse> => {
        return $api.post<SearchEntitiesResponse>('/api/v1/Entities/SearchEntites', requestBody).then((response) => response.data);
    };

    public static getEntityInfo = async (resourceId: string): Promise<EntityInfoResponse> => {
        return $api.get(`/api/v1/Entities/GetEntityInfo?ResourceId=${resourceId}`).then((response) => response.data);
    };

    public static getImage = async (resourceImageId: Guid): Promise<ResourceImageResponse> => {
        return $api.get<ResourceImageResponse>(`/api/v1/Entities/ResourceImage?resourceId=${resourceImageId}`).then((response) => response.data);
    };
}