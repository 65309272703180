import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import local from './local.scss';

const CustomCheckbox = withStyles({
    root: {
        color: '#006496',
        '&$checked': {
            color: '#006496',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

interface CheckBoxProps {
    name: string;
    checked: boolean;
    onChange: (value: boolean) => void;
    label: string;
    labelPlacement?: 'top' | 'start' | 'bottom' | 'end';
    className?: string;
    idName?: string;
}

export default (props: CheckBoxProps): JSX.Element => {
    const { checked, onChange, name, label, labelPlacement = 'end', className,idName} = props;

    return (
        <div className={classnames(local.checkBox, className)} id={idName}>
            <FormControlLabel
                control={<CustomCheckbox checked={checked} onChange={onChange} name={name} />}
                label={label}
                labelPlacement={labelPlacement}
                id={`${idName}_form-control`}
            />
        </div>
    );
};