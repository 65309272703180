import { Area } from 'V3Models/Domain/Area';
import { Room } from 'V3Models/Domain/Room';
import sortBy from './sortBy';

export default (rooms: Room[]): Area[] => {
    const areas = [];
    // sort distinct sites by name
    const sites = sortBy(
        rooms.reduce((acc, curr) => {
            if (!acc.find((r) => r.siteID === curr.siteID)) {
                acc.push({ siteID: curr.siteID, siteName: curr.siteName });
            }
            return acc;
        }, []),
        (site) => site.siteName.toUpperCase()
    );

    // sort distinct areas by area display order and name
    sites.forEach((site) => {
        const siteAreas = rooms.filter((r) => r.siteID === site.siteID);

        const tempAreas = sortBy(
            siteAreas.reduce((acc, curr) => {
                if (!acc.find((r) => r.areaID === curr.areaID)) {
                    acc.push(<Area>{
                        areaID: curr.areaID,
                        areaName: curr.areaName,
                        areaDisplayOrder: curr.areaDisplayOrder,
                        siteName: site.siteName,
                        siteID: site.siteID,
                        selected: true,
                    });
                }
                return acc;
            }, []),
            (area) => `${(area.areaDisplayOrder || 0).toString().padStart(6, '0')}${area.areaName.toUpperCase()}`
        );
        tempAreas.forEach((area) => {
            areas.push(area);
        });
    });
    return areas;
};

