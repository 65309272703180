import Booking from 'V3Models/BookingService/Booking/Booking';
import BookingHistoryItemWithUser from 'V3Models/BookingService/BookingHistoryItemWithUser';
import { Room } from 'V3Models/Domain/Room';
import { PersonInterface } from 'V3Models/PersonSearchService/PersonSearchItem';

import {
    ACTION_TYPES,
    BOOKING_TYPE,
    BookingInitialiseAction,
    ResetBookingAction,
    ResetSelectedRoomsAction,
    SetBookingAttendeesAction,
    SetBookingCompleteAction,
    SetBookingIDAction,
    SetBookingInProgressAction,
    SetBookingNotesAction,
    SetSelectedRoomAction,
    SetBookingHistoryAction,
    SetBookingStatusAction,
    SetHasRunPopulateBookingAction,
    BOOKING_STATUS_TYPES,
    SetLoadingStatusAction,
    RoomBookingInfoLoadResetAction,
} from './types';

export const initialise = (): BookingInitialiseAction => ({ type: ACTION_TYPES.BOOKING_FORM_RESET });

export const setBooking = (booking: Booking) => {    
    return {
        type: ACTION_TYPES.SET_BOOKING,
        booking
    }
};

export const setBookingComplete = (payload: { type: BOOKING_TYPE; item?: Booking }): SetBookingCompleteAction => ({
    type: ACTION_TYPES.SET_BOOKING_COMPLETE,
    payload,
});

export const roomBookingInfoReset = (): RoomBookingInfoLoadResetAction => ({
    type: ACTION_TYPES.ROOM_BOOKING_INFO_RESET
});

export const setLoadingStatus = (loadingStatus: string): SetLoadingStatusAction => ({
    type: ACTION_TYPES.SET_LOADING_STATUS,
    loadingStatus 
})

export const setBookingID = (bookingID: string): SetBookingIDAction => ({
    type: ACTION_TYPES.SET_BOOKING_ID,
    bookingID,
});

export const setBookingInProgress = (inProgress: boolean): SetBookingInProgressAction => ({
    type: ACTION_TYPES.SET_BOOKING_IN_PROGRESS,
    inProgress,
}); // Can we use loading status for this????

export const setBookingAttendees = (attendees: number): SetBookingAttendeesAction => ({
    type: ACTION_TYPES.SET_BOOKING_ATTENDEES,
    attendees,
});

export const setBookingNotes = (bookingNotes: string): SetBookingNotesAction => ({
    type: ACTION_TYPES.SET_BOOKING_NOTES,
    bookingNotes,
});

export const setBookingsOnBehalfOf = (onBehalfOf: PersonInterface) => ({
    type: ACTION_TYPES.SET_BOOKINGS_ON_BEHALF_OF,
    onBehalfOf,
});

export const setBookingsTitle = (title: string) => ({
    type: ACTION_TYPES.SET_BOOKING_TITLE,
    title,
});

export const resetBooking = (): ResetBookingAction => ({ type: ACTION_TYPES.RESET_BOOKING });

export const resetSelectedRoom = (): ResetSelectedRoomsAction => ({
    type: ACTION_TYPES.SELECTED_ROOM_RESET,
});

export const setSelectedRoom = (room: Room): SetSelectedRoomAction => {    
    return {
    type: ACTION_TYPES.SET_SELECTED_ROOM,
    selectedRoom: room,
    }
};

export const setBookingHistory = (history: BookingHistoryItemWithUser[]): SetBookingHistoryAction => ({
    type: ACTION_TYPES.SET_BOOKING_HISTORY,
    history,
});

export const setBookingStatus = (bookingStatus: BOOKING_STATUS_TYPES): SetBookingStatusAction => ({
    type: ACTION_TYPES.SET_BOOKING_STATUS,
    bookingStatus
});

export const setHasRunPopulateBooking = (): SetHasRunPopulateBookingAction => ({
    type: ACTION_TYPES.SET_HAS_RUN_POPULATE_BOOKING,
});
