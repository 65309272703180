import { DateTime } from 'luxon';
import Booking from 'V3Models/BookingService/Booking/Booking';
import AppointmentInfo from 'V3Models/Domain/AppointmentInfo';
import AppointmentLocation from 'V3Models/Domain/AppointmentLocation';
import BookingUserPropertyInfo from 'V3Models/Domain/BookingUserPropertyInfo';
import OutlookAsyncResult from 'V3Models/DTOs/Responses/OutlookAsyncResult';
import { getAllAttendees } from 'V3Services/AppointmentService/AppointmentService';
import LoggingService from 'V3Services/LoggingService';
import TimezoneService from 'V3Services/TimezoneService';
import settingsUtil from 'V3Utilities/settingsUtil';

export default class OutlookService {
    public static NOTIFICATION_KEY = 'cloudbookingbInfo';

    public static loadCurrentBookingInfo = async (): Promise<BookingUserPropertyInfo> => {
        return new Promise<BookingUserPropertyInfo>((resolve) => {
            Office.context.mailbox.item.loadCustomPropertiesAsync((asyncResult) => {
                const customProps = asyncResult.value;
                const bookingDataAsString = customProps.get(settingsUtil.constants.ADDIN_USER_PROP_KEY);

                let bookingData: BookingUserPropertyInfo | undefined;
                if (bookingDataAsString && bookingDataAsString !== '') bookingData = JSON.parse(bookingDataAsString);

                return resolve(bookingData);
            });
        });
    };

    public static removeCustomProperty = async (name: string): Promise<OutlookAsyncResult> => {
        const { item } = Office.context.mailbox;

        const outlookAsyncResult: OutlookAsyncResult = {
            status: Office.AsyncResultStatus.Failed,
            message: '',
        };

        return new Promise<OutlookAsyncResult>((resolve) => {
            if (item.itemType === Office.MailboxEnums.ItemType.Appointment) {
                item.loadCustomPropertiesAsync((loadAsyncResult) => {
                    if (loadAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                        const customProps = loadAsyncResult.value;
                        customProps.remove(name);

                        customProps.saveAsync((saveAsyncResult) => {
                            if (saveAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                                outlookAsyncResult.status = Office.AsyncResultStatus.Succeeded;
                                resolve(outlookAsyncResult);
                            } else {
                                resolve(outlookAsyncResult);
                            }
                        });
                    } else {
                        resolve(outlookAsyncResult);
                    }
                });
            } else {
                resolve(outlookAsyncResult);
            }
        });
    };

    public static saveCustomProperty = async (key: string, value): Promise<OutlookAsyncResult> => {
        const { item } = Office.context.mailbox;

        const outlookAsyncResult: OutlookAsyncResult = {
            status: Office.AsyncResultStatus.Failed,
            message: '',
        };

        return new Promise<OutlookAsyncResult>((resolve) => {
            if (item.itemType === Office.MailboxEnums.ItemType.Appointment) {
                item.loadCustomPropertiesAsync((loadAsyncResult) => {
                    if (loadAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                        const customProps = loadAsyncResult.value;
                        customProps.set(key, value);

                        customProps.saveAsync((saveAsyncResult) => {
                            if (saveAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                                outlookAsyncResult.status = Office.AsyncResultStatus.Succeeded;
                                resolve(outlookAsyncResult);
                            } else {
                                resolve(outlookAsyncResult);
                            }
                        });
                    } else {
                        resolve(outlookAsyncResult);
                    }
                });
            } else {
                resolve(outlookAsyncResult);
            }
        });
    };

    public static setLocationAndSubject = async (location: AppointmentLocation, subject: string): Promise<OutlookAsyncResult> => {
        const { item } = Office.context.mailbox;
        return OutlookService.setItemLocationAndSubject(item, [location], subject);
    };

    public static setItemLocationAndSubject = async (item: any, locations: AppointmentLocation[], subject: string): Promise<OutlookAsyncResult> => {
        const outlookAsyncResult: OutlookAsyncResult = { status: Office.AsyncResultStatus.Succeeded, message: '' };
        // Get the subset of locations that have an Exchange id. These are the Exchange rooms.
        const exchangeIds: AppointmentLocation[] = locations.filter((loc) => loc.externalMailbox);
        const existingLocationDetails: Office.LocationDetails[] = [];
        const existingLocationIds: Office.LocationIdentifier[] = [];

        return new Promise<OutlookAsyncResult>((resolve) => {
            if (item.itemType === Office.MailboxEnums.ItemType.Appointment) {
                if (item.subject.setAsync && item.location.setAsync) {
                    item.subject.setAsync(subject, () =>
                        OutlookService.handleItemAndLocation(
                            item,
                            locations,
                            exchangeIds,
                            existingLocationIds,
                            existingLocationDetails,
                            resolve,
                            outlookAsyncResult
                        )
                    );
                }
                resolve(null);
            } else {
                outlookAsyncResult.status = Office.AsyncResultStatus.Failed;
                outlookAsyncResult.message = `Unexpected Outlook item type ${item.itemType}`;
                resolve(outlookAsyncResult);
            }
        });
    };

    private static handleItemAndLocation = async (
        item,
        locations,
        exchangeIds,
        existingLocationIds,
        existingLocationDetails,
        resolve,
        outlookAsyncResult
    ) => {
        if (locations.length > 0) {
            if (exchangeIds.length > 0) {
                item.enhancedLocation.getAsync((result) => {
                    const exchangeLocationIds = exchangeIds.map((loc) => {
                        return { id: loc.externalMailbox, type: Office.MailboxEnums.LocationType.Room };
                    });

                    existingLocationDetails = result.value;

                    if (existingLocationDetails) {
                        existingLocationIds = existingLocationDetails.map((dtls) => {
                            return {
                                id: dtls.locationIdentifier.id,
                                type: Office.MailboxEnums.LocationType.Room,
                            };
                        });
                    }

                    if (existingLocationIds?.length > 0) {
                        item.enhancedLocation.removeAsync(existingLocationIds, () => {
                            item.enhancedLocation.addAsync(exchangeLocationIds, () => {
                                resolve(outlookAsyncResult);
                            });
                        });
                    } else {
                        item.enhancedLocation.addAsync(exchangeLocationIds, () => {
                            resolve(outlookAsyncResult);
                        });
                    }
                });
            } else {
                // eslint-disable-next-line no-lonely-if
                const maxLocationTextLen = 255;
                const ellipsisReservedLen = 10;
                let numLocsAdded = 0;
                let location = '';
                // Format names of non-exchange locations into a semicolon-delimited list in the Location string.
                const validLocations = locations.filter((l) => l.name.length > 0);
                validLocations.forEach((loc) => {
                    if (location.length + loc.name.length < maxLocationTextLen - ellipsisReservedLen) {
                        location += `${loc.name};`;
                        ++numLocsAdded;
                    }
                });

                // If the location names won't all fit into Outlook's 255-character limit, finish off with an ellipsis chip.
                if (numLocsAdded < validLocations.length) location += ` +${validLocations.length - numLocsAdded}...`;

                item.location.setAsync(location, () => {
                    resolve(outlookAsyncResult);
                });
            }
        }
    };

    public static getLocationFromOutlook = async (): Promise<string> => {
        return new Promise<string>((resolve) => {
            const { item } = Office.context.mailbox;

            const { enhancedLocation } = item;
            let locationAddress = '';
            try {
                enhancedLocation.getAsync((locationAsyncResult) => {
                    if (locationAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                        if (locationAsyncResult.value === undefined || locationAsyncResult.value === null) {
                            resolve(null);
                        }
                        if (locationAsyncResult.value.length === 0) {
                            resolve(null);
                        } else {
                            const displayName = locationAsyncResult.value[0].displayName.includes('@')
                                ? locationAsyncResult.value[0].displayName
                                : null;
                            const emailAddress = locationAsyncResult.value[0].emailAddress !== '' ? locationAsyncResult.value[0].emailAddress : null;
                            locationAddress = emailAddress ?? displayName ?? null;
                            resolve(locationAddress);
                        }
                    } else {
                        resolve(null);
                    }
                });
            } catch (e) {
                resolve(null);
            }
        });
    };

    public static getAppointmentInfoFromOutlook = async (appointment): Promise<AppointmentInfo> => {
        const timezoneService = TimezoneService.getInstance();
        const attendees = await getAllAttendees();
        const newAppointmentInfo: AppointmentInfo = {
            ...appointment,
            siteStartDTM: null,
            siteEndDTM: null,
            title: '',
            attendeeCount: attendees.length,
            outlookStartTime: null,
            outlookEndTime: null,
            siteTimeZone_Name: 'UTC',
        };

        return new Promise<AppointmentInfo>((resolve) => {
            const { item } = Office.context.mailbox;

            const { start, end, subject } = item;

            const outlookTimezone = timezoneService.getIANAFromWindows(Office.context.mailbox.userProfile.timeZone);
            const siteTimezone = newAppointmentInfo.siteTimeZone_Name;

            // Get the start date from outlook and update the new appointment info
            start.getAsync((startAsyncResult) => {
                if (startAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                    const utcStartTime = DateTime.fromJSDate(startAsyncResult.value, { zone: 'utc' });
                    const siteStartTime = timezoneService.getSiteTimeFromUTC(utcStartTime, siteTimezone);
                    const outlookStartTime = timezoneService.getSiteTimeFromUTC(utcStartTime, outlookTimezone);
                    newAppointmentInfo.startDTMUTC = siteStartTime;
                    newAppointmentInfo.outlookStartTime = outlookStartTime;

                    // Get the end date from outlook and update the new appointment info
                    end.getAsync((endAsyncResult) => {
                        if (endAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                            const utcEndTime = DateTime.fromJSDate(endAsyncResult.value, { zone: 'utc' });
                            const siteEndTime = timezoneService.getSiteTimeFromUTC(utcEndTime, siteTimezone);
                            const outlookEndTime = timezoneService.getSiteTimeFromUTC(utcEndTime, outlookTimezone);

                            newAppointmentInfo.endDTMUTC = siteEndTime;
                            newAppointmentInfo.outlookEndTime = outlookEndTime;

                            // Get the subject from outlook and update the new appointment info
                            subject.getAsync((subjectAsyncResult) => {
                                if (subjectAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
                                    newAppointmentInfo.title = subjectAsyncResult.value.toString();
                                }

                                resolve(newAppointmentInfo);
                            });
                        } else {
                            resolve(newAppointmentInfo);
                        }
                    });
                } else {
                    resolve(newAppointmentInfo);
                }
            });
        });
    };

    public static getAppointmentItemSubject = (bookingInfo: Booking, userData: string): string => {
        let subject = userData;

        if (bookingInfo?.displayName?.length > 0) {
            subject = bookingInfo.displayName;
        }

        return subject;
    };

    public static updateAppointmentWithCBSaveResponse_SRB = async (bookingInfo: Booking, userData: string): Promise<OutlookAsyncResult> => {
        const { displayName, externalProperties } = bookingInfo.resources[0];

        let externalMailbox = '';
        if (externalProperties.length) {
            externalMailbox = externalProperties[0].externalMailbox;
        }
        const subject = OutlookService.getAppointmentItemSubject(bookingInfo, userData);

        const location = { externalMailbox, name: displayName };

        return OutlookService.setLocationAndSubject(location, subject);
    };

    public static showNotificationForBooking = async (isUpdate: boolean): Promise<void> => {
        const isUpdatedMessage = isUpdate ? 'Booking is updated for this calendar item. ' : 'Booking is created for this calendar item. ';
        const message = `${isUpdatedMessage}Please save/send this item to link between cloudbooking and outlook.`;

        const jsonMessage: Office.NotificationMessageDetails = {
            type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
            message,
            icon: 'infoIcon',
            persistent: false,
        };

        Office.context.mailbox.item.notificationMessages.getAllAsync((asyncResult: Office.AsyncResult<Office.NotificationMessageDetails[]>) => {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                if (asyncResult.value.find((item) => item.key && item.key === OutlookService.NOTIFICATION_KEY)) {
                    Office.context.mailbox.item.notificationMessages.replaceAsync(OutlookService.NOTIFICATION_KEY, jsonMessage);
                } else {
                    Office.context.mailbox.item.notificationMessages.addAsync(OutlookService.NOTIFICATION_KEY, jsonMessage);
                }
            }
        });
    };

    public static onDeleteBooking = async (appointment): Promise<void> => {
        await OutlookService.removeCustomProperty(settingsUtil.constants.PLUGIN_CUST_PROP_KEY);
        await OutlookService.setLocationAndSubject({ externalMailbox: '', name: '' }, appointment.title);
        const outlookAsyncResult = await OutlookService.removeCustomProperty(settingsUtil.constants.ADDIN_USER_PROP_KEY);
        if (outlookAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
            Office.context.mailbox.item.notificationMessages.removeAsync(OutlookService.NOTIFICATION_KEY);
        } else {
            LoggingService.Error('ServiceBooking.onClickDeleteBooking - Custom property was not removed for booking deletion');
        }
    };
}
