import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ReactComponent as CapacityIcon } from 'V3/assets/Capacity_Fill.svg';
import { Room } from 'V3Models/Domain/Room';
import local from './SelectedRoom.scss';

const SelectedRoom = ({ onLoadRoom: onLoadRoom, Room }: SelectedRoomProps): JSX.Element => {
    const { roomName: RoomName, capacity: Capacity } = Room;

    const handleLoadRoom = () => {
        onLoadRoom(Room);
    }

    return (
        <div className={local.room} tabIndex={0} role="button" onClick={handleLoadRoom} >
            <div className={local.leftColumn}>
                <div id='selected-room__icon__room-name' className={local.roomName} title={RoomName}>
                    {RoomName}
                </div>
                <div className={local.capacity}>
                    <CapacityIcon id='selected-room__icon__capacity'/>
                    {Capacity}
                </div>
            </div>
            <div className={local.rightColumn}>
                <ChevronRightIcon id='selected-room__icon__chevron-right' fontSize="inherit" className={local.chevron} />
            </div>
        </div>
    );
};

export default SelectedRoom;

interface SelectedRoomProps {
    Room: Room;
    onLoadRoom: (room: Room) => void;
}
