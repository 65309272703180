import _ from 'lodash';
import { DateTime } from 'luxon';
import AppointmentInfoInterface from 'V3Models/Domain/AppointmentInfo';
import OutlookAsyncResult from 'V3Models/DTOs/Responses/OutlookAsyncResult';
import LoggingService from 'V3Services/LoggingService';
import TimezoneService from 'V3Services/TimezoneService';

export const getAppointment = async (appointment: AppointmentInfoInterface, timezoneService: TimezoneService): Promise<AppointmentInfoInterface> => {         
    const { item } = Office.context.mailbox;
    const newAppointment: AppointmentInfoInterface = { ...appointment };
    newAppointment.title = await getSubject();

    if (item.itemType === Office.MailboxEnums.ItemType.Appointment) {
        const attendees = await getAllAttendees();
        newAppointment.attendeeCount = attendees.length;

        const outlookTimezone = timezoneService.getIANAFromWindows(Office.context.mailbox.userProfile.timeZone);
        newAppointment.startDTMUTC = await getStartDate();        
        newAppointment.outlookStartTime = timezoneService.getSiteTimeFromUTC(newAppointment.startDTMUTC, outlookTimezone);

        newAppointment.endDTMUTC = await getEndDate();
        newAppointment.outlookEndTime = timezoneService.getSiteTimeFromUTC(newAppointment.endDTMUTC, outlookTimezone);
    }

    return newAppointment;
};

export const getMailboxUser = (): Office.EmailUser => {
    const emailUser: Office.EmailUser = {
        displayName: Office.context.mailbox.userProfile.displayName,
        emailAddress: Office.context.mailbox.userProfile.emailAddress,
    };
    return emailUser;
};

export const getRequiredAttendees = async (): Promise<string[]> => {
    const { item } = Office.context.mailbox;
    const { requiredAttendees } = item;
    let recipients = [];

    return new Promise<string[]>((resolve) => {
        if (item.itemType === Office.MailboxEnums.ItemType.Appointment) {

            if(requiredAttendees && !requiredAttendees.getAsync){
                resolve(requiredAttendees.map((r) => r.emailAddress) || []);
            }

            else {
                requiredAttendees.getAsync((asyncResult) => {
                if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                    recipients = asyncResult.value.map((r) => r.emailAddress) || [];
                }
                resolve(recipients);
                });
            }
        } else {
            resolve(recipients);
        }
    });
};

export const getOptionalAttendees = async (): Promise<string[]> => {
    const { item } = Office.context.mailbox;
    const { optionalAttendees } = item;
    let recipients = [];

    return new Promise<string[]>((resolve) => {
        if (item.itemType === Office.MailboxEnums.ItemType.Appointment) {

            if(!optionalAttendees.getAsync && optionalAttendees){
                resolve(optionalAttendees.map((r) => r.emailAddress) || []);
            }

            else{
                optionalAttendees.getAsync((asyncResult) => {
                if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                    recipients = asyncResult.value.map((r) => r.emailAddress) || [];
                }
                resolve(recipients);
                });
            }
        } else {
            resolve(recipients);
        }
    });
};

export const getOrganizer = async (): Promise<Office.EmailUser> => {
    const { item } = Office.context.mailbox;

    return new Promise<Office.EmailUser>((resolve) => {

        if(item.organizer && !item.organizer.getAsync){
            resolve(item.organizer);
        }
        else if (item.organizer && item.organizer.getAsync) {
            Office.context.mailbox.item.organizer.getAsync({}, (asyncResult) => {
                if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                    const apptOrganizer = asyncResult.value;
                    resolve(apptOrganizer);
                } else {
                    LoggingService.Error('AppointmentService.getOrganizer - Error', asyncResult);
                    resolve(getMailboxUser());
                }
            });
        } else {
            resolve(getMailboxUser());
        }
    });
};

export const getAllAttendees = async (): Promise<string[]> => {
    const attendees = await getRequiredAttendees();
    const optionalAttendees = await getOptionalAttendees();
    const organizer = await getOrganizer();

    return _.uniq([...attendees, ...optionalAttendees, organizer.emailAddress]);
};

export const getStartDate = async (): Promise<DateTime> => {
    const { item } = Office.context.mailbox;

    return new Promise<DateTime>((resolve) => {
        
        if(!item.start.getAsync && item.start) {
            resolve(DateTime.fromJSDate(item.start, { zone: 'utc'}));
        }

        else {
            item.start.getAsync((asyncResult) => {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                LoggingService.Error('AppointmentService.getStartDate - Error', asyncResult);
            }            
            resolve(DateTime.fromJSDate(asyncResult.value, { zone: 'utc'}));
        });
        }
    });
};

export const getEndDate = async (): Promise<DateTime> => {
    const { item } = Office.context.mailbox;

    return new Promise<DateTime>((resolve) => {

        if(item.end && !item.end.getAsync){
            resolve(DateTime.fromJSDate(item.end, { zone: 'utc'}));
        } 

        else {
                item.end.getAsync((asyncResult) => {
                if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                    LoggingService.Error('AppointmentService.getEndDate - Error', asyncResult);
                }
                resolve(DateTime.fromJSDate(asyncResult.value, { zone: 'utc'}));
              });
         }

    });
};

export const getSubject = async (): Promise<string> => {
    const { item } = Office.context.mailbox;
    let subject = '';

    return new Promise<string>((resolve) => {
        if (item.itemType === Office.MailboxEnums.ItemType.Appointment) {

            if(item.subject && !item.subject.getAsync){
                resolve(item.subject);
            }

            item.subject.getAsync((asyncResult) => {
                    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                        LoggingService.Error('AppointmentService.getSubject - Error', asyncResult);
                    } else {
                        subject = asyncResult.value.toString();
                    }
                    resolve(subject);
            });        
                       
        } else {
            resolve(item.subject || subject);
        }
    });
};

// @todo: This method ignores the actual value of asyncResult and assumes it has succeeded always. This should be
// refactored to account for the asyncResult status value. Does this have any unintended consequences?
export const setSubject = async (subject: string): Promise<OutlookAsyncResult> => {
    const outlookAsyncResult: OutlookAsyncResult = {
        status: Office.AsyncResultStatus.Failed,
        message: '',
    };

    return new Promise<OutlookAsyncResult>((resolve) => {
        const { item } = Office.context.mailbox;

        if (item.itemType === Office.MailboxEnums.ItemType.Appointment) {
            // Left as unused so we don't forget that we should be using this variable when we refactor based on
            // todo comments above. After that we should remove this disable command
            // eslint-disable-next-line @typescript-eslint/no-unused-vars

            item.subject.setAsync(subject, (asyncResult) => {
                outlookAsyncResult.status = Office.AsyncResultStatus.Succeeded;
                resolve(outlookAsyncResult);
            });
        } else {
            resolve(outlookAsyncResult);
        }
    });
};

// @todo: This method ignores the actual value of asyncResult and assumes it has succeeded always. This should be
// refactored to account for the asyncResult status value. Does this have any unintended consequences?
export const addEnhancedLocation = async (emailAddress: string): Promise<OutlookAsyncResult> => {
    const outlookAsyncResult: OutlookAsyncResult = {
        status: Office.AsyncResultStatus.Failed,
        message: '',
    };

    return new Promise<OutlookAsyncResult>((resolve) => {
        if (Office.context.mailbox.item.enhancedLocation) {
            const locations = [
                {
                    id: emailAddress,
                    type: Office.MailboxEnums.LocationType.Room,
                },
            ];

            // Left as unused so we don't forget that we should be using this variable when we refactor based on
            // todo comments above. After that we should remove this disable command
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            Office.context.mailbox.item.enhancedLocation.addAsync(locations, (asyncResult) => {
                outlookAsyncResult.status = Office.AsyncResultStatus.Succeeded;
                resolve(outlookAsyncResult);
            });
        } else {
            resolve(outlookAsyncResult);
        }
    });
};

// @todo: This method ignores the actual value of asyncResult and assumes it has succeeded always. This should be
// refactored to account for the asyncResult status value. Does this have any unintended consequences?
export const addLocation = async (locationName: string): Promise<OutlookAsyncResult> => {
    return new Promise<OutlookAsyncResult>((resolve) => {
        const { item } = Office.context.mailbox;
        const outlookAsyncResult: OutlookAsyncResult = {
            status: Office.AsyncResultStatus.Failed,
            message: '',
        };

        if (item.itemType === Office.MailboxEnums.ItemType.Appointment) {
            // Left as unused so we don't forget that we should be using this variable when we refactor based on
            // todo comments above. After that we should remove this disable command
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            item.location.setAsync(locationName, (asyncResult) => {
                outlookAsyncResult.status = Office.AsyncResultStatus.Succeeded;
                resolve(outlookAsyncResult);
            });
        } else {
            resolve(outlookAsyncResult);
        }
    });
};

export const addOutlookItemHandlers = (handler: () => void): void => {
    if (Office && Office.context.mailbox.item.removeHandlerAsync) {
        const { item } = Office.context.mailbox;

        item.removeHandlerAsync(Office.EventType.RecipientsChanged, (recipientsChangeAsyncResult) => {
            if (recipientsChangeAsyncResult.status === Office.AsyncResultStatus.Failed) {
                LoggingService.Error('AppointmentService.addOutlookItemHandlers.RecipientsChanged - Error', recipientsChangeAsyncResult);
            } else {
                item.addHandlerAsync(Office.EventType.RecipientsChanged, () => {
                    if (handler) handler();
                });
            }
        });
        item.removeHandlerAsync(Office.EventType.AppointmentTimeChanged, (appointmentTimeAsyncResult) => {
            if (appointmentTimeAsyncResult.status === Office.AsyncResultStatus.Failed) {
                LoggingService.Error('AppointmentService.addOutlookItemHandlers.AppointmentTimeChanged - Error', appointmentTimeAsyncResult);
            } else {
                item.addHandlerAsync(Office.EventType.AppointmentTimeChanged, () => {
                    if (handler) handler();
                });
            }
        });
    }
};

export const removeOutlookItemHandlers = (): void => {
    if (Office && Office.context.mailbox.item.removeHandlerAsync) {
        const { item } = Office.context.mailbox;

        item.removeHandlerAsync(Office.EventType.RecipientsChanged, (recipientsChangeAsyncResult) => {
            if (recipientsChangeAsyncResult.status === Office.AsyncResultStatus.Failed) {
                LoggingService.Error('AppointmentService.removeOutlookItemHandlers.RecipientsChanged - Error', recipientsChangeAsyncResult);
            }
        });

        item.removeHandlerAsync(Office.EventType.AppointmentTimeChanged, (appointmentTimeAsyncResult) => {
            if (appointmentTimeAsyncResult.status === Office.AsyncResultStatus.Failed) {
                LoggingService.Error('AppointmentService.removeOutlookItemHandlers.AppointmentTimeChanged - Error', appointmentTimeAsyncResult);
            }
        });
    }
};