import SiteGroupDTO from 'V3Models/DTOs/SiteGroupDTO';
import SitesServiceApiClient from './SitesServiceApiClient';
import { Site } from 'V3Models/Domain/Site';

export default class SiteService {
    public static getDefaultSiteObject = (): Site => {
        return {
            siteID: '',
            siteName: '',
            areas: [],
            timeZoneName: '',
            timeZoneIANAName: '',
            isDefault: false,
            isSelected: false,
            siteGroup: '',
            siteGroupID: '',
            supportText: ''
        };
    };

    public static mapSites = (results: SiteGroupDTO[]): Site[] => {
        const mappedSites: Site[] = [];
        results.forEach((siteGroup) => {
            siteGroup.sites.forEach((site) => {
                const tempSite: Site = {
                    siteID: site.id,
                    siteName: site.name,
                    siteGroup: siteGroup.name,
                    siteGroupID: siteGroup.id,
                    timeZoneName: site.timeZoneName || site.timeZoneDescription,
                    timeZoneIANAName: site.timeZoneIANAName,
                    isDefault: site.isDefault,
                    isSelected: site.isSelected ?? false,
                    areas: [],
                    supportText: site.supportText
                 };
                mappedSites.push(tempSite);
            });
        });

        return mappedSites;
    };

    public static getSites = async (): Promise<Site[]> => {
        const response = await SitesServiceApiClient.getSites();
        if (response.results) {
            const siteList = SiteService.mapSites(response.results);
            return siteList;
        }
        
        return [];
    };
}
