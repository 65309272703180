import { connect } from 'react-redux';
import route from 'V3/stores/route';
import { RootState } from 'V3Stores/index';
import Panel from './Panel';

const mapStateToProps = (state: RootState) => ({
    isWebClient: route.store.getIsWebClient(state),
});

export default connect(mapStateToProps)(Panel);
