import React, { useEffect } from 'react';
import { AppContainer } from 'react-hot-loader';
import { loadTheme } from '@fluentui/react';
import { Guid } from 'guid-typescript';
import { AppProps } from 'V3Components/App/index';
import Credential from 'V3Models/Domain/Credential';
import RefreshCBLoginResponse from 'V3Models/Login/RefreshLoginToCBResponse';
import AuthenticationServiceApi from 'V3Services/AuthenticationService';
import BookingServiceApiClient from 'V3Services/BookingService/BookingServiceApiClient';
import LocalisationService from 'V3Services/LocalisationService';
import OutlookService from 'V3Services/OutlookService';
import UserService from 'V3Services/UserService';
import * as constants from 'V3Utilities/constants';
import settingsUtil from 'V3Utilities/settingsUtil';
import { extractUserInfoFromLoginRefreshResponse } from 'V3Utilities/userInfo/extractUserInfoFromLoginResponse';

/* globals Office */
import Main from '../Main';

loadTheme({
    palette: {
        themePrimary: '#1796c6',
        themeLighterAlt: '#f1f7fb',
        themeLighter: '#c8e2ee',
        themeLight: '#9dc9e0',
        themeTertiary: '#4d9ac0',
        themeSecondary: '#1473a3',
        themeDarkAlt: '#005a87',
        themeDark: '#004c72',
        themeDarker: '#003854',
        neutralLighterAlt: '#f8f8f8',
        neutralLighter: '#f4f4f4',
        neutralLight: '#eaeaea',
        neutralQuaternaryAlt: '#dadada',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c8c8',
        neutralTertiary: '#bab8b7',
        neutralSecondary: '#a3a2a0',
        neutralPrimaryAlt: '#8d8b8a',
        neutralPrimary: '#323130',
        neutralDark: '#605e5d',
        black: '#494847',
        white: '#ffffff',
    },
});

const App = ({ setRoute, setIsWebClient, setOnBehalfOfUser, setUser, loadBooking }: AppProps): JSX.Element => {
    const setLanguageSettings = (): void => {
        // Get the language setting for UI display in the host application.
        const localisationService = LocalisationService.getInstance();
        const localeString = localisationService.getLanguage();

        if (!localeString) {
            localisationService.setLanguage(Office.context.displayLanguage);
        }
    };

    const getLoginInfoIfUserAuthenticate = async (): Promise<RefreshCBLoginResponse> => {
        const credential: Credential = UserService.getCredential();

        if (credential.username && credential.msgraphToken) {
            return await AuthenticationServiceApi.refreshUserLoginData(credential.username);
        }
        return null;
    };

    const loadCurrentBookingInfo = async (): Promise<string> => {
        return new Promise<string>((resolve) => {
            Office.context.mailbox.item.loadCustomPropertiesAsync((asyncResult) => {
                const customProps = asyncResult.value;
                const m365BookingProp = customProps?.get(settingsUtil.constants.ADDIN_USER_PROP_KEY) || '';
                const bookingProperties = customProps?.get(settingsUtil.constants.PLUGIN_CUST_PROP_KEY) || '';
                let bookingId: string | undefined;
                if (m365BookingProp && m365BookingProp !== '') {
                    bookingId = JSON.parse(m365BookingProp).bookingId;
                } else if (bookingProperties && bookingProperties !== '') {
                    const data = bookingProperties.split(',');
                    bookingId = data[1];
                }

                return resolve(bookingId);
            });
        });
    };

    const getBookingIdUsingAppointmentInfo = async (): Promise<string> => {
        const appointmentInfo = await OutlookService.getAppointmentInfoFromOutlook({});
        const mailbox = await OutlookService.getLocationFromOutlook();
        const bookingId = await BookingServiceApiClient.getBookingUsingInfo(
            appointmentInfo.startDTMUTC.toISO(),
            appointmentInfo.endDTMUTC.toISO(),
            mailbox
        );

        return new Promise<string>((resolve) => {
            resolve(bookingId.toString() === Guid.createEmpty().toString() ? null : bookingId.toString());
        });
    };

    const renderLoggedInUIs = async (): Promise<void> => {
        const isAppointmentReadMode = !Office.context.mailbox.item.subject.setAsync;
        if (isAppointmentReadMode) {
            setRoute(constants.ROUTES.NOT_FOUND);
            return;
        }

        const outlookBookingData = await loadCurrentBookingInfo();
        const cbBookingId = await getBookingIdUsingAppointmentInfo();

        const bookingId = outlookBookingData ?? cbBookingId;

        await loadBooking(bookingId);

        setRoute(constants.ROUTES.SERVICE_SELECTION);
    };

    useEffect(() => {
        Office.initialize = async (): Promise<void> => {
            setIsWebClient(Office.context.diagnostics.platform.toString() === 'OfficeOnline');
            setLanguageSettings();

            const loginInfo = await getLoginInfoIfUserAuthenticate();
            if (loginInfo?.accessToken) {
                const user = extractUserInfoFromLoginRefreshResponse(loginInfo);
                setUser(user);
                localStorage.setItem('accessToken', loginInfo.accessToken);

                const onBehalfOfUser = await UserService.getOnBehalfOfUser(user);
                setOnBehalfOfUser(onBehalfOfUser);
                renderLoggedInUIs();
            } else {
                setRoute(constants.ROUTES.LOGIN, { isOfficeInitialized: true });
            }
        };
    }, []);

    return (
        <AppContainer>
            <Main />
        </AppContainer>
    );
};

export default App;
function getState(): import('../../stores').RootState {
    throw new Error('Function not implemented.');
}
