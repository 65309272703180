import AppointmentInfoInterface from 'V3Models/Domain/AppointmentInfo';
import { Action } from 'V3Utilities/Action';
import { REDUCER_NAMES } from 'V3Utilities/constants';

const reducerName = `${REDUCER_NAMES.ROOMS}/${REDUCER_NAMES.APPOINTMENT}`;

export const ACTION_TYPES = {
    SET_APPOINTMENT: `${reducerName}/SET_APPOINTMENT`,
    SET_APPOINTMENT_AND_CHANGED: `${reducerName}/SET_APPOINTMENT_AND_CHANGED`,
    APPOINTMENT_RESET: `${reducerName}/APPOINTMENT_RESET`,
};

export interface SetAppointmentAndChangedAction extends Action {
    appointment: AppointmentInfoInterface;
    changed: boolean;
}

export interface SetAppointmentHasChangedAction extends Action {
    changed: boolean;
}

export interface SetAppointmentAction extends Action {
    appointment: AppointmentInfoInterface;
}

export type ResetAppointmentAction = Action;

export type AppointmentActionType = SetAppointmentAndChangedAction | SetAppointmentAction | ResetAppointmentAction;

export interface AppointmentStateInterface {
    item: AppointmentInfoInterface;
    loadingStatus: string;
    changed: boolean;
}
