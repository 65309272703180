import { DateTime } from 'luxon';
import Booking from 'V3Models/BookingService/Booking/Booking';
import AppointmentInfoInterface from 'V3Models/Domain/AppointmentInfo';
import { getAppointment } from 'V3Services/AppointmentService/AppointmentService';
import ErrorHandlingService from 'V3Services/ErrorHandlingService';
import LoggingService from 'V3Services/LoggingService';
import OutlookService from 'V3Services/OutlookService/OutlookService';
import TimezoneService from 'V3Services/TimezoneService/TimezoneService';
import appointment from 'V3Stores/appointment';
import booking from 'V3Stores/booking';
import bookingForm from 'V3Stores/booking';
import errorState from 'V3Stores/errorState';
import { ErrorLevel } from 'V3Stores/errorState/actionTypes';
import filters from 'V3Stores/filters';
import { AppThunk } from 'V3Stores/index';
import navigation from 'V3Stores/navigation';
import referenceData from 'V3Stores/organisationalStructure';
import { LOADING_STATUS, TABS } from 'V3Utilities/constants';
import settingsUtil from 'V3Utilities/settingsUtil';

export const loadAppointment = (): AppThunk => async (dispatch, getState) => {
    try {        
        const selectedAppointment = appointment.store.getAppointment(getState());
        const timezoneService = TimezoneService.getInstance();
        const appointmentInfo = await getAppointment(selectedAppointment, timezoneService);
        const currentTab = navigation.store.getCurrentTab(getState());
        dispatch(isBookingInPast(appointmentInfo.startDTMUTC, currentTab))
        dispatch(appointment.store.setAppointmentAndChanged(appointmentInfo, false));
        dispatch(filters.store.filtersSetAttendees(appointmentInfo.attendeeCount));
        dispatch(bookingForm.store.setBookingAttendees(appointmentInfo.attendeeCount));
    } catch (e) {
        ErrorHandlingService.HandleSystemError('OA/LA', e);
    }
};

export const isBookingInPast = (bookingStartTime, currentTab) => (dispatch) => {    
    
    if (bookingStartTime < DateTime.utc()) {      
        
        if(currentTab !== TABS.ERROR){
            dispatch(navigation.store.setPreviousTab(currentTab));
        } 
        dispatch(navigation.store.setAvailableTabs([TABS.ERROR]));
        dispatch(navigation.store.setCurrentTab(TABS.ERROR));
        dispatch(
            errorState.store.setError({
                errorLevel: ErrorLevel.WARNING,
                errorMessage: 'A booking cannot be created in the past',
            })
        );
        return true
    }
    return false
}

export const refresh = (): AppThunk => async (dispatch, getState) => {
    await loadAppointment()(dispatch, getState, null);
};

export const removeEnhancedLocations = async (outlookItem): Promise<void> => {
    let existingLocationDetails: Office.LocationDetails[] = [];
    let existingLocationIds: Office.LocationIdentifier[] = [];

    outlookItem.enhancedLocation.getAsync((result) => {
        existingLocationDetails = result.value;
        if (existingLocationDetails) {
            existingLocationIds = existingLocationDetails.map((dtls) => {
                return {
                    id: dtls.locationIdentifier.id,
                    type: Office.MailboxEnums.LocationType.Room,
                };
            });
        }
        return new Promise((resolve) => {
            if (existingLocationIds?.length > 0) {
                outlookItem.enhancedLocation.removeAsync(existingLocationIds, () => {
                    resolve(null);
                });
            } else {
                resolve(null);
            }
        });
    });
};


export const removeLocations = async (outlookItem): Promise<void> => {
    outlookItem.location.setAsync('');
};

export const deleteAppointment = async (appointment: AppointmentInfoInterface) => {
    const { item } = Office.context.mailbox;
    await removeEnhancedLocations(item);
    await OutlookService.removeCustomProperty(settingsUtil.constants.PLUGIN_CUST_PROP_KEY);
    await OutlookService.setLocationAndSubject({ externalMailbox: '', name: '' }, appointment.title);
    const outlookAsyncResult = await OutlookService.removeCustomProperty(settingsUtil.constants.ADDIN_USER_PROP_KEY);
    if (outlookAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
        item.notificationMessages.removeAsync(OutlookService.NOTIFICATION_KEY);
    } else {
        ErrorHandlingService.HandleSystemError('OA/DA');
    }
};

export const refreshFromOutlook = (): AppThunk => async (dispatch, getState) => {        
    const state = getState();
    const isEdit = booking.store.getIsEdit(state);
    const appointmentInfo = appointment.store.getAppointment(state);
    let currentTab = navigation.store.getCurrentTab(state);
    const outlookAppointment = await OutlookService.getAppointmentInfoFromOutlook(appointmentInfo);

    // update appointment
    dispatch(appointment.store.setAppointmentAndChanged(outlookAppointment, true));    
    if (!dispatch(isBookingInPast(outlookAppointment.endDTMUTC, currentTab))) {     
        if(currentTab === TABS.ERROR){
            const previousTab = navigation.store.getPreviousTab(getState());
            
            if(!previousTab || previousTab === TABS.ERROR){
                currentTab = TABS.HOME
            }

            var newTab = previousTab;
            if(!previousTab){
                newTab = TABS.HOME;
            }

            dispatch(navigation.store.setAvailableTabs([newTab]));
            dispatch(navigation.store.setCurrentTab(newTab));
            dispatch(errorState.store.clearErrors());
        }
    }

    // update filters with attendee count (if on first tab)
    if (currentTab === TABS.HOME) {
        dispatch(filters.store.filtersSetAttendees(outlookAppointment.attendeeCount));
        dispatch(referenceData.store.roomsLoadReset());
        dispatch(referenceData.store.referenceDataLoadReset());
        dispatch(bookingForm.store.resetSelectedRoom());  
    }

    // Check Appointment times have changed - if so reload booking.
    if (
        currentTab in [TABS.HOME, TABS.ERROR] &&
        !isEdit &&
        (outlookAppointment.startDTMUTC !== appointmentInfo.siteStartDTM || outlookAppointment.endDTMUTC !== appointmentInfo.endDTMUTC)
    ) {
        dispatch(referenceData.store.setSitesLoadingStatus(LOADING_STATUS.REQUIRED));
        dispatch(referenceData.store.setRoomsLoadingStatus(LOADING_STATUS.REQUIRED));
    }

    // if single room selected, check capacity
    const selectedRoom = bookingForm.store.getSelectedRoom(state);
    const selectedRoomCapacity = referenceData.store.getRoom(selectedRoom?.roomID)(state)?.capacity;

    // can be null if changed to all day and refresh clicked - room will not be available in reference data
    if (!selectedRoomCapacity) {
        dispatch(navigation.store.setCurrentTab(TABS.HOME));
    } else if (outlookAppointment.attendeeCount > selectedRoomCapacity) {
        dispatch(appointment.store.setAppointmentAndChanged({ ...outlookAppointment, attendeeCount: selectedRoomCapacity }, true));
        // todo: show error dialog instead of notification
        dispatch(filters.store.filtersSetAttendees(outlookAppointment.attendeeCount));
        dispatch(referenceData.store.roomsLoadReset());
        dispatch(referenceData.store.referenceDataLoadReset());
    } else {
        dispatch(bookingForm.store.setBookingAttendees(outlookAppointment.attendeeCount));
    }
};

export const updateOutlookAppointment = async ( responseBooking: Booking, userData: string): 
Promise<any> => {
        await OutlookService.updateAppointmentWithCBSaveResponse_SRB(responseBooking, userData);
};

export const showNotificationForBooking = async (isUpdate: boolean): Promise<any> => {
    await OutlookService.showNotificationForBooking(isUpdate);
};

export const saveOfficeLoginDetails =
    (email: string, msgraphToken: string): AppThunk =>
    async () => {
        return new Promise<any>((resolve) => {
            Office.context.roamingSettings.set('username', email);
            Office.context.roamingSettings.set('msgraphToken', msgraphToken);

            Office.context.roamingSettings.saveAsync((asyncResult: Office.AsyncResult<void>) => {
                LoggingService.Info('Login.saveOfficeLoginDetails - RoamingSettings saved', asyncResult);
                resolve('');
            });
        });
    };
