import { connect } from 'react-redux';
import Booking from 'V3Models/BookingService/Booking/Booking';
import AppointmentInfoInterface from 'V3Models/Domain/AppointmentInfo';
import { Site } from 'V3Models/Domain/Site';
import appointment from 'V3Stores/appointment';
import booking from 'V3Stores/booking';
import { RootState } from 'V3Stores/index';
import referenceData from 'V3Stores/organisationalStructure';
import AppointmentInfo from './AppointmentInfo';

interface OwnProps {
    appointment: AppointmentInfoInterface;
    focusedSite: Site;
    selectedRoomID: string;
    is24HoursTimeFormat: boolean;
    roomBookingInfo: Booking;
}

const mapStateToProps = (state: RootState): OwnProps => {
     
    const isEdit = booking.store.getIsEdit(state);
    const selectedRoom = booking.store.getSelectedRoom(state);
    const bookingFromStore = booking.store.getBooking(state);    

    const focusedSite = 
    ( 
        isEdit ? referenceData.store.getSite(bookingFromStore?.resources[0]?.siteId)(state) //resources???
               : ( selectedRoom ? referenceData.store.getSite(selectedRoom.siteID)(state) : null )
    );

    const { is24HoursTimeFormat } = state.user.user;

    return {
        appointment: appointment.store.getAppointment(state),
        focusedSite,
        selectedRoomID : selectedRoom?.roomID,
        is24HoursTimeFormat,
        roomBookingInfo: booking.store.getBooking(state)
    };
};

const mapDispatchToProps = {
    setBookingsTitle: (value: string) => booking.store.setBookingsTitle(value),
};

export type AppointmentInfoProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentInfo);
