import React, { useEffect } from 'react';
import classnames from 'classnames';
import BookingDeletePanel from 'V3Components/BookingDeletePanel';
import BookingHistory from 'V3Components/BookingDetails/BookingHistory';
import Equipment from 'V3Components/BookingDetails/Equipment';
import BookingResponsePanel from 'V3Components/BookingResponsePanel';
import Buttons from 'V3Components/Buttons';
import { ContentLoader } from 'V3Components/Common';
import Progress from 'V3Components/Common/Progress';
import ErrorScreen from 'V3Components/ErrorScreen/ErrorScreen';
import InfoPanel from 'V3Components/InfoPanel';
import ServiceSelection from 'V3Components/ServiceSelection';
import Tabs from 'V3Components/Tabs';
import { addOutlookItemHandlers, removeOutlookItemHandlers } from 'V3Services/AppointmentService/AppointmentService';
import { TABS } from 'V3Utilities/constants';
import BookingDetailsComponent from '../../components/BookingDetails/BookingDetails';
import useScrollToTop from '../../utilities/hooks/useScrollToTop';
import { ServiceSelectionRoutesProps } from '.';
import local from './local.scss';

const ServiceSelectionRoutes = (props: ServiceSelectionRoutesProps): JSX.Element => {
    const {
        referenceDataLoadingStatus,
        loadOrganisationStructureData,
        currentTab,
        loadAppointment,
        appointmentLoadingStatus,
        isWebClient,
        refreshFromOutlook,
        bookingInProgress,
        error,
    } = props;

    useEffect(() => {
        addOutlookItemHandlers(refreshFromOutlook);
        return () => removeOutlookItemHandlers();
    }, []);

    const scrollRef = useScrollToTop(currentTab);
    
    if (bookingInProgress) {
        return <Progress />;
    }
    
    return (
        <ContentLoader loadStatus={appointmentLoadingStatus} load={loadAppointment}>
            <ContentLoader loadStatus={referenceDataLoadingStatus} load={loadOrganisationStructureData}>
                <div className={local.layout}>
                    <div>
                        <Tabs />
                    </div>
                    <div
                        id="service-selection-routes__tabs"
                        className={classnames(local.pageContent, isWebClient ? null : local.desktopClient)}
                        ref={scrollRef}
                    >
                        {currentTab === TABS.HOME && <ServiceSelection />}
                        {currentTab === TABS.BOOKING && <BookingDetailsComponent />}
                        {currentTab === TABS.HISTORY && <BookingHistory />}
                        {currentTab === TABS.EQUIPMENT && <Equipment />}
                        {currentTab === TABS.ERROR && <ErrorScreen error={error} />}
                    </div>
                    <div>
                        <Buttons />
                    </div>
                </div>
            </ContentLoader>
            <BookingResponsePanel />
            <BookingDeletePanel />
            <InfoPanel />
        </ContentLoader>
    );
};

export default ServiceSelectionRoutes;
