import { connect } from 'react-redux';
import { PersonInterface } from 'V3Models/PersonSearchService/PersonSearchItem';
import UserService from 'V3Services/UserService';
import bookingForm from 'V3Stores/booking';
import { RootState } from 'V3Stores/index';
import BookingForm from './BookingForm';

const mapStateToProps = (state: RootState) => ({
    onBehalfOfSelector: bookingForm.store.getBookingOnBehalfOf,
    booking: bookingForm.store.getBooking(state),
    capacity: bookingForm.store.getSelectedRoom(state)?.capacity,
    roomPermissions: UserService.getRoomBookingPermissions(),
});

const mapDispatchToProps = {
    setBookingAttendees: (attendees: number) => bookingForm.store.setBookingAttendees(attendees),
    setBookingNotes: (bookingNotes: string) => bookingForm.store.setBookingNotes(bookingNotes),
    setBookingFormOnBehalfOf: (onBehalfOf: PersonInterface) => bookingForm.store.setBookingsOnBehalfOf(onBehalfOf),
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingForm);
export type BookingDetailsProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
