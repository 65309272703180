import { connect } from 'react-redux';
import { loginToCBSuccess } from 'V3Stores/Thunks/authActions';
import {
    setLoginAuthSchemesSelectionActive,
    setLoginAuthSchemesSelectionDone,
    setLoginCBFailed,
    setLoginLoading,
    setLoginTenantsSelectionActive,
    setLoginTenantsSelectionIsActive,
    setLoginTenantsSelectionDone,
} from 'V3Stores/auth/storeActions';
import {
    getAuthSchemeSelection,
    getCBLoginError,
    getInProgress,
    getIsCBLoginSuccess,
    getIsOfficeLoginError,
    getIsOfficeLoginSuccess,
    getTenantsSelection,
    getUsername,
} from 'V3Stores/auth/storeSelectors';
import { RootState } from 'V3Stores/index';
import { setRoute } from 'V3Stores/route/storeActions';
import { setUser } from 'V3Stores/user/storeActions';

import Login from './Login';
import { saveOfficeLoginDetails } from 'V3Stores/Thunks/outlookActions';

const mapStateToProps = (state: RootState) => {
    return {
        inProgress: getInProgress(state),
        username: getUsername(state),
        CBLoginError: getCBLoginError(state),
        isCBLoginSuccess: getIsCBLoginSuccess(state),
        isOffice365LoginError: getIsOfficeLoginError(state),
        isOffice365LoginSuccess: getIsOfficeLoginSuccess(state),
        tenantsSelection: getTenantsSelection(state),
        authSchemeSelection: getAuthSchemeSelection(state),
    };
};

const mapDispatchToProps = {
    setRoute,
    setUser,
    setLoginCBFailed,
    setLoginLoading,
    setLoginTenantsSelectionIsActive,
    setLoginTenantsSelectionActive,
    setLoginTenantsSelectionDone,
    setLoginAuthSchemesSelectionActive,
    setLoginAuthSchemesSelectionDone,
    loginToCBSuccess,
    saveOfficeLoginDetails,
};

export type LoginProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Login);
