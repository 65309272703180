import React from 'react';
import LocalisationService from 'V3Services/LocalisationService';

import local from './local.scss';

const NotFound = (): JSX.Element => {
    const localisationService = LocalisationService.getInstance();

    return (
        <div className={local.notFound} id='not-found__block'>
            <div id='not-found__block_outlook-item-not-associated'>This outlook item is not associated with {localisationService.strings.cloudbooking}.</div>
            <div id='not-found__block_go-to-appointment'>
                Go to appointment <em>Edit</em> view to create a booking
            </div>
        </div>
    );
};

export default NotFound;
