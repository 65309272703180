import { connect } from 'react-redux';
import { Room } from 'V3Models/Domain/Room';
import bookingForm from 'V3Stores/booking';
import { RootState } from 'V3Stores/index';
import referenceData from 'V3Stores/organisationalStructure';
import sortRoomsForSites from 'V3Utilities/sortRoomsForSites';
import SelectedRooms from './SelectedRoomPanel';
import bookingActions from 'V3Stores/Thunks';

const mapStateToProps = (state: RootState) => ({
        sites: sortRoomsForSites(
            referenceData.store
                .getRooms(state)
                .filter((item) => bookingForm.store.getSelectedRoom(state)?.roomID === item.roomID)
                .map<Room>((item) => ({
                    restrictedTo: null,
                    areaDisplayOrder: item.areaDisplayOrder,
                    areaID: item.areaID,
                    areaName: item.areaName,
                    capacity: item.capacity,
                    displayRestrictionIcon: false,
                    equipment: [],
                    isFilterMatch: true,
                    isSelected: false,
                    roomID: item.roomID,
                    roomName: item.roomName,
                    siteID: item.siteID,
                    siteName: item.siteName,
                    effectiveMaxBookingDateUTC: item.effectiveMaxBookingDateUTC,
                    resourceImageId: item.resourceImageId,
                    description: item.description,
                    externalProperties: item.externalProperties                    
                }))
        )
});

const mapDispatchToProps = {
    loadRoomInfo: bookingActions.loadRoomInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedRooms);

export type SelectedRoomsProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
