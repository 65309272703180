import {
    ACTION_TYPES,
    NavigationStateInterface,
    RemoveTabAction,
    SetAvailableTabsAction,
    SetCurrentTabAction,
    SetInfoMessageAction,
    SetOperationInProgressAction,
    SetPreviousTabAction,
    SetShowAppointmentChangedAction,
    SetShowDeleteBookingAction,
    TabsActionType,
} from './types';

export const initialState: NavigationStateInterface = {
    availableTabs: [],
    currentTab: null,
    previousTab: null,
    showDeleteBooking: false,
    showAppointmentChanged: false,
    infoMessage: null,
    operationInProgress: false,
};

export default (state = initialState, action: TabsActionType): NavigationStateInterface => {
    switch (action.type) {
        case ACTION_TYPES.SET_OPERATION_IN_PROGRESS:
            return {
                ...state,
                operationInProgress: (action as SetOperationInProgressAction).value,
            };
        case ACTION_TYPES.SET_AVAILABLE_TABS:
            return {
                ...state,
                availableTabs: (action as SetAvailableTabsAction).tabs,
            };

        case ACTION_TYPES.SET_CURRENT_TAB:
            return {
                ...state,
                currentTab: (action as SetCurrentTabAction).tab,
            };
        
        case ACTION_TYPES.PREVIOUS_TAB: 
            return {
                ...state,
                previousTab: (action as SetPreviousTabAction).tab
            }
        case ACTION_TYPES.SET_SHOW_DELETE_BOOKING:
            return {
                ...state,
                showDeleteBooking: (action as SetShowDeleteBookingAction).value,
            };

        case ACTION_TYPES.SET_SHOW_APPOINTMENT_CHANGED:
            return {
                ...state,
                showAppointmentChanged: (action as SetShowAppointmentChangedAction).value,
            };

        case ACTION_TYPES.SET_INFO_MESSAGE:
            return {
                ...state,
                infoMessage: (action as SetInfoMessageAction).value,
            };

        case ACTION_TYPES.REMOVE_TAB:
            return {
                ...state,
                availableTabs: state.availableTabs.filter((t) => t != (action as RemoveTabAction).tab),
            };
        default: {
            return state;
        }
    }
};
