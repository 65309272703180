import React, { useState } from 'react';
import { Fabric, mergeStyles, MessageBar, Shimmer, ShimmerElementType } from '@fluentui/react';
import SearchIcon from '@material-ui/icons/Search';
import { TextBox } from '../../../../common';
import { BlueButton } from 'V3Components/common/buttons';
import { PanelContentProps } from 'V3Components/Forms/OnBehalfOf/Panel/PanelContent/index';
import PersonSearchResultItem from 'V3Components/Forms/OnBehalfOf/Panel/PanelContent/PersonSearchResultItem/PersonSearchResultItem';
import { PersonInterface } from 'V3Models/PersonSearchService/PersonSearchItem';
import LocalisationService from 'V3Services/LocalisationService';
import PersonSearchServiceApiClient from 'V3Services/PersonSearch/PersonSearchServiceApiClient';

import local from './local.scss';
import Person from 'V3Models/Domain/Person';

const PanelContent = ({ onSelect, selectedContact }: PanelContentProps) => {
    
    const localisationService: LocalisationService = LocalisationService.getInstance();
    const [noResults, setNoResults] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [contacts, setContacts] = useState<PersonInterface[]>([]);
    const [searchText, setSearchText] = useState<string>('');

    const onChangeSearchText = (value) => {
        setSearchText(value);
    };

    const onSearch = async () => {
        try {
            setNoResults(false);
            setIsLoading(true);
            setContacts([]);

            const response = await PersonSearchServiceApiClient.lookupPerson(searchText);
            const people = response.items.map<PersonInterface>((p) => {  
                return {
                    email: p.email,
                    firstname: p.firstname,
                    personId: p.id,
                    surname: p.surname,
                    title: p.title
                }
            });        

            setContacts(people);
            setIsLoading(false);
            setNoResults(!response.items.length);
        } catch (e) {
            setIsLoading(false);
            setNoResults(true);
        }
    };

    const wrapperClass = mergeStyles({
        padding: 2,
        selectors: {
            '& > *': {
                margin: '10px 0',
            },
        },
    });

    const getErrorMessage = (): JSX.Element => {
        return (
            <div>
                <br />
                <MessageBar id='panel-content__message-bar_no-results-found'>{localisationService.strings.noResultFound}</MessageBar>
            </div>
        );
    };

    return (
        <div className={local.onBehalOfContainer}>
            <h1 id='panel-content__h1_on-behalf-of'>On behalf of</h1>
            <div className={local.searchControl}>
                <TextBox
                    name="SearchOnBehalfOfTextBox"
                    onChange={onChangeSearchText}
                    value={''}
                    label={localisationService.strings.searchByName}
                    className={local.textBox}
                    id='panel-content__textbox_on-behalf-of'
                />
                <BlueButton id='panel-content__blue-button_search' onClick={onSearch} startIcon={<SearchIcon />} className={local.actionButton} disabled={!searchText} />
            </div>
            <div className={local.results}>
                <div className={local.resultCount} id='panel-content__results'>
                    <span id='panel-content__results-count' className={local.count}>{contacts?.length || 0}&nbsp;</span>
                    <span id='panel-content__results-results' className={local.label}>{contacts.length === 1 ? 'result' : 'results'}</span>
                </div>
                {isLoading && (
                    <Fabric className={wrapperClass}>
                        <Shimmer
                            shimmerElements={[
                                { type: ShimmerElementType.circle },
                                { type: ShimmerElementType.gap, width: '2%' },
                                { type: ShimmerElementType.line },
                            ]}
                        />
                        <Shimmer
                            shimmerElements={[
                                { type: ShimmerElementType.circle },
                                { type: ShimmerElementType.gap, width: '2%' },
                                { type: ShimmerElementType.line },
                            ]}
                        />
                        <Shimmer
                            shimmerElements={[
                                { type: ShimmerElementType.circle },
                                { type: ShimmerElementType.gap, width: '2%' },
                                { type: ShimmerElementType.line },
                            ]}
                        />
                    </Fabric>
                )}
                {!!contacts.length && contacts.map((item) => <PersonSearchResultItem key={item.personId} item={item} onSelect={onSelect} />)}

                {!contacts.length && !!searchText.length && noResults && getErrorMessage()}
            </div>
        </div>
    );
};

export default PanelContent;
