import React from 'react';
import ServiceSelection from 'V3/routes/ServiceSelection';
import { MainProps } from 'V3Components/Main/index';
import NotFound from 'V3Components/NotFound';
import { ROUTES } from 'V3Utilities/constants';

import { Progress } from '../common';
import Login from '../Login';

const Main = (props: MainProps): JSX.Element => {
    const { route } = props;
    return (
        <>
            <span id='main__block_route' data-route={route}></span>
            {route === ROUTES.SERVICE_SELECTION && <ServiceSelection />}
            {route === ROUTES.LOGIN && <Login />}
            {route === ROUTES.NOT_FOUND && <NotFound />}
            {route === ROUTES.PROGRESS && <Progress />}
        </>
    );
};

export default Main;
