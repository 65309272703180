import Booking from 'V3Models/BookingService/Booking/Booking';
import BookingHistoryItemWithUser from 'V3Models/BookingService/BookingHistoryItemWithUser';
import { Room } from 'V3Models/Domain/Room';
import { PersonInterface } from 'V3Models/PersonSearchService/PersonSearchItem';
import { RootState } from '..';
import { BookingResponseInterface, BOOKING_STATUS_TYPES } from './types';

export const getBooking = (state: RootState): Booking => {
    return state.bookingForm.booking;
};

export const getBookingStatus = (state: RootState): BOOKING_STATUS_TYPES => {
    return state.bookingForm.booking.bookingStatus;
}

export const getLoadingStatus = (state: RootState): string => {
    return state.bookingForm.loadingStatus;
}

export const getBookingID = (state: RootState): string => {
    return state.bookingForm.booking.id;
};

export const getBookingOnBehalfOf = (state: RootState): PersonInterface => {
    return state.bookingForm.booking.onBehalfOf;
};

export const getSelectedRoom = (state: RootState): Room => {
    return state.bookingForm.selectedRoom;
};

export const getBookingResponse = (state: RootState): BookingResponseInterface => {
    return state.bookingForm.response;
};

export const getBookingInProgress = (state: RootState): boolean => state.bookingForm.inProgress;

export const getIsEdit = (state: RootState): boolean => (state.bookingForm.booking.bookingStatus === BOOKING_STATUS_TYPES.CONFIRMED);

export const getBookingHistory = (state: RootState): BookingHistoryItemWithUser[] => state.bookingForm.bookingHistory;

export const getHasRunPopulateBooking = (state: RootState): boolean => state.bookingForm.hasRunPopulateBooking;