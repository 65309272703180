import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { mapListItems } from 'V3/utilities/listUtils';
import bookingForm from 'V3Stores/booking';
import filters from 'V3Stores/filters';
import organisationalStructure from 'V3Stores/organisationalStructure';
import user from 'V3Stores/user';
import { LOADING_STATUS } from 'V3Utilities/constants';

import ServiceSelection from './ServiceSelection';

const mapStateToProps = createSelector(
    organisationalStructure.store.getAttendees,
    organisationalStructure.store.getRoomsLoadingStatus,

    // user
    user.store.getUser,

    // navigation
    bookingForm.store.getIsEdit,

    // filters
    filters.store.getSelectedAttendees,
    (attendees, roomsLoadingStatus, user, isEdit, selectedAttendees) => ({
        attendeeItems: mapListItems(
            attendees,
            (item) => item,
            (item) => item
        ),
        roomsIsLoading: roomsLoadingStatus !== LOADING_STATUS.LOADED,
        selectedAttendees,
        isEdit,
        tenantName: user.tenantName,
    })
);

const mapDispatchToProps = {
    onChangeAttendee: organisationalStructure.actions.onChangeAttendee,
};

export type ServiceSelectionProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(ServiceSelection);
