import React from 'react';
import { ReactComponent as UserIcon } from 'V3/assets/SVGs/User_icon.svg';
import { PersonInterface } from 'V3Models/PersonSearchService/PersonSearchItem';

import local from './local.scss';

interface PersonButtonProps {
    item: PersonInterface;
    onSelect: (item: PersonInterface) => void;
}

const PersonSearchResultItem = ({ onSelect, item }: PersonButtonProps) => {
    return (
        <div id="person-search-result-item__block_add-contact" onClick={() => onSelect(item)} className={local.searchResultItem}>
            <div className={local.nameEmail}>
                <div id={`person-search-result-item__block_contact-name-${item.personId}`} >{`${item.surname}, ${item.firstname}`}</div>
                <div id={`person-search-result-item__block_contact-email-${item.personId}`} className={local.email}>{item.email}</div>
            </div>
            <div className={local.imgContainer}>
                <UserIcon color="#006496" width="1rem" height="1.3125rem" />
            </div>
        </div>
    );
};

export default PersonSearchResultItem;
