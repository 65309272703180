import React from 'react';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { Panel } from '../common';
import { OkButton } from '../common/buttons';
import local from './local.scss';


export interface InfoPanelProps{
    infoMessage;
    isOpen: boolean;
    onClose?: () => void;
}

const InfoPanel = (props: InfoPanelProps): JSX.Element => {
    const { infoMessage, isOpen, onClose } = props;

    const buttons = <OkButton onClick={onClose} />;

    return (
        <Panel id='info-panel__panel' icon={InfoOutlined} buttons={buttons} isOpen={isOpen} onClose={onClose}>
            <div className={local.infoPanel}>
                <InfoOutlined className={local.icon} />
                <h3 id='info-panel__h3_info-message'>{infoMessage}</h3>
            </div>
        </Panel>
    );
};

export default InfoPanel;
