import { connect } from 'react-redux';
import AttendeeCount from './AttendeeCount';

const mapStateToProps = (state, ownProps) => ({
    value: ownProps.selector(state),
    ...ownProps,
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onChange: (value, validation) => dispatch(ownProps.onChange(value, validation)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendeeCount);
