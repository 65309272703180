export default (collection: any[], fn: (arg: any) => string): any[] => {
    return collection.sort((a, b) => {
        const valA = fn(a);
        const valB = fn(b);
        if (valA < valB) {
            return -1;
        }
        if (valA > valB) {
            return 1;
        }
        return 0;
    });
};
