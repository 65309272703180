import { connect } from 'react-redux';
import { RootState } from 'V3Stores/index';
import route from 'V3Stores/route';
import Main from './Main';

const mapStateToProps = (state: RootState) => {
    return {
        route: route.store.getRoute(state),
    };
};

export type MainProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(Main);
