import { LOADING_STATUS } from 'V3Utilities/constants';
import {
    ACTION_TYPES,
    BookingActionType,
    BookingStateInterface,
    BOOKING_STATUS_TYPES,
    SetBookingAction,
    SetBookingAttendeesAction,
    SetBookingCompleteAction,
    SetBookingHistoryAction,
    SetBookingIDAction,
    SetBookingInProgressAction,
    SetBookingNotesAction,
    SetBookingsOnBehalfOfAction,
    SetBookingStatusAction,
    SetBookingTitleAction,
    SetLoadingStatusAction,
    SetSelectedRoomAction
} from './types';

export const initialState: BookingStateInterface = {
    booking: {
        id: '',
        attendeesCount: 1,
        notes: '',
        onBehalfOf: null,
        title: '',
        bookingStatus: BOOKING_STATUS_TYPES.NOT_STARTED,
        displayName: '',
        endDateTime: '',
        resources: null,
        startDateTime: '',
        friendlyBookingId: ''
    },
    response: {
        type: null,
        item: null,
    },
    loadingStatus: LOADING_STATUS.REQUIRED,
    inProgress: false,
    selectedRoom: null,
    bookingHistory: null,
    hasRunPopulateBooking: false,
};

export default (state: BookingStateInterface = initialState, action: BookingActionType): BookingStateInterface => {    
    switch (action.type) {
        case ACTION_TYPES.BOOKING_FORM_RESET: {
            return { 
                ...initialState,
                selectedRoom: state.selectedRoom //Keep Selected Room.
             };
        }

        case ACTION_TYPES.SET_BOOKING: {
            
            return {
                ...state,
                booking: (action as SetBookingAction).booking,
            };      
            
        }

        case ACTION_TYPES.SET_BOOKING_STATUS: {
            return {
                ...state,
                booking : {
                    ...state.booking,
                    bookingStatus: (action as SetBookingStatusAction).bookingStatus
                },
            };
        }

        case ACTION_TYPES.REFERENCE_DATA_ROOM_BOOKING_INFO_LOAD_RESET:
            return {
                ...state,
                booking: initialState.booking,
                loadingStatus: LOADING_STATUS.LOADING
            };

        case ACTION_TYPES.SET_LOADING_STATUS: {
            return {
                ...state,
                loadingStatus: (action as SetLoadingStatusAction).loadingStatus
            };
        }

        case ACTION_TYPES.SET_BOOKING_IN_PROGRESS: {
            return {
                ...state,
                inProgress: (action as SetBookingInProgressAction).inProgress,
            };
        }

        case ACTION_TYPES.SET_BOOKING_ID: {
            return {
                ...state,
                booking: { ...state.booking, id: (action as SetBookingIDAction).bookingID },
            };
        }
        case ACTION_TYPES.SET_BOOKING_ATTENDEES: {
            return {
                ...state,
                booking: { ...state.booking, attendeesCount: (action as SetBookingAttendeesAction).attendees },
            };
        }

        case ACTION_TYPES.SET_BOOKING_NOTES: {
            return {
                ...state,
                booking: { ...state.booking, notes: (action as SetBookingNotesAction).bookingNotes },
            };
        }

        case ACTION_TYPES.SET_BOOKINGS_ON_BEHALF_OF: {
            return {
                ...state,
                booking: { ...state.booking, onBehalfOf: (action as SetBookingsOnBehalfOfAction).onBehalfOf },
            };
        }

        case ACTION_TYPES.SET_BOOKING_TITLE: {
            return {
                ...state,
                booking: { ...state.booking, title: (action as SetBookingTitleAction).title },
            };
        }

        case ACTION_TYPES.SET_BOOKING_COMPLETE: {
            return {
                ...state,
                response: { ...(action as SetBookingCompleteAction).payload },
            };
        }

        case ACTION_TYPES.RESET_BOOKING: {
            return { ...state, booking: { ...initialState.booking } };
        }

        case ACTION_TYPES.SET_SELECTED_ROOM:
            return {
                ...state,
                selectedRoom: (action as SetSelectedRoomAction).selectedRoom,
            };
            
        case ACTION_TYPES.SELECTED_ROOM_RESET: {
            return {
                ...state,
                selectedRoom: initialState.selectedRoom,
            };
        }

        case ACTION_TYPES.ROOM_BOOKING_INFO_RESET: {
            return {
                ...state,
                selectedRoom: initialState.selectedRoom,
            };
        }

        case ACTION_TYPES.SET_BOOKING_HISTORY: {
            return {
                ...state,
                bookingHistory: (action as SetBookingHistoryAction).history
            }
        }

        default: {
            return state;
        }
    }
};
