import React from 'react';
import { CheckBox } from 'V3Components/Common';
import { ResourceFilterProps } from '.';
import local from './local.scss';

const ResourcesFilter = (props: ResourceFilterProps): JSX.Element => {
    const { equipmentTypes, toggleEquipmentTypeFilterItem } = props;
    
    return (
        <div id='resources-filter__block_container' className={local.filterContainer}>
            {equipmentTypes.map((eqp) => (
                <div key={eqp.id}>
                    <CheckBox
                        name={`resource_${eqp.name}`}
                        checked={eqp.selected}
                        label={eqp.name}
                        onChange={() => toggleEquipmentTypeFilterItem(eqp.id)}
                        idName={`resource_${eqp.name}`}
                    />
                </div>
            ))}
        </div>
    );
};

export default ResourcesFilter;
