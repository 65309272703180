import { connect } from 'react-redux';
import BookingResponsePanel from 'V3Components/BookingResponsePanel/BookingResponsePanel';
import bookingForm from 'V3Stores/booking';
import { RootState } from 'V3Stores/index';
import bookingActions from 'V3Stores/Thunks';
import user from 'V3Stores/user';

const mapStateToProps = (state: RootState) => ({
    bookingResponse: bookingForm.store.getBookingResponse(state),
    userCalendarId: user.store.getUser(state).calendarId,
});

const mapDispatchToProps = {
    onEditBooking: bookingActions.onEditBooking,
};

export type BookingResponsePanelProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(BookingResponsePanel);
