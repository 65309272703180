import React from 'react';
import { ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react';

import local from './local.scss';

const StyledShimmer = (): JSX.Element => {
    return (
        <div style={{ display: 'flex' }} className={local.container}>
            <ShimmerElementsGroup
                shimmerElements={[
                    { type: ShimmerElementType.line, height: 40, width: 40 },
                    { type: ShimmerElementType.gap, width: 16, height: 40 },
                ]}
            />
            <ShimmerElementsGroup
                flexWrap
                width="100%"
                shimmerElements={[
                    {
                        type: ShimmerElementType.line,
                        width: '100%',
                        height: 10,
                        verticalAlign: 'bottom',
                    },
                    { type: ShimmerElementType.line, width: '90%', height: 8 },
                    { type: ShimmerElementType.gap, width: '10%', height: 20 },
                ]}
            />
        </div>
    );
};

export default StyledShimmer;
