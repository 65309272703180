import {
    ACTION_TYPES,
    FiltersActionType,
    FiltersStateInterface,
    SetAttendeesAction,
    SetEquipmentAction,
    SetSelectedAreasAction,
} from './actionTypes';

export const initialState: FiltersStateInterface = {
    attendees: 1,
    equipmentTypes: [],
    selectedAreas: [],
};

export default (state: FiltersStateInterface = initialState, action: FiltersActionType): FiltersStateInterface => {
    switch (action.type) {
        case ACTION_TYPES.FILTERS_INITIALISE:
            return {
                ...initialState,
            };

        case ACTION_TYPES.FILTERS_SET_ATTENDEES:
            return {
                ...state,
                attendees: (action as SetAttendeesAction).attendees,
            };

        case ACTION_TYPES.FILTERS_SET_EQUIPMENT_TYPES:
            return {
                ...state,
                equipmentTypes: (action as SetEquipmentAction).equipmentTypes,
            };

        case ACTION_TYPES.FILTERS_SET_SELECTED_AREAS:
            return {
                ...state,
                selectedAreas: (action as SetSelectedAreasAction).selectedAreas,
            };

        default: {
            return state;
        }
    }
};
