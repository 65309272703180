import React from 'react';
import { Label, Progress, TextBox } from '../../common';
import { RoomInfoProps } from '.';
import local from './roomInfo.scss';
import { LOADING_STATUS } from 'V3Utilities/constants';

const RoomInfo = (props: RoomInfoProps): JSX.Element => {
    const { loadingStatus, roomInfo, roomImageURL } = props;
    const imgURL = (roomImageURL?.length > 0) ? roomImageURL : 'assets/NoRoomImage.png';
    
    if (loadingStatus !== LOADING_STATUS.LOADED || roomInfo === null) {
        return <Progress />;
    }

    const { site, capacity, entityType, description, restrictedUsers, equipment, entityName } = roomInfo;

    return (
        <div className={local.roomInfo}>
            <h1 id="room-info__h1_entity-info">{entityType} Information</h1>
            <div className={local.imageContainer}>
                    <img width="90%" src={imgURL} />
                </div> 
            <div className={local.content}>
                {description && (
                    <>
                        <div id="room-info__block_description" className={local.description}>
                            {description}
                        </div>
                    </>
                )}
                <TextBox id="room-info__txt_entity-name" label={site} isDisabled value={entityName} />
                <TextBox id="room-info__txt_entity-capacity" label="Capacity" isDisabled value={String(capacity)} className={local.capacity} />               
                {restrictedUsers?.length > 0 ? (
                    <>
                        <Label id="room-info__label_restrctions" text="Restrictions" className={local.label} />
                        <div className={local.restrictions}>
                            <ul id="room-info__ul_restricted-users">
                                {restrictedUsers.map((item, idx) => {
                                    return (
                                        <li key={`RestrictedToUser-${idx}`}>
                                            User: {item.firstname} {item.surname}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </>
                ) : null}

                {equipment?.length > 0 && (
                    <>
                        <Label id="room-info__label_fixed-eqp" text="Fixed Equipment" className={local.label} />
                        <div className={local.resources}>
                            <ul id="room-info__unordered-list_fixed-eqp">
                                {equipment.map((item, idx) => {
                                    return (
                                        item && (
                                            <li key={`fixedequipment-${idx}`}>
                                                {item.name} {item.description && ` - ${item.description}`}
                                            </li>
                                        )
                                    );
                                })}
                            </ul>
                        </div>
                    </>
                )}     
            </div>
        </div>
    );
};
export default RoomInfo;
