import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {ReactComponent as CapacityIcon} from 'V3/assets/Capacity_Fill.svg';
import local from './selectableRoom.scss';
import { Room as RoomInterface } from 'V3Models/Domain/Room';
import { ReactComponent as RestrictedIcon } from 'V3/assets/SVGs/RestrictedIconGreen.svg';
import { ReactComponent as DisabledAccessIcon } from 'V3/assets/SVGs/DisabledIcon.svg';
import { Radio } from '@material-ui/core';

const InfoButton = withStyles({
    root: {
        color: '#9dd3e7',
        overflow: 'hidden',
        width: 16,
        height: 16,
        '&:hover': {
            color: '#006496',
            background: 'transparent',
            shadow: 'none',
        },
    },
})(IconButton);

const RadioButton = withStyles({
    root: {
        outerWidth: 12,
        color: '#199ed8',
        height:20,
        width: 20,
        '&:hover': {
            color: '#005a87',
        }
    }
  })(Radio);
  
const Room = (props: SelectableRoomProps): JSX.Element => {

    const { Room: room, OnSelectRoom: onSelectRoom, OnShowRoomInfo: onShowRoomInfo, Selected } = props;

    const handleClick = (): void => {
        onSelectRoom(room);
    };

    const handleInfoClick = (e): void => {
        onShowRoomInfo(room);
        e.stopPropagation();
    };

    return (
        <div className={local.room} onClick={handleClick} onKeyDown={handleClick} role="button" tabIndex={0}>
            <div className={local.radioButtonContainer}>
                <RadioButton 
                checked={Selected}                
                color='default'
                id='selectable-room__rbn'
                />               
            </div>
            <div className={local.roomDetails}>
                <div className={local.roomName} id='selectable-room__block_room-name'>{room.roomName}</div>
                <div className={local.capacity} id='selectable-room__block_capacity'>
                    <CapacityIcon />
                    {room.capacity}
                    <span className={local.restrictionIcon}>{room.displayRestrictionIcon && <RestrictedIcon />}</span>
                    <span className={local.disabledAccessIcon}>{room.disabledAccess && <DisabledAccessIcon />}</span>
                </div>
            </div>
            <div className={local.infoButton} id='selectable-room__block_info-button'>
                <InfoButton aria-label="information" size="medium" disableRipple onClick={handleInfoClick}>
                    <InfoOutlinedIcon fontSize="inherit" />
                </InfoButton>
            </div>
        </div>
    );
};

export default Room;

interface SelectableRoomProps {
    Room: RoomInterface, 
    OnSelectRoom: Function,
    Selected: boolean,
    OnShowRoomInfo: (room: RoomInterface) => void
}
