import AppTenant from 'V3Models/TenantsService/AppTenant';
import AuthScheme from 'V3Models/TenantsService/AuthScheme';
import { REDUCER_NAMES } from 'V3Utilities/constants';

const reducerName = REDUCER_NAMES.AUTH;

export const ACTION_TYPES = {
    LOGIN_LOADING: `${reducerName}/LOGIN_LOADING`,
    LOGIN_TENANTS_SELECTION_ACTIVE: `${reducerName}/LOGIN_TENANTS_SELECTION_ACTIVE`,
    LOGIN_TENANTS_SELECTION_DONE: `${reducerName}/LOGIN_TENANTS_SELECTION_DONE`,
    LOGIN_TENANTS_SELECTION_IS_ACTIVE: `${reducerName}/LOGIN_TENANTS_SELECTION_IS_ACTIVE`,
    LOGIN_AUTH_SCHEMES_SELECTION_ACTIVE: `${reducerName}/LOGIN_AUTH_SCHEMES_SELECTION_ACTIVE`,
    LOGIN_AUTH_SCHEMES_SELECTION_DONE: `${reducerName}/LOGIN_AUTH_SCHEMES_SELECTION_DONE`,
    LOGIN_CB_FAIL: `${reducerName}/LOGIN_CB_FAIL`,
    LOGIN_CB_SUCCESS: `${reducerName}/LOGIN_CB_SUCCESS`,
    LOGOUT: `${reducerName}/LOGOUT`,
};

export interface LoginLoadingAction {
    type: typeof ACTION_TYPES.LOGIN_LOADING;
    payload: {
        loading: boolean;
    };
}

export interface LoginTenantsSelectionActiveAction {
    type: typeof ACTION_TYPES.LOGIN_TENANTS_SELECTION_ACTIVE;
    payload: {
        tenants: AppTenant[];
        tenantsOptions: OptionType[];
    };
}

export interface LoginTenantsSelectionDoneAction {
    type: typeof ACTION_TYPES.LOGIN_TENANTS_SELECTION_DONE;
    payload: {
        tenant: AppTenant;
    };
}

export interface SetLoginTenantsSelectionIsActiveAction {
    type: typeof ACTION_TYPES.LOGIN_TENANTS_SELECTION_IS_ACTIVE;
    payload: {
        isActive: boolean;
    };
}

export interface LoginAuthSchemesSelectionActiveAction {
    type: typeof ACTION_TYPES.LOGIN_AUTH_SCHEMES_SELECTION_ACTIVE;
    payload: {
        authSchemes: AuthScheme[];
        authSchemesOptions: OptionType[];
    };
}

export interface LoginAuthSchemesSelectionDoneAction {
    type: typeof ACTION_TYPES.LOGIN_AUTH_SCHEMES_SELECTION_DONE;
}

export interface LoginCBSuccessAction {
    type: typeof ACTION_TYPES.LOGIN_CB_SUCCESS;
    payload: {
        username: string;
    };
}

export interface LogoutAction {
    type: typeof ACTION_TYPES.LOGOUT;
}

export interface LoginCBFailAction {
    type: typeof ACTION_TYPES.LOGIN_CB_FAIL;
    payload: CBLoginErrorInterface;
}

export type AuthActionType =
    | LoginLoadingAction
    | LoginCBSuccessAction
    | LoginCBFailAction
    | LogoutAction
    | LoginAuthSchemesSelectionDoneAction
    | LoginAuthSchemesSelectionActiveAction
    | LoginTenantsSelectionActiveAction
    | LoginTenantsSelectionDoneAction
    | SetLoginTenantsSelectionIsActiveAction;

export interface CBLoginErrorInterface {
    isCBLoginError: boolean;
    type: string;
}

export interface OptionType {
    key: string;
    text: string;
}

export interface TenantsSelectionInterface {
    active: boolean;
    tenants: AppTenant[];
    tenantsOptions: OptionType[];
}

export interface AuthSchemesSelectionInterface {
    active: boolean;
    authSchemes: AuthScheme[];
    authSchemesOptions: OptionType[];
}

export interface AuthStateInterface {
    inProgress: boolean;
    username: string;
    CBLoginError: CBLoginErrorInterface;
    isCBLoginSuccess: boolean;
    isOffice365LoginError: boolean;
    isOffice365LoginSuccess: boolean;
    tenant: AppTenant;
    tenantsSelection: TenantsSelectionInterface;
    authSchemesSelection: AuthSchemesSelectionInterface;
}
