import React from 'react';
import { LOADING_STATUS, TABS } from 'V3Utilities/constants';

import { ButtonBar } from '../common';
import { BookButton, DeleteButton, NextButton, SaveButton } from '../common/buttons';

import { ButtonsProps } from '.';

const Buttons = ({
    currentTab,
    isEdit,
    onDelete,
    onNext,
    isHomeNextDisabled,
    saveBooking,
    bookingLoadingStatus,
    sitesLoadingStatus,
}: ButtonsProps): JSX.Element => {

    const renderButtons = (): JSX.Element => {
        const deleteText = isEdit ? 'Delete' : 'Cancel';
        const nextID = isEdit ? 'buttons__button_next-home' : 'buttons__button_next-not-home';
        
        if (currentTab === TABS.HOME) {
            if (isEdit) {
                return (
                    <>
                        <DeleteButton
                            onClick={onDelete}
                            disabled={sitesLoadingStatus !== LOADING_STATUS.LOADED || bookingLoadingStatus !== LOADING_STATUS.LOADED}
                            id="buttons__button_delete-edit"
                            text={deleteText}
                        />
                        <NextButton onClick={onNext} disabled={isHomeNextDisabled} id={nextID} />
                    </>
                );
            }
            return (
                <>
                    <NextButton onClick={onNext} disabled={isHomeNextDisabled} id={nextID} />
                </>
            );
        }
        if (currentTab === TABS.BOOKING) {
            if (isEdit) {
                return (
                    <>
                        <DeleteButton
                            onClick={onDelete}
                            disabled={sitesLoadingStatus !== LOADING_STATUS.LOADED || bookingLoadingStatus !== LOADING_STATUS.LOADED}
                            id="buttons__button_delete-booking"
                        />
                        <SaveButton
                            onClick={saveBooking}
                            disabled={sitesLoadingStatus !== LOADING_STATUS.LOADED || bookingLoadingStatus !== LOADING_STATUS.LOADED}
                            id="buttons__button_delete-save"
                        />
                    </>
                );
            }
            return (
                <>
                    <DeleteButton
                        onClick={onDelete}
                        disabled={sitesLoadingStatus !== LOADING_STATUS.LOADED || bookingLoadingStatus !== LOADING_STATUS.LOADED}
                        id="buttons__button_delete-booking"
                        text={deleteText}
                    />
                    <BookButton onClick={saveBooking} disabled={bookingLoadingStatus !== LOADING_STATUS.LOADED} id="buttons__button_book" />
                </>
            );
        }

        return null;
    };

    return <ButtonBar id="buttons__button_button-bar">{renderButtons()}</ButtonBar>;
};

export default Buttons;
