import { EntityInfo } from 'V3Models/Domain/EntityInfo';
import { Room } from 'V3Models/Domain/Room';
import { Site } from 'V3Models/Domain/Site';
import { RootState } from 'V3Stores/index';

export const getReferenceDataLoadingStatus = (state: RootState): string => state.organisationalStructure.loadingStatus;

export const getSites = (state: RootState): Site[] => {
    return state.organisationalStructure.sites.items;
};

export const getSite =
    (siteID: string) =>
    (state: RootState): Site => {
        return state.organisationalStructure.sites.items.find((s) => s.siteID == siteID);
    };

export const getSelectedSiteIDs = (state: RootState): string[] => {
    return state.organisationalStructure?.selectedSiteIDs;
};

export const getSelectedSites = (state: RootState): Site[] => {
    const selectedSiteIDs = getSelectedSiteIDs(state)
    return (state.organisationalStructure.sites.items.filter((s) => {
        return selectedSiteIDs.includes(s.siteID)
    }));
}

export const getSitesLoadingStatus = (state: RootState): string => state.organisationalStructure.sites.loadingStatus;

export const getRoomsLoadingStatus = (state: RootState): string => state.organisationalStructure.rooms.loadingStatus;

export const getRooms = (state: RootState): Room[] => {
       return   ( state.bookingForm.selectedRoom?.roomID ? 
                    [...state.organisationalStructure.rooms.items, state.bookingForm.selectedRoom] 
                        :  
                    state.organisationalStructure.rooms.items 
                );
};

export const getRoom =
    (roomId: string) =>
    (state: RootState): Room => {                
        return getRooms(state).find((r) => r.roomID === roomId);
    };

export const getAttendees = (state: RootState): any => state.organisationalStructure.attendees;

export const getRoomInfoLoadingStatus = (state: RootState): string => state.organisationalStructure.roomInfo.loadingStatus;

export const getRoomInfo =
    (roomId: string) =>
    (state: RootState): EntityInfo => {
        return state.organisationalStructure.roomInfo.items.find((r) => r.entityID === roomId);
    };

export const getRoomImageURL = (state: RootState): string => {
    return state.organisationalStructure.roomInfo.imageURL;
}

export const getRoomsSite =
    (roomId: string) =>
    (state: RootState): Site => {
        const siteID = getRoom(roomId)(state)?.siteID;
        if (siteID) return getSites(state).find((s) => s.siteID === siteID);
        return null;
};