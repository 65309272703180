import React from 'react';
import classNames from 'classnames';
import { ErrorText, RequiredLabel } from './Components';
import local from './local.scss';

export interface InputWrapperProps {
    label: string;
    name?: string;
    className?: string;
    hideLabel?: boolean;
    required?: string | boolean;
    error?: string;
    id?:string;
}

const InputWrapper = ({
    label,
    name,
    className,
    hideLabel,
    required = false,
    error,
    children,
    id
}: React.PropsWithChildren<InputWrapperProps>): JSX.Element => {
    return (
        <div id={id} className={classNames(local.inputWrapper, className)}>
            <label className={classNames(error && local.error, hideLabel && local.hidden)} htmlFor={name}>
                {label}
                <RequiredLabel required={!!required} />
            </label>
            {children}
            {error ? <ErrorText text={error} /> : null}
        </div>
    );
};

export default InputWrapper;
