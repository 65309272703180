import { connect } from 'react-redux';
import route from 'V3/stores/route';
import appointment from 'V3Stores/appointment';
import bookingAppointmentActions from 'V3Stores/Thunks';
import bookingForm from 'V3Stores/booking';
import errorState from 'V3Stores/errorState';
import { RootState } from 'V3Stores/index';
import navigation from 'V3Stores/navigation';
import referenceData from 'V3Stores/organisationalStructure';
import ServiceSelectionRoutes from './ServiceSelectionRoutes';

const mapStateToProps = (state: RootState) => {
    return {
        referenceDataLoadingStatus: referenceData.store.getReferenceDataLoadingStatus(state),
        currentTab: navigation.store.getCurrentTab(state),
        appointmentLoadingStatus: appointment.store.getAppointmentLoadingStatus(state),
        isWebClient: route.store.getIsWebClient(state),
        bookingInProgress: bookingForm.store.getBookingInProgress(state),
        error: errorState.store.getError(state),
    };
};

const mapDispatchToProps = {
    loadOrganisationStructureData: referenceData.actions.loadOrganisationStructureData,
    loadAppointment: bookingAppointmentActions.loadAppointment,
    refreshFromOutlook: bookingAppointmentActions.refreshFromOutlook,
};

export type ServiceSelectionRoutesProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(ServiceSelectionRoutes);