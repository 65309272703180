import React from 'react';
import { Select } from '../../common';
import LocalisationService from 'V3Services/LocalisationService';
import { mapListItems } from 'V3Utilities/listUtils';

const AttendeeCount = ({ id, value = null, onChange, className, capacity }): JSX.Element => {
    const localisationService = LocalisationService.getInstance();
    const items = Array.from(Array(capacity).keys(), (x) => x + 1);

    return (
        <Select
            label={localisationService.strings.attendees}
            name={id}
            items={mapListItems(
                items,
                (item) => item,
                (item) => item
            )}
            value={value}
            className={className}
            required={false}
            onChange={(item) => onChange(item.value)}
            id='attendee-count__select'
        />
    );
};

export default AttendeeCount;
