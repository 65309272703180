import React, { memo, useState } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import { ReactComponent as FloorIcon } from 'V3/assets/SVGs/Floor-Location-Icon.svg';
import { ReactComponent as SiteIcon } from 'V3/assets/SVGs/Site.svg';

import local from './local.scss';

interface AccordionProps {
    children: any | any[];
    title: string;
    parent: boolean;
    type?: string;
    expanded?: boolean;
    isFilter?: boolean;
    isSubFilter?: boolean;
    id?: string;
}

const Accordion = ({ children, title, parent, type, expanded, isFilter, isSubFilter, id}: AccordionProps): JSX.Element => {
    const [isOpen, setOpen] = useState<boolean>(expanded || false);
    const [noOfChildren, setNoOfChildren] = useState<number>(children.props?.children?.length);

    React.useEffect(() =>
        setNoOfChildren(children.props?.children?.length)
    ,[children.props?.children])

    const handleOpen = (): void => {
        setOpen(!isOpen);
    };
    const headerString = (): string => {
        switch (type) {
            case 'site':
            case 'area':
            case 'floor':
                return parent || isFilter ? `${title}` : `${title} - (${children.length})`;
            default:
                return `${title}`;
        }
    };

    const headerIcon = (): JSX.Element => {
        switch (type) {
            case 'site':
                return <SiteIcon className={classNames(local.icon)} id={`${id}-header__icon_site`} name='header__icon_site' />;
            case 'floor':
                return <FloorIcon className={classNames(local.icon)} id={`${id}-header__icon_floor`} name='header__icon_floor' />
            default:
                return <></>;
        }
    };

    const typeStyle = () => {
        if (parent) return local.isParent;
        if (isFilter) return local.isFilter;
        if (isSubFilter) return local.isSubFilter;

        return local.isChild;
    };

    return (
       (parent && noOfChildren > 0 )?
            <>
            <div className={classNames(local.accordion, typeStyle())} id={`${id}-header-button`} onClick={handleOpen} role="button" onKeyPress={handleOpen} tabIndex={-1}>
                <div className={local.accordionHeader} id={`${id}-header-icon-has-parent`}>
                    {headerIcon()}
                    <span className={local.title} data-name={`${type}-header-title`} id={`${id}-accordian-header-title`}>{headerString()}</span>
                </div>
                <ChevronRightIcon data-name='chevron-right' className={classNames(local.arrowIcon, isOpen ? local.isClosed : local.isOpen)} />
            </div>
            {isOpen && <div className={classNames(local.content, typeStyle())}>{children}</div>}
            </>
        :
        !parent ?
         <>
            <div className={classNames(local.accordion, typeStyle())} onClick={handleOpen} role="button" onKeyPress={handleOpen} tabIndex={-1}>
                <div className={local.accordionHeader} id={`${id}-header-icon-no-parent`}>
                    {headerIcon()}
                    <span className={local.title} data-name={`${type}-header-title`} >{headerString()}</span>
                </div>
                <ChevronRightIcon className={classNames(local.arrowIcon, isOpen ? local.isClosed : local.isOpen)} />
            </div>
            {isOpen && <div id={`${id}-children-open`} data-name={`${id}-children-open`} className={classNames(local.content, typeStyle())}>{children}</div>}
            </>
        :
        null

    );
};

export default memo(Accordion);
