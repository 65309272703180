import LoggingService from './LoggingService';

export default class ErrorHandlingService {
    public static HandleSystemError = (codeLocation: string, object: any = null): void => {
        LoggingService.Error(codeLocation, object);

        const message = {
            type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
            message: `Action unavailable. If the problem persists, contact your administrator quoting ref ${codeLocation}`,
            icon: 'Icon.systemError',
            persistent: true,
        };

        Office.context.mailbox.item.notificationMessages.replaceAsync('syserr', message);
    };
}
