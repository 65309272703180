import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import referenceData from 'V3Stores/organisationalStructure';
import { LOADING_STATUS } from 'V3Utilities/constants';

import SiteSelector, { SiteSelectorInterface } from './SiteSelector';

const mapStateToProps = createSelector(
    referenceData.store.getSitesLoadingStatus,
    referenceData.store.getSites,
    referenceData.store.getSelectedSiteIDs,
    (loadStatus, sites, selectedSites) => {
        return <SiteSelectorInterface>{
            isLoading: loadStatus !== LOADING_STATUS.LOADED,
            selectedSites: selectedSites?.map((selectedSiteId) => sites.find((siteItem) => siteItem.siteID === selectedSiteId)),
            isDisabled: false,
        };
    }
);

export default connect(mapStateToProps)(SiteSelector);
