import { LOADING_STATUS } from 'V3Utilities/constants';

import { ACTION_TYPES, AppointmentActionType, AppointmentStateInterface, SetAppointmentAction, SetAppointmentAndChangedAction } from './types';

export const initialState: AppointmentStateInterface = {
    item: {
        attendeeCount: 0,
        endDTMUTC: null,
        outlookEndTime: null,
        outlookStartTime: null,
        startDTMUTC: null,
        title: '',
        siteEndDTM: null,
        siteStartDTM: null,
        siteTimeZone_Name: '',
    },
    loadingStatus: LOADING_STATUS.REQUIRED,
    changed: false,
};

export default (state: AppointmentStateInterface = initialState, action: AppointmentActionType) => {
    switch (action.type) {
        case ACTION_TYPES.SET_APPOINTMENT:
            return {
                ...state,
                item: (action as SetAppointmentAction).appointment,
                loadingStatus: LOADING_STATUS.LOADED,
            };

        case ACTION_TYPES.SET_APPOINTMENT_AND_CHANGED:
            return {
                ...state,
                item: (action as SetAppointmentAndChangedAction).appointment,
                loadingStatus: LOADING_STATUS.LOADED,
                changed: (action as SetAppointmentAndChangedAction).changed,
            };

        case ACTION_TYPES.APPOINTMENT_RESET:
            return {
                ...state,
                item: { ...initialState.item },
                loadingStatus: LOADING_STATUS.REQUIRED,
                changed: false,
            };

        default: {
            return state;
        }
    }
};
