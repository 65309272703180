import { EntityInfo } from 'V3Models/Domain/EntityInfo';
import { Room } from 'V3Models/Domain/Room';
import { Site } from 'V3Models/Domain/Site';

import * as ACTION_TYPES from './types';
import {
    ReferenceDataInitialiseAction,
    ReferenceDataLoadEndAction,
    ReferenceDataLoadResetAction,
    ReferenceDataLoadStartAction,
    ResetSelectedSitesAction,
    RoomInfoLoadCompleteAction,
    RoomInfoLoadResetAction,
    RoomsLoadCompleteAction,
    RoomsLoadResetAction,
    SetSelectedSitesAction,
    SitesLoadCompleteAction,
    SitesLoadResetAction,
    SetSitesLoadingStatusAction,
    SetRoomsLoadingStatusAction,
    SetRoomImageURLAction
} from './types';

export const referenceDataLoadStart = (): ReferenceDataLoadStartAction => ({
    type: ACTION_TYPES.REFERENCE_DATA_LOAD_START,
});

export const referenceDataLoadEnd = (): ReferenceDataLoadEndAction => ({
    type: ACTION_TYPES.REFERENCE_DATA_LOAD_END,
});

export const referenceDataLoadReset = (): ReferenceDataLoadResetAction => ({
    type: ACTION_TYPES.REFERENCE_DATA_LOAD_RESET,
});

export const referenceDataInitialise = (): ReferenceDataInitialiseAction => ({
    type: ACTION_TYPES.REFERENCE_DATA_INITIALISE,
});

export const sitesLoadComplete = (sites: Site[]): SitesLoadCompleteAction => {
    return {
        type: ACTION_TYPES.REFERENCE_DATA_SITES_LOAD_COMPLETE,
        sites,
    };
};

export const sitesLoadReset = (): SitesLoadResetAction => ({
    type: ACTION_TYPES.REFERENCE_DATA_SITES_LOAD_RESET,
});

export const setSitesLoadingStatus = (loadingStatus: string): SetSitesLoadingStatusAction => ({
    type: ACTION_TYPES.REFERENCE_DATA_SET_SITES_LOADING_STATUS,
    loadingStatus
});

export const setSelectedSites = (selectedSitesIDs: string[]): SetSelectedSitesAction => ({
        type: ACTION_TYPES.REFERENCE_DATA_SET_SELECTED_SITES,
        selectedSiteIDs: selectedSitesIDs,
});

export const resetSelectedSites = (): ResetSelectedSitesAction => ({
        type: ACTION_TYPES.REFERENCE_DATA_SELECT_SITES_RESET,
});

export const roomsLoadComplete = (rooms: Room[]): RoomsLoadCompleteAction => ({
    type: ACTION_TYPES.REFERENCE_DATA_ROOMS_LOAD_COMPLETE,
    rooms,
});

export const roomsLoadReset = (): RoomsLoadResetAction => ({
    type: ACTION_TYPES.REFERENCE_DATA_ROOMS_LOAD_RESET,
});

export const setRoomsLoadingStatus = (loadingStatus: string): SetRoomsLoadingStatusAction => ({
    type: ACTION_TYPES.REFERENCE_DATA_SET_ROOMS_LOADING_STATUS,
    loadingStatus
});

export const roomInfoLoadComplete = (room: EntityInfo): RoomInfoLoadCompleteAction => {    
    return {
        type: ACTION_TYPES.REFERENCE_DATA_ROOM_INFO_LOAD_COMPLETE,
        room,
    };
};

export const roomInfoLoadReset = (): RoomInfoLoadResetAction => ({
    type: ACTION_TYPES.REFERENCE_DATA_ROOM_INFO_LOAD_RESET,
});


export const setRoomImageURL = (url: string): SetRoomImageURLAction => ({
    type: ACTION_TYPES.SET_ROOM_IMAGE_URL,
    imageURL: url
})