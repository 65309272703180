import { combineReducers } from 'redux';
import filters from 'V3Stores/filters';
import organisationalStructure from 'V3Stores/organisationalStructure'
import auth from './auth';
import route from './route';
import user from './user';
import appointment from './appointment';
import navigation from './navigation';
import booking from './booking';
import errorState from './errorState';
import { ACTION_TYPES } from 'V3Stores/auth/types';

const appReducer = combineReducers({
    auth: auth.reducer,
    route: route.reducer,
    user: user.reducer,
    bookingForm: booking.reducer,
    organisationalStructure: organisationalStructure.reducer,
    navigation: navigation.reducer,
    appointment: appointment.reducer,
    filters: filters.reducer,
    errorState: errorState.reducer
});

const rootReducer = (state: any, action: any) => {
    if (action.type === ACTION_TYPES.LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
