import React, { useState } from 'react';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import classnames from 'classnames';
import PubSub from 'pubsub-js';
import { ReactComponent as Chevron } from 'V3/assets/SVGs/RightChevron.svg';
import { Site } from 'V3Models/Domain/Site';
import LocalisationService from 'V3Services/LocalisationService';
import { PUB_SUB_TOPICS } from 'V3Utilities/constants';

import { InputFieldLoadingDots, InputWrapper, Panel, TextBox } from '../../common';
import { ApplyButton, ResetButton } from '../../common/buttons';
import SiteSelectorSiteList from '../SiteSelectorSiteList';

import local from './local.scss';

const SiteSelector = ({ isLoading, selectedSites, isDisabled }: SiteSelectorInterface): JSX.Element => {
    const [showPanel, setShowPanel] = useState<boolean>(false);
    const localisationService = LocalisationService.getInstance();

    const onOpenPanel = () => {
        if (isLoading) {
            return;
        }
        setShowPanel(true);
    };

    const getSelectedSitesLabel = (showDefault = null): string => {
        return selectedSites?.length
            ? selectedSites.map((item) => ` ${item?.siteName || ''}`).toString()
            : showDefault && localisationService.strings.selectSites;
    };

    const panelButtons = (
        <>
            <ApplyButton id="service-selection__button_apply" onClick={() => PubSub.publish(PUB_SUB_TOPICS.SITE_SELECTOR_SAVE)} />
            <ResetButton id="service-selection__button_reset" onClick={() => PubSub.publish(PUB_SUB_TOPICS.SITE_SELECTOR_RESET)} />
        </>
    );

    return isDisabled ? (
        <TextBox
            label={localisationService.strings.sites}
            name="SitesDummy"
            className={local.siteContainer}
            value={getSelectedSitesLabel() || ''}
            onChange={() => {}}
            isDisabled
            id="site-selector__sites-dummmy"
        />
    ) : (
        <InputWrapper
            label={localisationService.strings.sites}
            className={local.siteContainer}
            name="SitesSelector"
            hideLabel={false}
            required={false}
            error=""
            id="site-selector__sites-selector"
        >
            <div className={classnames(local.siteBox, selectedSites?.length && local.hasValue)} onClick={onOpenPanel}>
                <div className={local.siteItemText} id="site-selector__chevron">
                    {getSelectedSitesLabel(true)}
                </div>
                {isLoading && <InputFieldLoadingDots className={local.loadingDots} id="site-selector" />}
                <Chevron className={local.siteItemChevron} id="site-selector__chevron" />
            </div>
            <Panel id="site-selector__panel" icon={AddLocationIcon} isOpen={showPanel} buttons={panelButtons} onClose={() => setShowPanel(false)}>
                <SiteSelectorSiteList />
            </Panel>
        </InputWrapper>
    );
};

export default SiteSelector;

export interface SiteSelectorInterface {
    isLoading: boolean;
    selectedSites: Site[];
    isDisabled: boolean;
}
