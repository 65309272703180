import React from 'react';
import { Label, Select, TextBox } from 'V3Components/Common';
import SelectableRooms from 'V3Components/Rooms/SelectableRooms';
import { ServiceSelectionProps } from 'V3Components/ServiceSelection/index';
import LocalisationService from 'V3Services/LocalisationService';
import SiteSelector from './SiteSelector';
import local from './local.scss';

const ServiceSelection = ({
    attendeeItems,
    roomsIsLoading,
    selectedAttendees,
    onChangeAttendee,
    isEdit,
    tenantName,
}: ServiceSelectionProps): JSX.Element => {
    const localisationService = LocalisationService.getInstance();

    return (
        <>
            <h1 id='service-selection__h1'>{isEdit ? 'Change booking' : 'Make a booking'}</h1>

            <Label id='service-selection__tenant_name' text={tenantName} className={local.tenantName} />
            <hr className={local.headerLine} />
            <br/>
            {/* Services */}
            <TextBox
                id='service-selection__service-label'
                label='Service'
                name="notes"
                className={local.formItem}
                value='Single Room Booking'
                isDisabled={true}
                onChange={() => null}
            />
            <br/>
            {/* Attendees */}
            <Select
                label={localisationService.strings.attendees}
                name="AttendeeSelect"
                placeholder={localisationService.strings.attendees}
                items={attendeeItems}
                value={selectedAttendees}
                onChange={onChangeAttendee}
                className={local.serviceSelectContainer}
                isDisabled={roomsIsLoading}
                id='service-selection__select_attendees'
            />

            {/* Sites */}
            <SiteSelector />

            {/* Rooms */}
            <SelectableRooms />
        </>
    );
};

export default ServiceSelection;
