import AppTenant from 'V3Models/TenantsService/AppTenant';
import AuthScheme from 'V3Models/TenantsService/AuthScheme';
import { AuthSchemeType } from 'V3Models/TenantsService/AuthSchemeType';
import { HandlerType } from 'V3Models/TenantsService/HandlerType';
import IdentityTenant from 'V3Models/TenantsService/IdentityTenant';


const isAuthoritySchemeAllowed = (authScheme: AuthScheme): boolean => {
    return ((authScheme.authority?.indexOf('microsoftonline') !== -1 && authScheme.authSchemeType == AuthSchemeType.Common)
            ||
           (authScheme.authSchemeType == AuthSchemeType.M365 && authScheme.handlerType == HandlerType.Oidc))
}

export const sortTenantsWithMSGraph = (tenantsList: IdentityTenant[]): AppTenant[] => {
    const tenants: AppTenant[] = [];
    if (tenantsList?.length >= 1) {
        tenantsList?.forEach((tenant) => {
            const newTenant: AppTenant = { ...tenant, isSSO: false };
            newTenant?.authSchemes?.forEach((authScheme, index) => {
                if (isAuthoritySchemeAllowed(authScheme)) {
                    newTenant.isSSO = true;
                }
            });
            if (newTenant.isSSO) {
                const newTenantWithFilteredSchemes = filterAuthSchemes(newTenant);
                tenants.push(newTenantWithFilteredSchemes);
            }
        });
    }
    return tenants;
};

export const filterAuthSchemes = (tenant: AppTenant): AppTenant => {
    const filteredAuthSchemes = tenant.authSchemes.filter((authScheme) => authScheme.authority?.indexOf('microsoftonline') !== -1);
    tenant.authSchemes = filteredAuthSchemes;
    return tenant;
};
