import { ACTION_TYPES, ErrorActionType, ErrorLevel, ErrorStateInterface, SetErrorAction } from './actionTypes';

export const initialState: ErrorStateInterface = {
    errorLevel: ErrorLevel.OK,
    errorMessage: 'Sorry, something went wrong.', // This should never be displayed as the Error tab should only be displayed
}; // when there is an actual error to display

export default (state = initialState, action: ErrorActionType): ErrorStateInterface => {
    switch (action.type) {
        case ACTION_TYPES.SET_ERROR:
            return (action as SetErrorAction).error;
        case ACTION_TYPES.CLEAR_ERRORS:
            return {
                errorLevel: ErrorLevel.OK,
                errorMessage: ''
            };
    }
    return state;
};
