import AppointmentInfoInterface from 'V3Models/Domain/AppointmentInfo';
import { ACTION_TYPES, ResetAppointmentAction, SetAppointmentAction,  SetAppointmentAndChangedAction } from './types';

export const setAppointmentAndChanged = (appointment: AppointmentInfoInterface, changed: boolean): SetAppointmentAndChangedAction => ({
    type: ACTION_TYPES.SET_APPOINTMENT_AND_CHANGED,
    appointment,
    changed  
});

export const setAppointment = (appointment: AppointmentInfoInterface): SetAppointmentAction => ({
    type: ACTION_TYPES.SET_APPOINTMENT,
    appointment  
});

export const resetAppointment = (): ResetAppointmentAction => ({
    type: ACTION_TYPES.APPOINTMENT_RESET,
});