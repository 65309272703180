import axios from 'axios';
import RefreshLoginToCBResponse from 'V3Models/Login/RefreshLoginToCBResponse';
import { setRoute } from 'V3Stores/route/storeActions';
import { setUser } from 'V3Stores/user/storeActions';
import Config from 'V3Utilities/Config';
import * as constants from 'V3Utilities/constants';
import { extractUserInfoFromLoginResponse } from 'V3Utilities/userInfo/extractUserInfoFromLoginResponse';

import store from '../stores';

const API_URL = Config.V3_M365_SERVICE_URL;
const QA_API_URL = Config.API_URL;
const ADDIN_API_URL = Config.ADDIN_URL;

export const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL,
    headers: { 'Content-Type': 'application/json' },
});

export const $qaAPI = axios.create({
    baseURL: QA_API_URL,
});

export const $addinAPI = axios.create({
    baseURL: ADDIN_API_URL,
});

$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;    
    return config;
});

$api.interceptors.response.use(
    (config) => {
        
        return config;
    },
    async (error) => {
        const originalRequest = error.config;
        const { username } = store.getState().auth;
        if (error.response.status === 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true;
            try {
                const response = await axios.get<RefreshLoginToCBResponse>(`${API_URL}/api/v1/Authentication/refreshtoken?userName=${username}`, {
                    withCredentials: true,
                });
                localStorage.setItem('accessToken', response.data.accessToken);
                store.dispatch(setUser(extractUserInfoFromLoginResponse(response.data)));
                return $api.request(originalRequest);
            } catch (e) {
                // in case if refresh token in cookies isn't valid we'll be redirected to login page
                Office.context.roamingSettings.remove('username');
                Office.context.roamingSettings.remove('msgraphToken');
                store.dispatch(setRoute(constants.ROUTES.LOGIN));
            }
        }
        return Promise.reject(error);
    }
);
