import { Guid } from 'guid-typescript';
import { EntityInfo } from 'V3Models/Domain/EntityInfo';
import { BookingCancelRequest } from 'V3Models/DTOs/Requests/BookingCancelRequest';
import { BookingReservationRequest } from 'V3Models/DTOs/Requests/BookingReservationRequest';
import { ReserveBookingResponse } from 'V3Models/DTOs/Responses/ReserveBookingResponse';
import BookingServiceApiClient from 'V3Services/BookingService/BookingServiceApiClient';
import EntitiesServiceApiClient from 'V3Services/EntitiesService/EntitiesServiceApiClient';
import ErrorHandlingService from 'V3Services/ErrorHandlingService';
import LocalisationService from 'V3Services/LocalisationService';
import OutlookService from 'V3Services/OutlookService';
import appointment from 'V3Stores/appointment';
import bookingForm from 'V3Stores/booking';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE } from 'V3Stores/booking/types';
import errorState from 'V3Stores/errorState';
import { ErrorLevel } from 'V3Stores/errorState/actionTypes';
import filters from 'V3Stores/filters';
import { AppThunk } from 'V3Stores/index';
import navigation from 'V3Stores/navigation';
import organisationalStructure from 'V3Stores/organisationalStructure';
import { LOADING_STATUS, TABS } from 'V3Utilities/constants';
import BookingHistoryItemWithUser from 'V3Models/BookingService/BookingHistoryItemWithUser';
import onlyUnique from 'V3Utilities/onlyUnique';
import Booking from 'V3Models/BookingService/Booking/Booking';
import { Room } from 'V3Models/Domain/Room';
import { deleteAppointment, isBookingInPast, loadAppointment } from './outlookActions';
import PersonSearchServiceApiClient from 'V3Services/PersonSearch/PersonSearchServiceApiClient';

export const onEditBooking = (): AppThunk => async (dispatch, getState) => { 
    const { item } = bookingForm.store.getBookingResponse(getState());

    dispatch(loadAppointment());
    dispatch(bookingForm.store.initialise());
    dispatch(bookingForm.store.setBookingInProgress(true));
    dispatch(filters.store.filtersInitialise());

    const selectedRoom = bookingForm.store.getSelectedRoom(getState());
    dispatch(loadBooking(item.id, true, selectedRoom));
};

export const selectRoom =
    (room: Room): AppThunk =>
    (dispatch) => {
        dispatch(bookingForm.store.setSelectedRoom(room));
    };

export const setRoomInfo = (room: Room): AppThunk => (dispatch, getState) => {
    loadRoomInfoPage(room)(dispatch, getState, null);
};

export const onSitesSelect =
    (selectedSites: string[]): AppThunk =>
    (dispatch) => {
        dispatch(navigation.actions.setCreateBookingTabs());
        dispatch(bookingForm.store.resetSelectedRoom());
        dispatch(organisationalStructure.store.roomsLoadReset());
        dispatch(organisationalStructure.store.setSelectedSites(selectedSites));
        dispatch(organisationalStructure.store.referenceDataLoadReset());
    };

const handleReservationErrors = (reservationResponse: ReserveBookingResponse) => (dispatch) => {
    const localisationService: LocalisationService = LocalisationService.getInstance();
    let errorMessage = reservationResponse.errors[0]?.errorMessage;

    if (!errorMessage && reservationResponse.errors.length > 1) {
        errorMessage = reservationResponse.errors[1]?.errorMessage;
    }
    if (!errorMessage) {
        errorMessage = localisationService.strings.defaultErrorCreatingBooking;
    }
    if (errorMessage.length > 0) {
        dispatch(navigation.store.setAvailableTabs([TABS.HOME, TABS.ERROR]));
        dispatch(navigation.store.setCurrentTab(TABS.ERROR));
        dispatch(
            errorState.store.setError({
                errorLevel: ErrorLevel.WARNING,
                errorMessage,
            })
        );
    }
};

export const moveNext = (): AppThunk => async (dispatch, getState) => {
    dispatch(bookingForm.store.setBookingInProgress(true));
    await dispatch(cancelExistingBookingReservations());

    const appointmentInfo = await OutlookService.getAppointmentInfoFromOutlook({});
    dispatch(appointment.store.setAppointment(appointmentInfo));    

    const bookingInPast = dispatch(isBookingInPast(appointmentInfo?.startDTMUTC, navigation.store.getCurrentTab(getState())));

    if (!bookingInPast) {
        const selectedRoom = bookingForm.store.getSelectedRoom(getState());
        const bookingReservationRequest: BookingReservationRequest = {
            StartDateTime: appointmentInfo?.startDTMUTC.toISO() ?? '',
            EndDateTime: appointmentInfo?.endDTMUTC.toISO() ?? '',
            IsPending: true,
            ResourceId: selectedRoom.roomID,
            suppressPublishSyncEvent: true,
        };

        const reservationResponse = await BookingServiceApiClient.makeBookingReservation(bookingReservationRequest);
        
        if (reservationResponse?.errors && reservationResponse.errors.length > 0) {
            dispatch(handleReservationErrors(reservationResponse));
        } else {
            dispatch(bookingForm.store.setBookingID(reservationResponse.bookingId));
            dispatch(navigation.actions.setEditBookingTabs());
            Office.context.mailbox.item.start.setAsync(new Date(reservationResponse.startDateTime), null);
            Office.context.mailbox.item.end.setAsync(new Date(reservationResponse.endDateTime), null);
        }
    }

    dispatch(bookingForm.store.setBookingStatus(BOOKING_STATUS_TYPES.RESERVED));
    dispatch(bookingForm.store.setLoadingStatus(LOADING_STATUS.LOADED))
    dispatch(bookingForm.store.setBookingInProgress(false));
        
};

export const onShowBookingDelete = (): AppThunk => (dispatch) => {
    dispatch(navigation.store.setShowDeleteBooking(true));
};

export const cancelBookingReservation = async (bookingReservationID: string) => {
    if (bookingReservationID?.toString() !== Guid.EMPTY) {
        const cancelRequest: BookingCancelRequest = {
            bookingId: bookingReservationID,
            suppressPublishSyncEvent: true,
        };

        await BookingServiceApiClient.cancelBookingReservation(cancelRequest);
    }
};

export const deleteBooking =
    (userResponse = true): AppThunk =>
    async (dispatch, getState) => {
        dispatch(navigation.store.setShowDeleteBooking(false));
        if (!userResponse) return;

        dispatch(bookingForm.store.setBookingInProgress(true));

        const bookingId = bookingForm.store.getBooking(getState()).id;
        const appointmentData = appointment.store.getAppointment(getState());
        const cancelRequest: BookingCancelRequest = {
            bookingId,
            suppressPublishSyncEvent: true,
        };
        const bookingDeleteResponse = await BookingServiceApiClient.cancelBookingReservation(cancelRequest);
        
        if (bookingDeleteResponse && bookingDeleteResponse.errors.length === 0) {
            // delete from Outlook
            await deleteAppointment(appointmentData);
            // reset UI to create new booking
            dispatch(bookingForm.store.initialise()); // set booking Form 
            dispatch(navigation.actions.setCreateBookingTabs());
            dispatch(filters.store.filtersInitialise());
            dispatch(organisationalStructure.store.referenceDataInitialise());
            dispatch(bookingForm.store.setBookingComplete({ type: BOOKING_TYPE.DELETE }));
            dispatch(bookingForm.store.setBookingStatus(BOOKING_STATUS_TYPES.NOT_STARTED));
        } else {
            ErrorHandlingService.HandleSystemError('BA/DB');
            dispatch(bookingForm.store.setBookingInProgress(false));
        }
    };

export const loadRoomInfo = 
            (room: Room): AppThunk => async (dispatch, getState) => {  
                    try {                                                               
                        
                        dispatch(organisationalStructure.store.roomInfoLoadReset());
                        
                        if(room?.resourceImageId){
                            const roomImage = await EntitiesServiceApiClient.getImage(room.resourceImageId);                            
                            dispatch(organisationalStructure.store.setRoomImageURL(roomImage?.results?.base64Image))
                        }
                        else{
                            dispatch(organisationalStructure.store.setRoomImageURL(null));
                        }
                       
                        const restrictedUsers = ( room?.restrictedTo == null ? null : 
                                                                             await PersonSearchServiceApiClient.getByIds({personIds: room.restrictedTo?.users})
                                                                                                               .then((response) => response.persons)
                                                );

                        const entityInfo = <EntityInfo>{
                            area: room.areaName,
                            areaID: room.areaID,
                            capacity: room.capacity,
                            description: room.description,
                            effectiveMaxBookingDate: room.effectiveMaxBookingDateUTC,
                            entityID: room.roomID,
                            entityName: room.roomName,
                            entityType: 'Room',
                            entityTypeID: 2,
                            externalProperties: room.externalProperties,
                            resourceImageId: room.resourceImageId,
                            site: room.siteName,
                            siteID: room.siteID,
                            equipment: room.equipment,
                            restrictedUsers
                        }

                        dispatch(organisationalStructure.store.roomInfoLoadComplete(entityInfo));
                        
                    } catch (e) {
                        ErrorHandlingService.HandleSystemError('BA/LRI', e);
                    }
};

export const loadRoomEntityBookingInfo =
    (room: Room = null, bookingId = ''): AppThunk =>
    async (dispatch, getState) => {
        try {         
            
            const state = getState();            
            
            const roomId = (room === null) ? bookingForm.store.getSelectedRoom(state)?.roomID : room.roomID; // Adding a null check here breaks the load...
           
           if(roomId)
           {
            const roomInfo = organisationalStructure.store.getRoomInfo(roomId)(state);         

            if (!bookingId) bookingId = bookingForm.store.getBookingID(state);

            if ((roomInfo === undefined || roomInfo === null) && bookingId) {
                
                await BookingServiceApiClient.getBooking(bookingId)
                .then((bookingInfoResponse) => {                                       
                    dispatch(bookingForm.store.setBooking(
                        {
                            ...bookingInfoResponse.booking,
                            attendeesCount:  bookingInfoResponse.booking.attendeesCount == 0 ? 1 : bookingInfoResponse.booking.attendeesCount 
                        })
                    )   
                });                     
            }
            
            dispatch(bookingForm.store.setLoadingStatus(LOADING_STATUS.LOADED))
           }

        } catch (e) {      
            ErrorHandlingService.HandleSystemError('BA/LREBI', e);
        }
};

export const loadRoomInfoPage =
    (room: Room): AppThunk =>
    async (dispatch, getState) => {
        dispatch(bookingForm.store.setSelectedRoom(room));
        const roomInfo = organisationalStructure.store.getRoomInfo(room.roomID)(getState());
        if (roomInfo) {
            return;
        }        
        await loadRoomInfo(room)(dispatch, getState, {});
};

export const loadRoomBookingInformation = (): AppThunk => async (dispatch, getState) => {     
        await loadRoomEntityBookingInfo()(dispatch, getState, {}); 
};

export const cancelExistingBookingReservations = () => async (dispatch, getState) => {
    const bookingReservationID = bookingForm.store.getBookingID(getState());

    if (bookingReservationID && bookingReservationID?.toString() !== Guid.EMPTY) {
        await cancelBookingReservation(bookingReservationID);
    }
};


export const loadBookingHistory =
    (bookingId: string): AppThunk =>
    async (dispatch) => {
        const bookingLogs = await BookingServiceApiClient.getBookingHistory(bookingId).then((response) => response?.auditLogs || null);

        if (bookingLogs && bookingLogs.length) {
            const history: BookingHistoryItemWithUser[] = [];
            const personIds = bookingLogs.map((log) => log.userId).filter(onlyUnique);
            const users = await PersonSearchServiceApiClient.getByIds({ personIds }).then((response) => response.persons);
            bookingLogs.forEach((log) => {
                history.push({ ...log, userInfo: users.find((user) => user.personId === log.userId) });
            });
            dispatch(bookingForm.store.setBookingHistory(history));
        } else {
            dispatch(bookingForm.store.setBookingHistory(null));
        }
    };

export const loadBooking =
    (bookingId: string, resetReferenceData = false,selectedRoom: Room = null) =>
    async (dispatch, getState) => {
        
        if (!bookingId) return;

        try {
            // reload existing booking for editselectedRoom
            const response = await BookingServiceApiClient.getBooking(bookingId);
            // console.log('response >>>' , {...response})
            dispatch(bookingForm.store.setBooking(response.booking));
            // set UI to edit mode
            dispatch(navigation.actions.setEditBookingTabs());         
                           
            if (resetReferenceData) {
                const selectedSiteIDs = organisationalStructure.store.getSelectedSiteIDs(getState());
                dispatch(organisationalStructure.store.referenceDataInitialise());
                dispatch(organisationalStructure.store.setSelectedSites(selectedSiteIDs))   
                if(!selectedRoom){
                    throw Error('Selected Room Null when reset data called - loadBooking');
                }  
                else{
                    bookingForm.actions.setSelectedRoom(selectedRoom)
                }
            }
            
            dispatch(bookingForm.store.setBookingStatus(BOOKING_STATUS_TYPES.CONFIRMED))

            dispatch(bookingForm.store.setBookingInProgress(false));
        } catch (e) {
            ErrorHandlingService.HandleSystemError('LBA/LB', e);
        }
    };

// called by reference data actions after data loaded
export const populateBookingData = () => async (dispatch, getState) => {
    
    const state = getState();

    // Check if editing booking
    //const isEdit = bookingForm.store.getIsEdit(state);
    const hasRunPopulateBooking = bookingForm.store.getHasRunPopulateBooking(state);
    //if (!isEdit || hasRunPopulateBooking) {
    if (hasRunPopulateBooking) {
        return;
    }

    const booking = bookingForm.store.getBooking(state);

    // Populate store by sections
    await populateHomePage(booking)(dispatch); /// DO I NEED THIS FUNCTION? If I am always in edit screen here???
    await populateBookingPage(booking)(dispatch, getState); // Difference between these functions?????

    dispatch(bookingForm.store.setHasRunPopulateBooking());

};

const populateHomePage = (booking: Booking) => async (dispatch) => {
    // Attendees
    const { attendeesCount } = booking;
    dispatch(filters.store.filtersSetAttendees(attendeesCount));
};

const populateBookingPage = (booking: Booking) => async (dispatch, getState) => {    
    if (booking.onBehalfOf) {
        const {personId,  email, title, firstname: firstName, surname } = booking.onBehalfOf;

        // on behalf of
        dispatch(
            bookingForm.store.setBookingsOnBehalfOf({
                personId,
                email,
                title,
                firstname: firstName,
                surname,
            })
        );
    }

    // notes
    dispatch(bookingForm.store.setBookingNotes(booking.notes));

    // history
    dispatch(loadBookingHistory(booking.id));

    // set selected site
    dispatch(organisationalStructure.store.setSelectedSites([booking.resources[0].siteId]));

    // set attendees
    dispatch(bookingForm.store.setBookingAttendees(booking.attendeesCount));

    // set selected room
    await localLoadRoomInfo(booking.resources[0].resourceId, booking.id)(dispatch, getState);
};


const localLoadRoomInfo = (roomId: string, bookingId: string) => async (dispatch, getState) => {    
    // Load booked room and save into store (as not in available rooms list since already booked)
    const { entityInfo } = await EntitiesServiceApiClient.getEntityInfo(roomId);

    const room = <Room>{
        areaDisplayOrder: 0,
        areaID: entityInfo.areaID,
        areaName: entityInfo.area,
        capacity: entityInfo.capacity,
        roomID: entityInfo.entityID,
        roomName: entityInfo.entityName,
        siteName: entityInfo.site,
        siteID: entityInfo.siteID,
        effectiveMaxBookingDateUTC: entityInfo.effectiveMaxBookingDate,
        description: entityInfo.description,
        externalProperties: entityInfo.externalProperties,
        resourceImageId: entityInfo.resourceImageId,
        equipment: entityInfo.equipment
    };

    dispatch(bookingForm.store.setSelectedRoom(room));
    dispatch(organisationalStructure.store.roomInfoLoadComplete(entityInfo));
    
    await loadRoomEntityBookingInfo(room, bookingId)(dispatch, getState, {});
};
