import ResourceEquipment from 'V3Models/Domain/Equipment';
import { Room } from 'V3Models/Domain/Room';
import sortBy from './sortBy';

export default (rooms: Room[]): ResourceEquipment[] => {

    const equipmentTypes: ResourceEquipment[] = sortBy(
        rooms.reduce((allEquipmentTypesAccumilated: ResourceEquipment[], currentRoom) => {

            let currentRoomEquipment = currentRoom.equipment.reduce((currentRoomEquipmentTypes: ResourceEquipment[], currEquipment) => {
                if (!currentRoomEquipmentTypes.find((eqpType) => eqpType.name === currEquipment.name)) {
                    currentRoomEquipmentTypes.push(
                        <ResourceEquipment>{
                            category: currEquipment.category,
                            description: currEquipment.description,
                            id : currEquipment.id,
                            name: currEquipment.name,
                            selected: false
                        });
                }
                return currentRoomEquipmentTypes
            }, [])

            currentRoomEquipment.forEach(currentRoomEqpType => {
                if (!allEquipmentTypesAccumilated.find((eqpType) => eqpType.name === currentRoomEqpType.name)){
                    allEquipmentTypesAccumilated.push(currentRoomEqpType);
                }
            });

            return allEquipmentTypesAccumilated;
        }, []),
        (equipmentType: ResourceEquipment) => equipmentType.name.toUpperCase()
    );

    return equipmentTypes;
};