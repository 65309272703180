import React from 'react';
import { groupBy, orderBy } from 'lodash-es';
import { Accordion, CheckBox } from 'V3Components/common';
import { AreaFilterProps } from 'V3Components/Rooms/SelectableRooms/AreaFilter/index';
import local from './local.scss';

const AreaFilter = (props: AreaFilterProps): JSX.Element => {
    const { items, toggleAreaFilterItem } = props;

    const groupedAreas = orderBy(
        Object.values(groupBy(items, 'siteID')).map((groupedList) => ({
            siteID: groupedList[0].siteID,
            siteName: groupedList[0].siteName,
            items: [...groupedList],
        })),
        ['siteName'],
        'asc'
    );

    return (
        <>
            {groupedAreas.map((groupedAreaList) => (
                <div key={groupedAreaList.siteID} className={local.filterContainer}>
                    <Accordion type="site" title={`${groupedAreaList.siteName}`} parent={false} expanded isFilter>
                        {groupedAreaList.items.map((item) => (
                            <CheckBox
                                key={`item_${item.areaID}`}
                                name={`item_${item.areaID}`}
                                checked={item.selected}
                                label={item.areaName}
                                onChange={() => toggleAreaFilterItem(item.areaID)}
                                idName={`item_${item.areaID}`}
                            />
                        ))}
                    </Accordion>
                </div>
            ))}
        </>
    );
};
export default AreaFilter;
