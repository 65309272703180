import { connect } from 'react-redux';
import route from 'V3/stores/route';
import user from 'V3/stores/user';
import bookingActions from 'V3Stores/Thunks';
import { saveOfficeLoginDetails } from 'V3Stores/Thunks/outlookActions';

import App from './App';

const mapStateToProps = null;

const mapDispatchToProps = {
    setRoute: route.actions.setRoute,
    setIsWebClient: route.actions.setIsWebClient,
    setUser: user.store.setUser,
    setOnBehalfOfUser: user.store.setOnBehalfOfUser,
    saveOfficeLoginDetails,
    loadBooking: bookingActions.loadBooking,
};

export type AppProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(App);
