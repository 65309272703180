export const TimeZones = [
    {
        alias: 'Dateline Standard Time',
        displayName: '(UTC-12:00) International Date Line West',
    },
    {
        alias: 'Samoa Standard Time',
        displayName: '(UTC+13:00) Samoa',
    },
    {
        alias: 'UTC-11',
        displayName: '(UTC-11:00) Coordinated Universal Time-11',
    },
    {
        alias: 'Aleutian Standard Time',
        displayName: '(UTC-10:00) Aleutian Islands',
    },
    {
        alias: 'Hawaiian Standard Time',
        displayName: '(UTC-10:00) Hawaii',
    },
    {
        alias: 'Marquesas Standard Time',
        displayName: '(UTC-09:30) Marquesas Islands',
    },
    {
        alias: 'Alaskan Standard Time',
        displayName: '(UTC-09:00) Alaska',
    },
    {
        alias: 'UTC-09',
        displayName: '(UTC-09:00) Coordinated Universal Time-09',
    },
    {
        alias: 'Pacific Standard Time (Mexico)',
        displayName: '(UTC-08:00) Baja California',
    },
    {
        alias: 'UTC-08',
        displayName: '(UTC-08:00) Coordinated Universal Time-08',
    },
    {
        alias: 'Pacific Standard Time',
        displayName: '(UTC-08:00) Pacific Time (US & Canada)',
    },
    {
        alias: 'US Mountain Standard Time',
        displayName: '(UTC-07:00) Arizona',
    },
    {
        alias: 'Mountain Standard Time (Mexico)',
        displayName: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
    },
    {
        alias: 'Mountain Standard Time',
        displayName: '(UTC-07:00) Mountain Time (US & Canada)',
    },
    {
        alias: 'Eastern Standard Time (Mexico)',
        displayName: '(UTC-05:00) Chetumal',
    },
    {
        alias: 'Central America Standard Time',
        displayName: '(UTC-06:00) Central America',
    },
    {
        alias: 'Central Standard Time',
        displayName: '(UTC-06:00) Central Time (US & Canada)',
    },
    {
        alias: 'Easter Island Standard Time',
        displayName: '(UTC-06:00) Easter Island',
    },
    {
        alias: 'Central Standard Time (Mexico)',
        displayName: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    },
    {
        alias: 'Canada Central Standard Time',
        displayName: '(UTC-06:00) Saskatchewan',
    },
    {
        alias: 'SA Pacific Standard Time',
        displayName: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
    },
    {
        alias: 'Eastern Standard Time',
        displayName: '(UTC-05:00) Eastern Time (US & Canada)',
    },
    {
        alias: 'Haiti Standard Time',
        displayName: '(UTC-05:00) Haiti',
    },
    {
        alias: 'Cuba Standard Time',
        displayName: '(UTC-05:00) Havana',
    },
    {
        alias: 'US Eastern Standard Time',
        displayName: '(UTC-05:00) Indiana (East)',
    },
    {
        alias: 'Turks And Caicos Standard Time',
        displayName: '(UTC-05:00) Turks and Caicos',
    },
    {
        alias: 'Venezuela Standard Time',
        displayName: '(UTC-04:00) Caracas',
    },
    {
        alias: 'Magallanes Standard Time',
        displayName: '(UTC-03:00) Punta Arenas',
    },
    {
        alias: 'Paraguay Standard Time',
        displayName: '(UTC-04:00) Asuncion',
    },
    {
        alias: 'Atlantic Standard Time',
        displayName: '(UTC-04:00) Atlantic Time (Canada)',
    },
    {
        alias: 'Central Brazilian Standard Time',
        displayName: '(UTC-04:00) Cuiaba',
    },
    {
        alias: 'SA Western Standard Time',
        displayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
    },
    {
        alias: 'Pacific SA Standard Time',
        displayName: '(UTC-04:00) Santiago',
    },
    {
        alias: 'Newfoundland Standard Time',
        displayName: '(UTC-03:30) Newfoundland',
    },
    {
        alias: 'Tocantins Standard Time',
        displayName: '(UTC-03:00) Araguaina',
    },
    {
        alias: 'E. South America Standard Time',
        displayName: '(UTC-03:00) Brasilia',
    },
    {
        alias: 'SA Eastern Standard Time',
        displayName: '(UTC-03:00) Cayenne, Fortaleza',
    },
    {
        alias: 'Argentina Standard Time',
        displayName: '(UTC-03:00) City of Buenos Aires',
    },
    {
        alias: 'Greenland Standard Time',
        displayName: '(UTC-03:00) Greenland',
    },
    {
        alias: 'Montevideo Standard Time',
        displayName: '(UTC-03:00) Montevideo',
    },
    {
        alias: 'Saint Pierre Standard Time',
        displayName: '(UTC-03:00) Saint Pierre and Miquelon',
    },
    {
        alias: 'Bahia Standard Time',
        displayName: '(UTC-03:00) Salvador',
    },
    {
        alias: 'UTC-02',
        displayName: '(UTC-02:00) Coordinated Universal Time-02',
    },
    {
        alias: 'Mid-Atlantic Standard Time',
        displayName: '(UTC-02:00) Mid-Atlantic - Old',
    },
    {
        alias: 'Azores Standard Time',
        displayName: '(UTC-01:00) Azores',
    },
    {
        alias: 'Cape Verde Standard Time',
        displayName: '(UTC-01:00) Cabo Verde Is.',
    },
    {
        alias: 'UTC',
        displayName: '(UTC) Coordinated Universal Time',
    },
    {
        alias: 'GMT Standard Time',
        displayName: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
    },
    {
        alias: 'Greenwich Standard Time',
        displayName: '(UTC+00:00) Monrovia, Reykjavik',
    },
    {
        alias: 'Morocco Standard Time',
        displayName: '(UTC+01:00) Casablanca',
    },
    {
        alias: 'W. Europe Standard Time',
        displayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    },
    {
        alias: 'Central Europe Standard Time',
        displayName: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    },
    {
        alias: 'Romance Standard Time',
        displayName: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    },
    {
        alias: 'Central European Standard Time',
        displayName: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    },
    {
        alias: 'W. Central Africa Standard Time',
        displayName: '(UTC+01:00) West Central Africa',
    },
    {
        alias: 'Libya Standard Time',
        displayName: '(UTC+02:00) Tripoli',
    },
    {
        alias: 'Namibia Standard Time',
        displayName: '(UTC+02:00) Windhoek',
    },
    {
        alias: 'Jordan Standard Time',
        displayName: '(UTC+02:00) Amman',
    },
    {
        alias: 'GTB Standard Time',
        displayName: '(UTC+02:00) Athens, Bucharest',
    },
    {
        alias: 'Middle East Standard Time',
        displayName: '(UTC+02:00) Beirut',
    },
    {
        alias: 'Egypt Standard Time',
        displayName: '(UTC+02:00) Cairo',
    },
    {
        alias: 'E. Europe Standard Time',
        displayName: '(UTC+02:00) Chisinau',
    },
    {
        alias: 'Syria Standard Time',
        displayName: '(UTC+02:00) Damascus',
    },
    {
        alias: 'West Bank Standard Time',
        displayName: '(UTC+02:00) Gaza, Hebron',
    },
    {
        alias: 'South Africa Standard Time',
        displayName: '(UTC+02:00) Harare, Pretoria',
    },
    {
        alias: 'FLE Standard Time',
        displayName: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    },
    {
        alias: 'Israel Standard Time',
        displayName: '(UTC+02:00) Jerusalem',
    },
    {
        alias: 'Kaliningrad Standard Time',
        displayName: '(UTC+02:00) Kaliningrad',
    },
    {
        alias: 'Sudan Standard Time',
        displayName: '(UTC+02:00) Khartoum',
    },
    {
        alias: 'Turkey Standard Time',
        displayName: '(UTC+03:00) Istanbul',
    },
    {
        alias: 'Belarus Standard Time',
        displayName: '(UTC+03:00) Minsk',
    },
    {
        alias: 'Arabic Standard Time',
        displayName: '(UTC+03:00) Baghdad',
    },
    {
        alias: 'Arab Standard Time',
        displayName: '(UTC+03:00) Kuwait, Riyadh',
    },
    {
        alias: 'Russian Standard Time',
        displayName: '(UTC+03:00) Moscow, St. Petersburg',
    },
    {
        alias: 'E. Africa Standard Time',
        displayName: '(UTC+03:00) Nairobi',
    },
    {
        alias: 'Astrakhan Standard Time',
        displayName: '(UTC+04:00) Astrakhan, Ulyanovsk',
    },
    {
        alias: 'Russia Time Zone 3',
        displayName: '(UTC+04:00) Izhevsk, Samara',
    },
    {
        alias: 'Saratov Standard Time',
        displayName: '(UTC+04:00) Saratov',
    },
    {
        alias: 'Volgograd Standard Time',
        displayName: '(UTC+04:00) Volgograd',
    },
    {
        alias: 'Iran Standard Time',
        displayName: '(UTC+03:30) Tehran',
    },
    {
        alias: 'Arabian Standard Time',
        displayName: '(UTC+04:00) Abu Dhabi, Muscat',
    },
    {
        alias: 'Azerbaijan Standard Time',
        displayName: '(UTC+04:00) Baku',
    },
    {
        alias: 'Mauritius Standard Time',
        displayName: '(UTC+04:00) Port Louis',
    },
    {
        alias: 'Georgian Standard Time',
        displayName: '(UTC+04:00) Tbilisi',
    },
    {
        alias: 'Caucasus Standard Time',
        displayName: '(UTC+04:00) Yerevan',
    },
    {
        alias: 'Afghanistan Standard Time',
        displayName: '(UTC+04:30) Kabul',
    },
    {
        alias: 'West Asia Standard Time',
        displayName: '(UTC+05:00) Ashgabat, Tashkent',
    },
    {
        alias: 'Ekaterinburg Standard Time',
        displayName: '(UTC+05:00) Ekaterinburg',
    },
    {
        alias: 'Pakistan Standard Time',
        displayName: '(UTC+05:00) Islamabad, Karachi',
    },
    {
        alias: 'Qyzylorda Standard Time',
        displayName: '(UTC+05:00) Qyzylorda',
    },
    {
        alias: 'India Standard Time',
        displayName: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    },
    {
        alias: 'Sri Lanka Standard Time',
        displayName: '(UTC+05:30) Sri Jayawardenepura',
    },
    {
        alias: 'Nepal Standard Time',
        displayName: '(UTC+05:45) Kathmandu',
    },
    {
        alias: 'Central Asia Standard Time',
        displayName: '(UTC+06:00) Astana',
    },
    {
        alias: 'Bangladesh Standard Time',
        displayName: '(UTC+06:00) Dhaka',
    },
    {
        alias: 'Omsk Standard Time',
        displayName: '(UTC+06:00) Omsk',
    },
    {
        alias: 'Altai Standard Time',
        displayName: '(UTC+07:00) Barnaul, Gorno-Altaysk',
    },
    {
        alias: 'N. Central Asia Standard Time',
        displayName: '(UTC+07:00) Novosibirsk',
    },
    {
        alias: 'Tomsk Standard Time',
        displayName: '(UTC+07:00) Tomsk',
    },
    {
        alias: 'Myanmar Standard Time',
        displayName: '(UTC+06:30) Yangon (Rangoon)',
    },
    {
        alias: 'SE Asia Standard Time',
        displayName: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    },
    {
        alias: 'W. Mongolia Standard Time',
        displayName: '(UTC+07:00) Hovd',
    },
    {
        alias: 'North Asia Standard Time',
        displayName: '(UTC+07:00) Krasnoyarsk',
    },
    {
        alias: 'China Standard Time',
        displayName: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    },
    {
        alias: 'North Asia East Standard Time',
        displayName: '(UTC+08:00) Irkutsk',
    },
    {
        alias: 'Singapore Standard Time',
        displayName: '(UTC+08:00) Kuala Lumpur, Singapore',
    },
    {
        alias: 'W. Australia Standard Time',
        displayName: '(UTC+08:00) Perth',
    },
    {
        alias: 'Taipei Standard Time',
        displayName: '(UTC+08:00) Taipei',
    },
    {
        alias: 'Ulaanbaatar Standard Time',
        displayName: '(UTC+08:00) Ulaanbaatar',
    },
    {
        alias: 'Transbaikal Standard Time',
        displayName: '(UTC+09:00) Chita',
    },
    {
        alias: 'North Korea Standard Time',
        displayName: '(UTC+09:00) Pyongyang',
    },
    {
        alias: 'Aus Central W. Standard Time',
        displayName: '(UTC+08:45) Eucla',
    },
    {
        alias: 'Tokyo Standard Time',
        displayName: '(UTC+09:00) Osaka, Sapporo, Tokyo',
    },
    {
        alias: 'Korea Standard Time',
        displayName: '(UTC+09:00) Seoul',
    },
    {
        alias: 'Yakutsk Standard Time',
        displayName: '(UTC+09:00) Yakutsk',
    },
    {
        alias: 'Cen. Australia Standard Time',
        displayName: '(UTC+09:30) Adelaide',
    },
    {
        alias: 'AUS Central Standard Time',
        displayName: '(UTC+09:30) Darwin',
    },
    {
        alias: 'E. Australia Standard Time',
        displayName: '(UTC+10:00) Brisbane',
    },
    {
        alias: 'AUS Eastern Standard Time',
        displayName: '(UTC+10:00) Canberra, Melbourne, Sydney',
    },
    {
        alias: 'West Pacific Standard Time',
        displayName: '(UTC+10:00) Guam, Port Moresby',
    },
    {
        alias: 'Tasmania Standard Time',
        displayName: '(UTC+10:00) Hobart',
    },
    {
        alias: 'Vladivostok Standard Time',
        displayName: '(UTC+10:00) Vladivostok',
    },
    {
        alias: 'Bougainville Standard Time',
        displayName: '(UTC+11:00) Bougainville Island',
    },
    {
        alias: 'Magadan Standard Time',
        displayName: '(UTC+11:00) Magadan',
    },
    {
        alias: 'Sakhalin Standard Time',
        displayName: '(UTC+11:00) Sakhalin',
    },
    {
        alias: 'Lord Howe Standard Time',
        displayName: '(UTC+10:30) Lord Howe Island',
    },
    {
        alias: 'Russia Time Zone 10',
        displayName: '(UTC+11:00) Chokurdakh',
    },
    {
        alias: 'Norfolk Standard Time',
        displayName: '(UTC+11:00) Norfolk Island',
    },
    {
        alias: 'Central Pacific Standard Time',
        displayName: '(UTC+11:00) Solomon Is., New Caledonia',
    },
    {
        alias: 'Russia Time Zone 11',
        displayName: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
    },
    {
        alias: 'New Zealand Standard Time',
        displayName: '(UTC+12:00) Auckland, Wellington',
    },
    {
        alias: 'UTC+12',
        displayName: '(UTC+12:00) Coordinated Universal Time+12',
    },
    {
        alias: 'Fiji Standard Time',
        displayName: '(UTC+12:00) Fiji',
    },
    {
        alias: 'Kamchatka Standard Time',
        displayName: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
    },
    {
        alias: 'Chatham Islands Standard Time',
        displayName: '(UTC+12:45) Chatham Islands',
    },
    {
        alias: 'UTC+13',
        displayName: '(UTC+13:00) Coordinated Universal Time+13',
    },
    {
        alias: 'Tonga Standard Time',
        displayName: "(UTC+13:00) Nuku'alofa",
    },
    {
        alias: 'Line Islands Standard Time',
        displayName: '(UTC+14:00) Kiritimati Island',
    },
];

export const CloudbookingTimeZones = [
    {
        ID: 1,
        Name: 'Dateline Standard Time',
    },
    {
        ID: 2,
        Name: 'UTC-11',
    },
    {
        ID: 3,
        Name: 'Aleutian Standard Time',
    },
    {
        ID: 4,
        Name: 'Hawaiian Standard Time',
    },
    {
        ID: 5,
        Name: 'Marquesas Standard Time',
    },
    {
        ID: 6,
        Name: 'Alaskan Standard Time',
    },
    {
        ID: 7,
        Name: 'UTC-09',
    },
    {
        ID: 8,
        Name: 'Pacific Standard Time (Mexico)',
    },
    {
        ID: 9,
        Name: 'UTC-08',
    },
    {
        ID: 10,
        Name: 'Pacific Standard Time',
    },
    {
        ID: 11,
        Name: 'US Mountain Standard Time',
    },
    {
        ID: 12,
        Name: 'Mountain Standard Time (Mexico)',
    },
    {
        ID: 13,
        Name: 'Mountain Standard Time',
    },
    {
        ID: 14,
        Name: 'Central America Standard Time',
    },
    {
        ID: 15,
        Name: 'Central Standard Time',
    },
    {
        ID: 16,
        Name: 'Easter Island Standard Time',
    },
    {
        ID: 17,
        Name: 'Central Standard Time (Mexico)',
    },
    {
        ID: 18,
        Name: 'Canada Central Standard Time',
    },
    {
        ID: 19,
        Name: 'SA Pacific Standard Time',
    },
    {
        ID: 20,
        Name: 'Eastern Standard Time (Mexico)',
    },
    {
        ID: 21,
        Name: 'Eastern Standard Time',
    },
    {
        ID: 22,
        Name: 'Haiti Standard Time',
    },
    {
        ID: 23,
        Name: 'Cuba Standard Time',
    },
    {
        ID: 24,
        Name: 'US Eastern Standard Time',
    },
    {
        ID: 25,
        Name: 'Paraguay Standard Time',
    },
    {
        ID: 26,
        Name: 'Atlantic Standard Time',
    },
    {
        ID: 27,
        Name: 'Venezuela Standard Time',
    },
    {
        ID: 28,
        Name: 'Central Brazilian Standard Time',
    },
    {
        ID: 29,
        Name: 'SA Western Standard Time',
    },
    {
        ID: 30,
        Name: 'Pacific SA Standard Time',
    },
    {
        ID: 31,
        Name: 'Turks And Caicos Standard Time',
    },
    {
        ID: 32,
        Name: 'Newfoundland Standard Time',
    },
    {
        ID: 33,
        Name: 'Tocantins Standard Time',
    },
    {
        ID: 34,
        Name: 'E. South America Standard Time',
    },
    {
        ID: 35,
        Name: 'SA Eastern Standard Time',
    },
    {
        ID: 36,
        Name: 'Argentina Standard Time',
    },
    {
        ID: 37,
        Name: 'Greenland Standard Time',
    },
    {
        ID: 38,
        Name: 'Montevideo Standard Time',
    },
    {
        ID: 39,
        Name: 'Magallanes Standard Time',
    },
    {
        ID: 40,
        Name: 'Saint Pierre Standard Time',
    },
    {
        ID: 41,
        Name: 'Bahia Standard Time',
    },
    {
        ID: 42,
        Name: 'UTC-02',
    },
    {
        ID: 44,
        Name: 'Azores Standard Time',
    },
    {
        ID: 45,
        Name: 'Cape Verde Standard Time',
    },
    {
        ID: 46,
        Name: 'UTC',
    },
    {
        ID: 47,
        Name: 'Morocco Standard Time',
    },
    {
        ID: 48,
        Name: 'GMT Standard Time',
    },
    {
        ID: 49,
        Name: 'Greenwich Standard Time',
    },
    {
        ID: 50,
        Name: 'W. Europe Standard Time',
    },
    {
        ID: 51,
        Name: 'Central Europe Standard Time',
    },
    {
        ID: 52,
        Name: 'Romance Standard Time',
    },
    {
        ID: 53,
        Name: 'Central European Standard Time',
    },
    {
        ID: 54,
        Name: 'W. Central Africa Standard Time',
    },
    {
        ID: 55,
        Name: 'Namibia Standard Time',
    },
    {
        ID: 56,
        Name: 'Jordan Standard Time',
    },
    {
        ID: 57,
        Name: 'GTB Standard Time',
    },
    {
        ID: 58,
        Name: 'Middle East Standard Time',
    },
    {
        ID: 59,
        Name: 'Egypt Standard Time',
    },
    {
        ID: 60,
        Name: 'E. Europe Standard Time',
    },
    {
        ID: 61,
        Name: 'Syria Standard Time',
    },
    {
        ID: 62,
        Name: 'West Bank Standard Time',
    },
    {
        ID: 63,
        Name: 'South Africa Standard Time',
    },
    {
        ID: 64,
        Name: 'FLE Standard Time',
    },
    {
        ID: 65,
        Name: 'Israel Standard Time',
    },
    {
        ID: 66,
        Name: 'Kaliningrad Standard Time',
    },
    {
        ID: 67,
        Name: 'Libya Standard Time',
    },
    {
        ID: 68,
        Name: 'Arabic Standard Time',
    },
    {
        ID: 69,
        Name: 'Turkey Standard Time',
    },
    {
        ID: 70,
        Name: 'Arab Standard Time',
    },
    {
        ID: 71,
        Name: 'Belarus Standard Time',
    },
    {
        ID: 72,
        Name: 'Russian Standard Time',
    },
    {
        ID: 73,
        Name: 'E. Africa Standard Time',
    },
    {
        ID: 74,
        Name: 'Iran Standard Time',
    },
    {
        ID: 75,
        Name: 'Arabian Standard Time',
    },
    {
        ID: 76,
        Name: 'Astrakhan Standard Time',
    },
    {
        ID: 77,
        Name: 'Azerbaijan Standard Time',
    },
    {
        ID: 78,
        Name: 'Russia Time Zone 3',
    },
    {
        ID: 79,
        Name: 'Mauritius Standard Time',
    },
    {
        ID: 80,
        Name: 'Saratov Standard Time',
    },
    {
        ID: 81,
        Name: 'Georgian Standard Time',
    },
    {
        ID: 82,
        Name: 'Caucasus Standard Time',
    },
    {
        ID: 83,
        Name: 'Afghanistan Standard Time',
    },
    {
        ID: 84,
        Name: 'West Asia Standard Time',
    },
    {
        ID: 85,
        Name: 'Ekaterinburg Standard Time',
    },
    {
        ID: 86,
        Name: 'Pakistan Standard Time',
    },
    {
        ID: 87,
        Name: 'India Standard Time',
    },
    {
        ID: 88,
        Name: 'Sri Lanka Standard Time',
    },
    {
        ID: 89,
        Name: 'Nepal Standard Time',
    },
    {
        ID: 90,
        Name: 'Central Asia Standard Time',
    },
    {
        ID: 91,
        Name: 'Bangladesh Standard Time',
    },
    {
        ID: 92,
        Name: 'Omsk Standard Time',
    },
    {
        ID: 93,
        Name: 'Myanmar Standard Time',
    },
    {
        ID: 94,
        Name: 'SE Asia Standard Time',
    },
    {
        ID: 95,
        Name: 'Altai Standard Time',
    },
    {
        ID: 96,
        Name: 'W. Mongolia Standard Time',
    },
    {
        ID: 97,
        Name: 'North Asia Standard Time',
    },
    {
        ID: 98,
        Name: 'N. Central Asia Standard Time',
    },
    {
        ID: 99,
        Name: 'Tomsk Standard Time',
    },
    {
        ID: 100,
        Name: 'China Standard Time',
    },
    {
        ID: 101,
        Name: 'North Asia East Standard Time',
    },
    {
        ID: 102,
        Name: 'Singapore Standard Time',
    },
    {
        ID: 103,
        Name: 'W. Australia Standard Time',
    },
    {
        ID: 104,
        Name: 'Taipei Standard Time',
    },
    {
        ID: 105,
        Name: 'Ulaanbaatar Standard Time',
    },
    {
        ID: 106,
        Name: 'North Korea Standard Time',
    },
    {
        ID: 107,
        Name: 'Aus Central W. Standard Time',
    },
    {
        ID: 108,
        Name: 'Transbaikal Standard Time',
    },
    {
        ID: 109,
        Name: 'Tokyo Standard Time',
    },
    {
        ID: 110,
        Name: 'Korea Standard Time',
    },
    {
        ID: 111,
        Name: 'Yakutsk Standard Time',
    },
    {
        ID: 112,
        Name: 'Cen. Australia Standard Time',
    },
    {
        ID: 113,
        Name: 'AUS Central Standard Time',
    },
    {
        ID: 114,
        Name: 'E. Australia Standard Time',
    },
    {
        ID: 115,
        Name: 'AUS Eastern Standard Time',
    },
    {
        ID: 116,
        Name: 'West Pacific Standard Time',
    },
    {
        ID: 117,
        Name: 'Tasmania Standard Time',
    },
    {
        ID: 118,
        Name: 'Vladivostok Standard Time',
    },
    {
        ID: 119,
        Name: 'Lord Howe Standard Time',
    },
    {
        ID: 120,
        Name: 'Bougainville Standard Time',
    },
    {
        ID: 121,
        Name: 'Russia Time Zone 10',
    },
    {
        ID: 122,
        Name: 'Magadan Standard Time',
    },
    {
        ID: 123,
        Name: 'Norfolk Standard Time',
    },
    {
        ID: 124,
        Name: 'Sakhalin Standard Time',
    },
    {
        ID: 125,
        Name: 'Central Pacific Standard Time',
    },
    {
        ID: 126,
        Name: 'Russia Time Zone 11',
    },
    {
        ID: 127,
        Name: 'New Zealand Standard Time',
    },
    {
        ID: 128,
        Name: 'UTC+12',
    },
    {
        ID: 129,
        Name: 'Fiji Standard Time',
    },
    {
        ID: 131,
        Name: 'Chatham Islands Standard Time',
    },
    {
        ID: 133,
        Name: 'Tonga Standard Time',
    },
    {
        ID: 134,
        Name: 'Samoa Standard Time',
    },
    {
        ID: 135,
        Name: 'Line Islands Standard Time',
    },
];

// In some cases the timezone returned on Mac OSX differs from it's Windows equivalent.
// The object below represents a map from Mac OSX to Windows. This is necessary before converting to IANA timezone.
export const timezoneAliases = {
    'Greenwich Mean Time': 'GMT Standard Time',
};
