import { connect } from 'react-redux';
import { Area } from 'V3Models/Domain/Area';
import ResourceEquipment from 'V3Models/Domain/Equipment';
import { Room } from 'V3Models/Domain/Room';
import bookingActions from 'V3Stores/Thunks';
import bookingForm from 'V3Stores/booking';
import filters from 'V3Stores/filters';
import { RootState } from 'V3Stores/index';
import referenceData from 'V3Stores/organisationalStructure';
import user from 'V3Stores/user';
import sortRoomsForSites from 'V3Utilities/sortRoomsForSites';
import SelectableRooms from './SelectableRooms';

const mapStateToProps = (state: RootState) => {
    const userDetails = user.store.getUser(state);
    const rooms = referenceData.store.getRooms(state);
    const selectedRoom = bookingForm.store.getSelectedRoom(state);    
    const selectedAreas = filters.store.getSelectedAreas(state).map(
        (area) =>
            <Area>{
                ID: area.areaID,
                selected: area.selected,
                siteID: area.siteID,
                areaDisplayOrder: area.areaDisplayOrder,
                areaID: area.areaID,
                areaName: area.areaName,
                rooms: [],
                siteName: area.siteName,
            }
    );
    const selectedAreaIds = selectedAreas.filter((area) => area.selected).map((area) => area.areaID);

    const selectedEquipmentTypes = filters.store.getEquipmentTypes(state)?.filter((e) => e.selected);

    const isFilterMatch = (restrictedUsers: string[], areaID: string, equipment: ResourceEquipment[]): boolean => {
        const equipmentFilterMatch = selectedEquipmentTypes?.length > 0 ? equipmentInList(equipment, selectedEquipmentTypes) : true;
        const areaFilterMatch = selectedAreaIds.includes(areaID);
        const roomPassesUserRestrictions = checkUserRestrictions(userDetails.id, restrictedUsers);

        // bool array convert to true if all true else false
        return equipmentFilterMatch && areaFilterMatch && roomPassesUserRestrictions;
    };

    const equipmentInList = (roomEquipment: ResourceEquipment[], equipmentList: ResourceEquipment[]): boolean => {
        if (equipmentList && !roomEquipment) {
            return false;
        }
        if (!equipmentList && !roomEquipment) {
            return true;
        }
        return roomEquipment?.filter((re) => equipmentList
                                             .map((e) => e.name.toLowerCase())
                                             .includes(re.name.toLowerCase())
                                    ).length !== 0;
    };

    return {
        loadingStatus: referenceData.store.getRoomsLoadingStatus(state),
        sites: sortRoomsForSites(
            rooms.map(
                (room) =>
                    <Room>{
                        siteID: room.siteID,
                        siteName: room.siteName,
                        areaID: room.areaID,
                        areaName: room.areaName,
                        areaDisplayOrder: room.areaDisplayOrder,
                        roomID: room.roomID,
                        isFilterMatch: isFilterMatch(room.restrictedTo?.users, room.areaID, room.equipment),
                        displayRestrictionIcon:
                        checkUserRestrictions(userDetails.id, room.restrictedTo?.users) && room.restrictedTo?.users?.length > 0,
                        roomName: room.roomName,
                        capacity: room.capacity,
                        isSelected: false,
                        equipment: room.equipment,
                        itemDisplayOrder: room.itemDisplayOrder,
                        disabledAccess: room.disabledAccess
                    }
            )
        ),
        selectedAreas,
        selectedRoom,
    };
};

const mapActionsToProps = {
    selectRoom: bookingActions.selectRoom,
    toggleAreaFilterItem: filters.actions.toggleAreaFilterItem,
    setRoomInfo: bookingActions.setRoomInfo,
    setSelectedRoom: bookingForm.store.setSelectedRoom,
    resetSelectedRoom: bookingForm.store.resetSelectedRoom,
    resetFilters: filters.actions.resetFilters,
};

export default connect(mapStateToProps, mapActionsToProps)(SelectableRooms);

export type SelectableRoomsProps = ReturnType<typeof mapStateToProps> & typeof mapActionsToProps;

export const checkUserRestrictions = (permissedUserID: string, restrictedUsers: string[]): boolean => {
    return restrictedUsers?.length > 0 ? restrictedUsers.includes(permissedUserID) : true;
};
