import LoginToCBResponse from 'V3Models/Login/LoginToCBResponse';
import RefreshCBLoginResponse from 'V3Models/Login/RefreshLoginToCBResponse';

export const extractUserInfoFromLoginResponse = ({
    id,
    company,
    department,
    email,
    mobile,
    surname,
    firstname,
    title,
    tenantId,
    siteId,
    is24HoursTimeFormat,
    calendarId,
}: LoginToCBResponse) => ({
    id,
    company,
    is24HoursTimeFormat,
    title,
    tenantId,
    siteId,
    firstname,
    surname,
    email,
    mobile,
    department,
    calendarId
});

export const extractUserInfoFromLoginRefreshResponse = ({
    id,
    company,
    department,
    email,
    mobile,
    surname,
    firstname,
    title,
    tenantId,
    siteId,
    is24HoursTimeFormat,
    currentTenantName,
    calendarId,
}: RefreshCBLoginResponse) => ({
    id,
    company,
    is24HoursTimeFormat,
    title,
    tenantId,
    siteId,
    firstname,
    surname,
    email,
    mobile,
    department,
    tenantName: currentTenantName,
    calendarId,
});
