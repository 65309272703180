import React from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { BookingResponsePanelProps } from 'V3Components/BookingResponsePanel/index';
import { Panel } from 'V3Components/common';
import { EditBookingButton } from 'V3Components/common/buttons';
import PatchBookingRequest from 'V3Models/BookingService/PatchBookingRequest';
import BookingServiceApiClient from 'V3Services/BookingService/BookingServiceApiClient';
import LocalisationService from 'V3Services/LocalisationService';
import { BOOKING_TYPE } from 'V3Stores/booking/types';

import { ReactComponent as SuccessIcon } from '../../assets/SVGs/SuccessTick.svg';

import local from './local.scss';

const BookingResponsePanel = ({ bookingResponse, onEditBooking, userCalendarId }: BookingResponsePanelProps): JSX.Element => {
    const { type, item } = bookingResponse;
    const [itemExchangeId, setItemExchangeId] = React.useState<string>(null);
    const localisationService = LocalisationService.getInstance();
    const isOpen = !!(type && item);

    if (!item) {
        return null;
    }

    const buttons = <EditBookingButton id="booking-response__button_edit" onClick={onEditBooking} />;

    Office.context.mailbox.item.getItemIdAsync((asyncResult) => {
        setItemExchangeId(asyncResult.value);
    });

    React.useEffect(() => {
        const patchBooking = async (): Promise<void> => {
            const requestBody: PatchBookingRequest = {
                bookingId: item.id,
                msExchangeId: itemExchangeId,
                msExchangeCalendarId: userCalendarId,
            };
            await BookingServiceApiClient.addEventIdToBooking(requestBody);
        };
        if (itemExchangeId && userCalendarId) {
            patchBooking();
        }
    }, [itemExchangeId]);

    return (
        <Panel icon={InfoOutlinedIcon} buttons={buttons} isOpen={isOpen} id="booking-response__panel">
            <div className={local.bookingResponseContainer}>
                <SuccessIcon
                    id="booking-response__icon_success"
                    style={{ width: '30%', height: 'auto', textAlign: 'center', paddingBottom: '20px' }}
                />
                <h1 id="booking-response__h1">
                    {type === BOOKING_TYPE.CREATE && localisationService.strings.bookingSuccess}
                    {type === BOOKING_TYPE.UPDATE && localisationService.strings.updateSuccessMessage}
                    {type === BOOKING_TYPE.DELETE && localisationService.strings.deleteSuccessMessage}
                </h1>
                <div id="booking-response__block_booking-id" className={local.bookingId}>
                    {localisationService.strings.bookingId} <span id="booking-response__block_friendly-booking-id">{item.friendlyBookingId}</span>
                </div>
            </div>
        </Panel>
    );
};

export default BookingResponsePanel;
