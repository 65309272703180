interface Config {
    API_URL: string;
    ADDIN_URL: string;
    PORTALIMAGES_URL: string;
    V3_M365_SERVICE_URL: string;
}

declare global {
    interface Window {
        env: Config;
    }
}

const Config: Config = {
    API_URL: window?.env?.API_URL ?? '',
    ADDIN_URL: window?.env?.ADDIN_URL ?? '',
    PORTALIMAGES_URL: window?.env?.PORTALIMAGES_URL ?? '',
    V3_M365_SERVICE_URL: window?.env?.V3_M365_SERVICE_URL ?? '',
};

export default Config;

