import Booking from 'V3Models/BookingService/Booking/Booking';
import BookingUserPropertyInfo from 'V3Models/Domain/BookingUserPropertyInfo';
import OutlookService from 'V3Services/OutlookService';
import settingsUtil from 'V3Utilities/settingsUtil';

export default class BookingService {
    public static saveBookingInfoAsOutlookCustomProperty = async (booking: Booking): Promise<void> => {
        const outlookCustomPropValue = `CB, ${booking.id}`;

        const newBookingUserPropertyInfo: BookingUserPropertyInfo = {
            bookingId: booking.id,
            suppressNotification: true,
        };

        await OutlookService.saveCustomProperty(settingsUtil.constants.PLUGIN_CUST_PROP_KEY, outlookCustomPropValue);
        await OutlookService.saveCustomProperty(settingsUtil.constants.ADDIN_USER_PROP_KEY, JSON.stringify(newBookingUserPropertyInfo));

        return new Promise((resolve) => {
            resolve();
        });
    };
}
