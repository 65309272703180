import { Action } from 'V3Utilities/Action';
import { REDUCER_NAMES } from 'V3Utilities/constants';

const reducerName = REDUCER_NAMES.ERROR;

export const ACTION_TYPES = {
    SET_ERROR: `${reducerName}/SET_ERROR`,
    CLEAR_ERRORS: `${reducerName}/CLEAR_ERRORS`
};

export interface SetErrorAction extends Action {
    error: ErrorStateInterface;
}

export interface ClearErrorsAction extends Action {}

export enum ErrorLevel {
    ERROR,
    WARNING,
    OK,
}

export type ErrorActionType = SetErrorAction | ClearErrorsAction;

export interface ErrorStateInterface {
    errorLevel: ErrorLevel;
    errorMessage: string;
}
