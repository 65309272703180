import { ACTION_TYPES, SetOnBehalfOfUserAction, SetUserAction, UserActionType, UserStateInterface } from './types';

export const initialState: UserStateInterface = {
    user: null,
    onBehalfOfUser: null,
    subscriptionStatusMessage: null,
};

export default (state = initialState, action: UserActionType): UserStateInterface => {
    switch (action.type) {
        case ACTION_TYPES.SET_USER:
            return {
                ...state,
                user: (action as SetUserAction).payload,
            };

        case ACTION_TYPES.SET_ON_BEHALF_OF_USER:
            return {
                ...state,
                onBehalfOfUser: (action as SetOnBehalfOfUserAction).payload,
            };

        default: {
            return state;
        }
    }
};
