import User from 'V3Models/Domain/User';
import OnBehalfOfUser from 'V3Models/Domain/OnBehalfOfUser';

import { ACTION_TYPES, SetOnBehalfOfUserAction, SetUserAction } from './types';

export const setUser = (user: User): SetUserAction => ({
    type: ACTION_TYPES.SET_USER,
    payload: user,
});

export const setOnBehalfOfUser = (user: OnBehalfOfUser): SetOnBehalfOfUserAction => ({
    type: ACTION_TYPES.SET_ON_BEHALF_OF_USER,
    payload: user,
});
