import { connect } from 'react-redux';
import bookingActions from 'V3Stores/Thunks';
import { RootState } from 'V3Stores/index';
import navigation from 'V3Stores/navigation';
import BookingDeletePanel from './BookingDeletePanel';

const mapStateToProps = (state: RootState) => ({
    isOpen: navigation.store.getShowDeleteBooking(state)
});

const mapDispatchToProps = {
    deleteBooking: bookingActions.deleteBooking
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDeletePanel);

export type BookingDeletePanelProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
