import { connect } from 'react-redux';
import { RootState } from 'V3Stores/index';
import filters from 'V3Stores/filters';
import ResourceTypeFilter from './ResourceTypeFilter';

const mapStateToProps = (state: RootState) => ({ 
        equipmentTypes: filters.store.getEquipmentTypes(state)        
    });

const mapActionsToProps = {
    toggleEquipmentTypeFilterItem: filters.actions.toggleEquipmentTypeFilterItem,
};

export default connect(mapStateToProps, mapActionsToProps)(ResourceTypeFilter);

export type ResourceFilterProps = ReturnType<typeof mapStateToProps> & typeof mapActionsToProps;