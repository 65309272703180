import React from 'react';
import Select from 'react-select';
import classnames from 'classnames';

import LocalisationService from 'V3Services/LocalisationService';

import InputWrapper, { InputWrapperProps } from '../InputWrapper';

import ListItem from './ListItem';

import './local.scss';

export interface SelectProps extends InputWrapperProps {
    items: ListItem[];
    value: string | number;
    isDisabled?: boolean;
    placeholder?: string;
    isLoading?: boolean;
    isClearable?: boolean;
    onChange?: (value: ListItem) => void;
}
export default (props: SelectProps): JSX.Element | null => {
    const {
        label,
        name,
        className,
        hideLabel,
        required,
        error,
        items,
        value,
        isDisabled = false,
        isLoading = false,
        isClearable = false,
        onChange,
        id
    } = props;
    let { placeholder } = props;

    if (!placeholder) {
        const localisationService = LocalisationService.getInstance();
        placeholder = localisationService.strings.selectOption;
    }

    return (
        <InputWrapper label={label} name={name} className={className} hideLabel={hideLabel} required={required} error={error} id={id}>
            <Select
                name={name}
                onChange={onChange}
                className={classnames('select-container', value && 'select-container--has-value', isDisabled && 'select-container--disabled')}
                classNamePrefix="select"
                options={items}
                value={value && items?.find((i) => i.value === value)}
                placeholder={placeholder}
                isDisabled={isDisabled}
                isClearable={isClearable}
                isLoading={isLoading}
                menuPosition="fixed"
                id={`${id}_select`}
            />
        </InputWrapper>
    );
};
