import { Area } from 'V3Models/Domain/Area';
import ResourceEquipment from 'V3Models/Domain/Equipment';
import { Action } from 'V3Utilities/Action';
import { REDUCER_NAMES } from 'V3Utilities/constants';

const reducerName = `${REDUCER_NAMES.ROOMS}/${REDUCER_NAMES.FILTERS}`;

export const ACTION_TYPES = {
    FILTERS_SET_ATTENDEES: `${reducerName}/SET_ATTENDEES`,
    FILTERS_SET_EQUIPMENT_TYPES: `${reducerName}/SET_EQUIPMENT_TYPES`,
    FILTERS_INITIALISE: `${reducerName}/FILTERS_INITIALISE`,
    FILTERS_SET_SELECTED_AREAS: `${reducerName}/FILTERS_SET_SELECTED_AREAS`,
};

export interface SetAttendeesAction extends Action {
    attendees: number;
}

export interface SetEquipmentAction extends Action {
    equipmentTypes: ResourceEquipment[];
}

export type FiltersInitialiseAction = Action;

export interface SetSelectedAreasAction extends Action {
    selectedAreas: Area[];
}

export type FiltersActionType = SetAttendeesAction | SetEquipmentAction | SetSelectedAreasAction | FiltersInitialiseAction;

export interface FiltersStateInterface {
    attendees: number;
    equipmentTypes: ResourceEquipment[];
    selectedAreas: Area[];
}
