import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';

import local from './local.scss';

interface ProgressProps {
    size?: 'small' | 'normal';
    id?: string;
}

const Progress = (props: ProgressProps): JSX.Element => {
    const { size = 'normal', id } = props;

    return (
        <div id={id} className={classnames(local.progress, size === 'small' ? local.small : null)}>
            <CircularProgress id={`${id}_circular-progress`} />
        </div>
    );
};

export default Progress;
