import { Action } from 'V3Utilities/Action';
import { REDUCER_NAMES } from 'V3Utilities/constants';

const reducerName = `${REDUCER_NAMES.ROOMS}/${REDUCER_NAMES.NAVIGATION}`;

export const ACTION_TYPES = {
    SET_AVAILABLE_TABS: `${reducerName}/SET_AVAILABLE_TABS`,
    SET_CURRENT_TAB: `${reducerName}/SET_CURRENT_TAB`,
    SET_SHOW_DELETE_BOOKING: `${reducerName}/SET_SHOW_DELETE_BOOKING`,
    SET_SHOW_APPOINTMENT_CHANGED: `${reducerName}/SET_SHOW_APPOINTMENT_CHANGED`,
    SET_INFO_MESSAGE: `${reducerName}/SET_INFO_MESSAGE`,
    SET_OPERATION_IN_PROGRESS: `${reducerName}/SET_OPERATION_IN_PROGRESS`,
    REMOVE_TAB: `${reducerName}/REMOVE_TAB`,
    PREVIOUS_TAB: `${reducerName}/PREVIOUS_TAB`
};

export interface RemoveTabAction extends Action {
    type: typeof ACTION_TYPES.REMOVE_TAB;
    tab: string;
}

export interface SetAvailableTabsAction extends Action {
    type: typeof ACTION_TYPES.SET_AVAILABLE_TABS;
    tabs: string[];
}

export interface SetCurrentTabAction extends Action {
    type: typeof ACTION_TYPES.SET_CURRENT_TAB;
    tab: string;
}

export interface SetPreviousTabAction extends Action {
    type: typeof ACTION_TYPES.PREVIOUS_TAB;
    tab: string;
}

export interface SetShowDeleteBookingAction extends Action {
    type: typeof ACTION_TYPES.SET_SHOW_DELETE_BOOKING;
    value: boolean;
}

export interface SetShowAppointmentChangedAction extends Action {
    type: typeof ACTION_TYPES.SET_SHOW_APPOINTMENT_CHANGED;
    value: boolean;
}

export interface SetInfoMessageAction extends Action {
    type: typeof ACTION_TYPES.SET_INFO_MESSAGE;
    value: string;
}

export interface SetOperationInProgressAction extends Action {
    type: typeof ACTION_TYPES.SET_OPERATION_IN_PROGRESS;
    value: boolean;
}

export type TabsActionType =
    | SetAvailableTabsAction
    | SetCurrentTabAction
    | SetShowDeleteBookingAction
    | SetShowAppointmentChangedAction
    | SetInfoMessageAction
    | RemoveTabAction
    | SetPreviousTabAction;

export interface NavigationStateInterface {
    availableTabs: string[];
    currentTab: string;
    previousTab: string;
    showDeleteBooking: boolean;
    showAppointmentChanged: boolean;
    infoMessage: string;
    operationInProgress: boolean;
}
