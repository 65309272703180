import { connect } from 'react-redux';
import BookingHistory from 'V3Components/BookingDetails/BookingHistory/BookingHistory';
import booking from 'V3Stores/booking';
import { RootState } from 'V3Stores/index';
import user from 'V3Stores/user';

const mapStateToProps = (state: RootState) => ({
    bookingHistory: booking.store.getBookingHistory(state),
    is24HoursTimeFormat: user.store.getUser(state).is24HoursTimeFormat
});

export type BookingHistoryProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(BookingHistory);
