export default class LoggingService {
    private static MessagePrefix = '##########----------';

    private static DeepClone(object: any): any {
        return JSON.parse(JSON.stringify(object));
    }

    public static Debug(message: string, object: any = null): void {
        if (!object) console.log(`${LoggingService.MessagePrefix} ${message}`);
        else console.log(`${LoggingService.MessagePrefix} ${message}`, LoggingService.DeepClone(object));
    }

    public static Info(message: string, object: any = null): void {
        if (!object) console.log(`${LoggingService.MessagePrefix} ${message}`);
        else console.log(`${LoggingService.MessagePrefix} ${message}`, LoggingService.DeepClone(object));
    }

    public static Warn(message: string, object: any = null): void {
        if (!object) console.warn(`${LoggingService.MessagePrefix} ${message}`);
        else console.warn(`${LoggingService.MessagePrefix} ${message}`, LoggingService.DeepClone(object));
    }

    public static Error(message: string, object: any = null): void {
        if (!object) console.error(`${LoggingService.MessagePrefix} ${message}`);
        else console.error(`${LoggingService.MessagePrefix} ${message}`, object, LoggingService.DeepClone(object));
    }
}

/* eslint-enable no-console */
/* eslint-enable @typescript-eslint/no-explicit-any */
