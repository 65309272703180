import booking from 'V3Stores/booking';
import bookingActions from 'V3Stores/Thunks';
import { TABS } from 'V3Utilities/constants';
import { AppThunk } from '..';
import * as actions from '../navigation/storeActions';

export const setCreateBookingTabs = (): AppThunk  => (dispatch) => {    
    const tabs: string[] = [];
    tabs.push(TABS.HOME);
    dispatch(actions.setAvailableTabs(tabs));
    dispatch(actions.setCurrentTab(TABS.HOME));
};

export const setEditBookingTabs = (): AppThunk => (dispatch, getState) => {    
    const isEdit = booking.store.getIsEdit(getState());
    const tabs = [];

    tabs.push(TABS.BOOKING);

    if (isEdit) {
        tabs.push(TABS.HISTORY);
    }

    tabs.push(TABS.EQUIPMENT);

    if (!isEdit) {
        dispatch(bookingActions.loadRoomBookingInformation());
    }

    dispatch(actions.setAvailableTabs(tabs));    
    dispatch(actions.setCurrentTab(TABS.BOOKING));
};

export const setTab = (tab: string): AppThunk => (dispatch) => {    
    if (tab !== TABS.ERROR) {
        dispatch(actions.removeTab(TABS.ERROR));
    }
    dispatch(actions.setCurrentTab(tab));
};