import * as actions from '../filters/storeActions';
import * as selectors from '../filters/storeSelectors';

export const toggleAreaFilterItem = (id: string) => (dispatch, getState) => {
    const list = selectors.getSelectedAreas(getState()).map((item) => (item.areaID !== id ? item : { ...item, selected: !item.selected }));
    dispatch(actions.setSelectedAreas(list));
};

export const toggleEquipmentTypeFilterItem = (equipmemntTypeId: string) => (dispatch, getState) => {
    const equipmentList = selectors
        .getEquipmentTypes(getState())
        .map((item) => (item.id !== equipmemntTypeId ? item : { ...item, selected: !item.selected }));
    dispatch(actions.setEquipmentTypes(equipmentList));
};

export const resetFilters = () => (dispatch, getState) => {
    const equipmentList = selectors.getEquipmentTypes(getState()).map((item) => ({ ...item, selected: false }));
    const areaList = selectors.getSelectedAreas(getState()).map((item) => ({ ...item, selected: true }));
    dispatch(actions.setEquipmentTypes(equipmentList));
    dispatch(actions.setSelectedAreas(areaList));
};
