import { RootState } from "..";

export const getAvailableTabs = (state: RootState): string[] => state.navigation.availableTabs;
export const getCurrentTab = (state: RootState): string => state.navigation.currentTab;
export const getShowDeleteBooking = (state: RootState): boolean => state.navigation.showDeleteBooking;
export const getShowAppointmentChanged = (state: RootState): boolean => state.navigation.showAppointmentChanged;
export const getInfoMessage = (state: RootState): string => state.navigation.infoMessage;
export const getBookingOpInProgress = (state: RootState): boolean => {
  return state.navigation.operationInProgress; 
}
export const getPreviousTab = (state: RootState): string => state.navigation.previousTab;