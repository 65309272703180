import { connect } from 'react-redux';
import { EntityInfo as RoomInfoInterface } from 'V3Models/Domain/EntityInfo';
import bookingForm from 'V3Stores/booking';
import { RootState } from 'V3Stores/index';
import organisationalStructure from 'V3Stores/organisationalStructure';
import RoomInfo from './RoomInfo';

const mapStateToProps = (state: RootState): RoomInfoProps => {
    const selectedRoom = bookingForm.store.getSelectedRoom(state);    
    const roomEntityInfo = organisationalStructure.store.getRoomInfo(selectedRoom?.roomID)(state);
    const roomImageURL = organisationalStructure.store.getRoomImageURL(state);

    return {
        roomInfo: {
            ...roomEntityInfo,
        },
        loadingStatus: organisationalStructure.store.getRoomInfoLoadingStatus(state),
        roomImageURL
    };
};

export default connect(mapStateToProps)(RoomInfo);

export interface RoomInfoProps {
    roomInfo: RoomInfoInterface;
    loadingStatus: string;
    roomImageURL: string;
}
