import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import classnames from 'classnames';
import ButtonBar from '../../common/ButtonBar';

import { BackIcon } from './icon';

import local from './panel.scss';
import NavBar from 'V3Components/Navbar';
import { TabInterface } from 'V3Components/Tabs/Tabs';

interface PanelProps {
    isOpen: boolean;
    onClose?: () => void;
    buttons?: any | any[];
    icon: any;
    children: any | any[];
    isWebClient?: boolean;
    id?:string;
}

const Panel = (props: PanelProps): JSX.Element => {
    const { isOpen, onClose, buttons, icon, children, isWebClient, id } = props;
    const tabs: TabInterface[] = [{ icon, value: true  }];

    // add back button if onClose is defined
    if (onClose) {
        tabs.unshift({ icon: BackIcon, value: false });
    }

    return (
        <Dialog fullScreen open={isOpen} onClose={onClose} id={`${id}__dialog`}>
            <div className={local.panel}>
                <NavBar id={id} tabs={tabs} value showCommands={false} onChange={onClose} isLoading={false} onRefresh={null} />

                <div id={`${id}_children`} className={classnames(local.panelContent, isWebClient ? null : local.desktopClient)}>{children}</div>

                <div className={local.buttonBar} id={`${id}__block_button-bar`}>
                    <ButtonBar>{buttons}</ButtonBar>
                </div>
            </div>
        </Dialog>
    );
};

export default Panel;
