import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import classnames from 'classnames';
import parse from 'html-react-parser';
import { ReactComponent as CBLogo } from 'V3/assets/CB-logo-vertical-colour.svg';
import { ReactComponent as SupportIcon } from 'V3/assets/cb-support-icon.svg';
import { Label, Panel } from 'V3Components/common';
import { BackButton } from 'V3Components/common/buttons';
import { ROUTES } from 'V3Utilities/constants';
import { NavBarCommandsProps } from '.';
import local from './local.scss';

const NavBarCommands = ({ setRoute, supportText, logoutFromCB }: NavBarCommandsProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const isMenuOpen = Boolean(anchorEl);
    const [panelContent, setPanelContent] = React.useState<string>(null);

    const handleMenuOpen = (event): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (): void => {
        setAnchorEl(null);
    };

    const getAboutPanelContent = (): JSX.Element => {
        const year = new Date().getFullYear();

        return (
            <div className={local.panelContent}>
                <CBLogo className={classnames(local.cbLogoStyle)} id="nav-bar-commands__img_cb-logo" />
                <Label text={`© Cloudbooking Limited ${year}`} id="nav-bar-commands__label_CBltd" />
            </div>
        );
    };

    const getSupportContent = (): JSX.Element => {

        if (supportText) {
            let parsedText = supportText.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
            return (
                <div className={local.panelContent}>
                    <SupportIcon className={classnames(local.supportIconStyle)} id="nav-bar-commands__icon_support" />
                    <p id="nav-bar-commands__para_support-txt">{parse(parsedText)}</p>
                </div>
            );
        }

        const defaultText1 = "We'd love to hear from you and help in any way we can";
        const defaultText2 = ' so please either email us at ';

        const helpdeskTel = '+44 (0) 800 033 7841';
        const helpdeskEmail = 'help@cloudbooking.com';

        const emailHref = `mailto:${helpdeskEmail}`;

        return (
            <div className={local.panelContent}>
                <SupportIcon className={classnames(local.supportIconStyle)} />
                <h3 id="nav-bar-commands__header_text"><b>Support</b></h3>
                <p id="nav-bar-commands__para_footer-support-txt">
                    <b>{defaultText1}</b>
                    <br />
                    {defaultText2}
                    <br />
                    {parse(`<a href='${emailHref}'>${helpdeskEmail}</a>`)}
                    {parse(`<br /> or call us on <br />`)}
                    {helpdeskTel}
                </p>
            </div>
        );
    };

    const showAbout = (): void => {
        handleMenuClose();
        setPanelContent('about');
    };

    const showSupport = (): void => {
        handleMenuClose();
        setPanelContent('support');
    };

    const closePanel = (): void => {
        setPanelContent(null);
    };

    const signOut = async () => {
        const { roamingSettings } = Office.context;
        await logoutFromCB();

        roamingSettings.saveAsync(() => {
            setRoute(ROUTES.LOGIN);
        });
    };

    let currentView: JSX.Element = null;
    let currentIcon: any = null;
    switch (panelContent) {
        case 'about':
            currentView = getAboutPanelContent();
            currentIcon = InfoOutlinedIcon;
            break;
        case 'support':
            currentView = getSupportContent();
            currentIcon = HeadsetMicIcon;
            break;
        default:
            break;
    }

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={signOut} id="nav-bar-commands__menu-item_sign-out">
                <PowerSettingsNewIcon id="nav-bar-commands__power-settings-icon" fontSize="small" style={{ marginRight: '0.5rem' }} />
                Sign out
            </MenuItem>
            <MenuItem onClick={showSupport} id="nav-bar-commands__menu-item_show-support">
                <HeadsetMicIcon fontSize="small" id="nav-bar-commands__headset-icon" style={{ marginRight: '0.5rem' }} />
                Support
            </MenuItem>
            <MenuItem onClick={showAbout} id="nav-bar-commands__menu-item_about">
                <InfoOutlinedIcon fontSize="small" id="nav-bar-commands__about-icon" style={{ marginRight: '0.5rem' }} />
                About
            </MenuItem>
        </Menu>
    );

    return (
        <>
            <div className={local.navBar}>
                <IconButton
                    aria-label="delete"
                    className={local.refresh}
                    size="small"
                    onClick={handleMenuOpen}
                    id="nav-bar-commands__button_menu-item_menu-open"
                >
                    <SettingsIcon fontSize="inherit" style={{ color: 'white' }} className={local.settings} id="nav-bar-commands__img-settings-icon" />
                </IconButton>
            </div>

            {renderMenu}

            <Panel
                icon={currentIcon || InfoOutlinedIcon}
                isOpen={Boolean(panelContent)}
                buttons={<BackButton onClick={closePanel} />}
                onClose={closePanel}
                id="nav-bar-commands__panel_current-view"
            >
                {currentView}
            </Panel>
        </>
    );
};

export default NavBarCommands;
