import { Room } from 'V3Models/Domain/Room';
import { Site } from 'V3Models/Domain/Site';

import sortBy from './sortBy';

export default (rooms: Room[]): Site[] => {
    // sort distinct sites by name
    const sites: Site[] = sortBy(
        rooms.reduce((acc: SitesServiceSite[], curr) => {
            if (!acc.find((r) => r.siteID === curr.siteID)) {
                acc.push({ siteID: curr.siteID, siteName: curr.siteName });
            }
            return acc;
        }, []),
        (site: Site) => site.siteName.toUpperCase()
    );

    // sort distinct areas by area display order and name
    sites.forEach((site) => {
        const siteAreas = rooms.filter((r) => r.siteID === site.siteID);
        site.areas = sortBy(
            siteAreas.reduce((acc: SitesServiceArea[], curr) => {
                if (!acc.find((r) => r.areaID === curr.areaID)) {
                    acc.push({ areaID: curr.areaID, areaName: curr.areaName, areaDisplayOrder: curr.areaDisplayOrder });
                }
                return acc;
            }, []),
            (area: SitesServiceArea) => `${(area.areaDisplayOrder || 0).toString().padStart(6, '0')}${area.areaName.toUpperCase()}`
        );

        // sort distinct rooms by name
        site.areas.forEach((area) => {
            const siteAreaRooms = rooms.filter((r) => r.siteID === site.siteID && r.areaID === area.areaID);
            area.rooms = sortBy(
                siteAreaRooms.reduce((acc: Room[], curr) => {
                    if (!acc.find((r) => r.roomID === curr.roomID)) {
                        acc.push(curr);
                    }
                    return acc;
                }, []),
                (room) => room.roomName.toUpperCase()
            );
        });
    });

    return sites;
};

interface SitesServiceArea {
    areaID: string;
    areaName: string;
    areaDisplayOrder: number;
}

interface SitesServiceSite {
    siteID: string;
    siteName: string;
}
