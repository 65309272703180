import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import bookingActions from 'V3Stores/Thunks';
import organisationalStructure from 'V3Stores/organisationalStructure';
import SiteSelectorSiteList, { SiteSelectorSitesListInterface } from './SiteSelectorSiteList';

const mapStateToProps = createSelector(
    organisationalStructure.store.getSites,
    organisationalStructure.store.getSelectedSiteIDs,
    (sites, selectedSites) =>
        <SiteSelectorSitesListInterface>{
            items: sites.sort((a, b) => (a.siteName).localeCompare(b.siteName)),
            selectedSites,
        }
);

const mapDispatchToProps = {
    onSitesSelect: bookingActions.onSitesSelect,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteSelectorSiteList);
