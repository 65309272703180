import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { TabInterface, ActiveTabInterface } from 'V3Components/Tabs/Tabs';
import Fill from './Fill';
import local from './navBar.scss';
import NavBarCommands from './NavBarCommands';

const BorderLinearProgress = withStyles(() => ({
    root: {
        height: 3,
    },
    colorPrimary: {
        backgroundColor: '#0f2b4a',
    },
    bar: {
        backgroundColor: '#1796c6',
    },
}))(LinearProgress);

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        position: 'absolute',
        top: 0,
        width: '100%',
    },
});

const NavBar = (props: NavBarProps): JSX.Element => {
    const { tabs, value, onChange, showCommands = true, isLoading, id } = props;
    const classes = useStyles();

    const handleClick = (tab: ActiveTabInterface): void => {        
        if (!tab.isActive) {
            onChange(tab.value);
        }
    };

    return (
        <div className={local.navBarComponent} id={`${id}__block_navbar-panel`}>
            <div className={classes.root}>{isLoading && <BorderLinearProgress color="primary" id={`${id}__icon_border-linear-progress`}/>}</div>
            <div className={local.navBar}>
                <div className={local.tabPanel}>
                    {tabs.map((tab) => {                        
                        const isActive = value === tab.value;
                        const Image = Fill(tab.icon);

                        return (
                            <button
                                id={`${id}__button_${tab?.value}`}
                                type="button"
                                key={tab.value}
                                onClick={() => handleClick({ ...tab, isActive })}
                                className={classnames(local.tab, {
                                    [local.isActive]: isActive,
                                })}
                                disabled={tab.isDisabled}
                            >
                                <Image color={isActive ? 'black' : 'white'} id={`${id}__image_${tab?.value}`}/>
                            </button>
                        );
                    })}
                </div>
                {showCommands && (
                    <div className={local.commands} id={`${id}__block_navbarcommands`}>
                        <NavBarCommands />
                    </div>
                )}
            </div>
        </div>
    );
};

export default NavBar;

export interface NavBarProps {
    tabs: TabInterface[];
    value: any;
    showCommands: boolean;
    isLoading: boolean;
    onChange: Function;
    onRefresh: Function;
    id?:string;
}
