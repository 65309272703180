import { REDUCER_NAMES } from 'V3Utilities/constants';

const reducerName = REDUCER_NAMES.ROUTE;

export const ACTION_TYPES = {
    SET_ROUTE: `${reducerName}/SET_ROUTE`,
    SET_IS_WEB_CLIENT: `${reducerName}/SET_IS_WEB_CLIENT`,
};

export interface SetRouteAction {
    type: typeof ACTION_TYPES.SET_ROUTE;
    route: string;
}

export interface SetIsWebClientAction {
    type: typeof ACTION_TYPES.SET_IS_WEB_CLIENT;
    isWebClient: boolean;
}

export interface RouteStateInterface {
    isWebClient: boolean;
    route: string;
}

export type RouteActionType = SetRouteAction | SetIsWebClientAction;
