import jwtDecode from 'jwt-decode';
import Credential from 'V3Models/Domain/Credential';
import OnBehalfOfUser from 'V3Models/Domain/OnBehalfOfUser';
import User from 'V3Models/Domain/User';
import { getOrganizer } from 'V3Services/AppointmentService/AppointmentService';
import ErrorHandlingService from 'V3Services/ErrorHandlingService';
import PersonSearchApiClient from 'V3Services/PersonSearch/PersonSearchServiceApiClient';
import { RoomBooking } from 'V3Utilities/CBEnums';

export default class UserService {

    public static getCredential = (): Credential => {
        return {
            username: Office.context.roamingSettings.get('username'),
            msgraphToken: Office.context.roamingSettings.get('msgraphToken'),
        };
    }

    public static getOnBehalfOfUser = async (currentUser: User): Promise<OnBehalfOfUser> => {
        let onBehalfOfUser = null;
        const organizer = await getOrganizer();

        if (organizer.emailAddress !== currentUser.email) {
            onBehalfOfUser = { email: organizer.emailAddress, fullName: organizer.displayName };
            try {
                onBehalfOfUser.userId = await UserService.getOnBehalfOfUserId(organizer.displayName);
            } catch (err) {
                ErrorHandlingService.HandleSystemError('US/OBO/U', err);
            }
        }
        return new Promise<OnBehalfOfUser>((resolve) => {
            resolve(onBehalfOfUser);
        });
    }

    private static getOnBehalfOfUserId = async (fullName: string): Promise<number | string> => {
        const response = await PersonSearchApiClient.lookupPerson(fullName);

        const exactMatchContacts = response.items.filter((el) => el.firstname.toUpperCase().trim() === fullName.toUpperCase().trim());

        switch (exactMatchContacts.length) {
            case 0:
                return null;
            case 1:
                return exactMatchContacts[0].id;
            default:
                ErrorHandlingService.HandleSystemError(
                    'US/OBO/ID',
                    `Error getting on-behalf-of user: contact look-up for "${fullName}" returned ${exactMatchContacts.length} contacts`
                );
                return null;
        }
    }

    private static getAllPermissionsFromJwt = (token: string): any => {
        const decoded = jwtDecode(token);
        return JSON.parse(decoded['cb-permissions'].replace(/'/g, '"'));
    }

    public static getRoomBookingPermissions = () => {
        const accessToken = localStorage.getItem('accessToken');
        const roomBookingNum = UserService.getAllPermissionsFromJwt(accessToken).RoomBooking;
        return {
            EditOtherUsers: !!(RoomBooking.EditOtherUsers && roomBookingNum),
            EditPastBookings: !!(RoomBooking.EditPastBookings && roomBookingNum),
            BookOnBehalf: !!(RoomBooking.BookOnBehalf && roomBookingNum),
        };
    }
}
