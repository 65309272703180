import React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { BookingHistoryProps } from 'V3Components/BookingDetails/BookingHistory/index';
import { convertTimestring } from 'V3Utilities/date&time/convertTimestring';
import local from './local.scss';
import { DateTime } from 'luxon';
import HistoryItem from 'V3Models/Domain/HistoryItem';




const historyItemJSX = function (historyItem: string) {
    let jsxHistoryItem:  HistoryItem = {
        historyElement: null,
        isDate: false
    }

    if(historyItem && historyItem?.length > 0){
        let asDate = DateTime.fromISO(historyItem);
        if (asDate.isValid) {
            jsxHistoryItem.isDate = true;
            jsxHistoryItem.historyElement = <>{asDate.toFormat('d LLL yyyy HH:mm')}</>;
        }
        else {
            jsxHistoryItem.historyElement = <>{historyItem}</>
        }
    }

    return jsxHistoryItem;
}

const BookingHistory = ({ bookingHistory, is24HoursTimeFormat }: BookingHistoryProps) => {

    return (
        <div className={local.historyContainer}>
            <h1 id='booking-history__h1' className={local.title}>History</h1>
            <ul id='booking-history__unordered-list' className={local.historyList}>
                {bookingHistory ? (
                    bookingHistory.map((item) => {                      
                        const user = item.userInfo;
                                                
                        let historyItemBefore: HistoryItem = historyItemJSX(item.metadata?.propertyBefore);
                        let historyItemAfter: HistoryItem= historyItemJSX(item.metadata?.propertyAfter);
                        let detailsJSX: JSX.Element = <></>;

                        if(historyItemBefore.historyElement) {                                          
                            detailsJSX = <>
                                            <span>{item.metadata.propertyName} {historyItemBefore.isDate && '(UTC)'} changed from </span><br/>
                                            <span>{historyItemBefore.historyElement} to {(historyItemAfter.historyElement ? historyItemAfter.historyElement : 'blank.')}</span>
                                         </>
                        }
                        else if(historyItemAfter.historyElement) {
                            detailsJSX = <><span>{item.metadata.propertyName} changed to</span><br/>
                            <span>{historyItemAfter.historyElement}</span></>
                        }

                        return ( 
                            <li className={local.historyItem} key={item.timestamp}>
                                <AccountCircleIcon />
                                <div className={local.itemContent}>
                                    <span id='booking-history__block_time-string'>{convertTimestring(item.timestamp, is24HoursTimeFormat)}</span>
                                    <br />
                                    <span id={`booking-history__span_user-${user?.firstname}`} className={local.user}>{`${user?.firstname} ${user?.surname}`}</span>
                                    <span id='booking-history__block_time-string' className={local.event}>{item.domainEventName}</span>
                                    <br />
                                    {detailsJSX}
                                </div>
                            </li>
                        );
                    })
                ) : (
                    <div id='booking-history__block_no-history-available'>No history available</div>
                )}
            </ul>
        </div>
    );
};

export default BookingHistory;