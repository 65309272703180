import React, { useState } from 'react';
import { Icon } from '@fluentui/react';
import classnames from 'classnames';
import { OnBehalfOfProps } from 'V3Components/Forms/OnBehalfOf/index';
import LocalisationService from 'V3Services/LocalisationService';
import { InputWrapper } from '../../common';
import Panel from './Panel';
import local from './local.scss';

const OnBehalfOf = ({ id,idName, value = null, onChange }: OnBehalfOfProps) => {
    const [isPanelOpen, setPanelOpen] = useState<boolean>(false);
    const localisationService = LocalisationService.getInstance();

    const onSelect = (value) => {
        onChange(value);
        setPanelOpen(false);
    };

    return (
        <>
            <InputWrapper label={localisationService.strings.onbehalfOf} name={id} className={local.onBehalfOfInputWrapper}>
                <div className={classnames(local.onBehalfOfControl, { [local.hasValue]: value?.personId })}>
                    <div className={local.onBehalfOfContainer}>
                        {value?.personId ? (
                            <>
                                <div id={`on-behalf-of__block_contact-${idName}`} className={local.contactName}>{`${value?.firstname} ${value?.surname}`}</div>
                                {value && (
                                    <Icon
                                        id={`${idName}__icon-cancel`}
                                        iconName="Cancel"
                                        className={classnames(local.icon)}
                                        onClick={() => {
                                            onSelect(null);
                                        }}
                                    />
                                )}
                            </>
                        ) : (
                            <button id={`${idName}__button_open-panel`} type="button" onClick={() => setPanelOpen(true)}>
                                <Icon id={`${idName}__icon_search`} iconName="Search" className={classnames(local.icon)} onClick={() => setPanelOpen(true)} />
                            </button>
                        )}
                    </div>
                </div>
            </InputWrapper>
            <Panel id={idName} isOpen={isPanelOpen} onClose={() => setPanelOpen(false)} onSelect={onSelect} />
        </>
    );
};

export default OnBehalfOf;
