import React, { useEffect, useState } from 'react';
import { ChoiceGroup, IChoiceGroupOption, MessageBar, MessageBarType, PrimaryButton, Separator } from '@fluentui/react';
import classnames from 'classnames';
import { LoginProps } from 'V3Components/Login/index';
import AppTenant from 'V3Models/TenantsService/AppTenant';
import AuthScheme from 'V3Models/TenantsService/AuthScheme';
import AuthenticationServiceApi from 'V3Services/AuthenticationService';
import ErrorHandlingService from 'V3Services/ErrorHandlingService';
import LocalisationService from 'V3Services/LocalisationService';
import LoggingService from 'V3Services/LoggingService';
import TenantsServiceApiClient from 'V3Services/TenantsService/TenantsServiceApiClient';
import * as constants from 'V3Utilities/constants';
import { ERRORS, ROUTES } from 'V3Utilities/constants';
import { sortTenantsWithMSGraph } from 'V3Utilities/handleTenants';

import { Progress } from '../common';

import local from './local.scss';

const popUpUrl = 'authorizePopUp.html';

const Login = ({
    setRoute,
    setUser,
    inProgress,
    CBLoginError,
    isOffice365LoginError,
    isCBLoginSuccess,
    loginToCBSuccess,
    tenantsSelection,
    authSchemeSelection,
    setLoginCBFailed,
    setLoginLoading,
    setLoginTenantsSelectionActive,
    setLoginTenantsSelectionDone,
    saveOfficeLoginDetails,
    setLoginAuthSchemesSelectionActive,
    setLoginTenantsSelectionIsActive,
}: LoginProps): JSX.Element => {
    const email = Office.context.mailbox.userProfile.emailAddress;
    const [selectedTenantId, setSelectedTenantId] = useState<string>('');
    const [selectedTenant, setSelectedTenant] = useState<AppTenant>(null);
    const [selectedAuthSchemeId, setSelectedAuthSchemeId] = useState<string>('');
    const [selectedAuthScheme, setSelectedAuthScheme] = useState<AuthScheme>(null);
    const localisationService: LocalisationService = LocalisationService.getInstance();
    let loginDialog: any;

    const getTenants = async (): Promise<AppTenant[]> => {
        const tenantsResponse = await TenantsServiceApiClient.getIdentityTenants(email);
        return sortTenantsWithMSGraph(tenantsResponse.identityTenants);
    };

    const handleTenantSelection = (tenants: AppTenant[]) => {
        const tenantsOptions = tenants.map((tenant) => ({ key: tenant.id as unknown as string, text: tenant.name }));
        setLoginTenantsSelectionActive({ tenants, tenantsOptions });
    };

    const loginAndAuthenticate = async (): Promise<void> => {
        try {
            setLoginLoading(true);
            const tenants = await getTenants();
            if (tenants.length > 1) {
                handleTenantSelection(tenants);
            } else if (tenants.length === 1) {
                const tenant = tenants[0];
                setSelectedTenantId(tenant.id.toString());
                setSelectedTenant(tenant);
            } else {
                setLoginCBFailed(constants.ERRORS.loginToCB.TENANTS);
            }
        } catch (e) {
            ErrorHandlingService.HandleSystemError('L/LR', e);
        }
    };

    const handleAuthSchemesSelection = (authSchemes: AuthScheme[]) => {
        const authSchemesOptions = authSchemes.map((authScheme) => ({ key: authScheme.id, text: authScheme.name }));
        setLoginAuthSchemesSelectionActive({ authSchemes, authSchemesOptions });
    };

    const getPopUpPageUrl = (authScheme: AuthScheme, email: string, isServiceAccount: boolean): string => {
        const { id } = authScheme;
        const popUpPagePathName = window.location.pathname.replace('index.html', popUpUrl);
        const popUpPageUrl = `${window.location.origin}${popUpPagePathName}?OutlookEmailAddress=${email}&IsServiceAccount=${isServiceAccount}&AuthSchemeId=${id}&TenantId=${selectedTenantId}`;
        return popUpPageUrl;
    };

    const popUpOfficeLoginWindow = (authScheme: AuthScheme, email: string, isServiceAccount: boolean): void => {
        const popUpPageUrl = getPopUpPageUrl(authScheme, email, isServiceAccount);
        Office.context.ui.displayDialogAsync(popUpPageUrl, { height: 70, width: 60 }, (result) => {
            loginDialog = result.value;
            loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, (asyncResult: any) => processMessage(asyncResult));
            loginDialog.addEventHandler(Office.EventType.DialogEventReceived, (asyncResult: any) => dialogCallback(asyncResult));
        });
    };

    const loginToTenant = () => {
        setLoginTenantsSelectionDone({ tenant: selectedTenant });
        popUpOfficeLoginWindow(selectedAuthScheme, email, false);
    };

    const showOffice365LoginError = (error: string): void => {
        ErrorHandlingService.HandleSystemError('L/SOLE', error);
    };

    const navigateBookingSelectionView = (): void => {
        setRoute(ROUTES.SERVICE_SELECTION);
    };

    const handleLoginToCB = async (idpToken: string, email: string, msGraphRefreshToken: string, msGraphToken: string) => {
        try {
            const loginResponse = await AuthenticationServiceApi.loginToCB({
                userName: email,
                msGraphRefreshToken,
                idpToken,
                tenantId: selectedTenantId,
                authSchemeId: selectedTenant.authSchemes[0].id,
                authSchemeName: selectedTenant.authSchemes[0].name,
                msGraphToken,
            });
            if (!loginResponse.errors.length && loginResponse.accessToken) {
                const {
                    accessToken,
                    email,
                    company,
                    id,
                    department,
                    tenantId,
                    firstname,
                    mobile,
                    siteId,
                    surname,
                    title,
                    is24HoursTimeFormat,
                    calendarId,
                } = loginResponse;
                loginToCBSuccess({ username: email, accessToken });
                const user = {
                    id,
                    tenantId,
                    siteId,
                    title,
                    firstname,
                    surname,
                    company,
                    department,
                    mobile,
                    email,
                    is24HoursTimeFormat,
                    calendarId,
                };
                setUser(user);
                navigateBookingSelectionView();
            } else {
                setLoginCBFailed(ERRORS.loginToCB.CREDENTIALS);
            }
        } catch (ex) {
            setLoginCBFailed(ERRORS.loginToCB.CREDENTIALS);
            showOffice365LoginError(ex.message);
            ErrorHandlingService.HandleSystemError('L/PM');
        }
    };

    const processMessage = async (args): Promise<void> => {
        try {
            const parsedArgs = JSON.parse(args.message);

            if (parsedArgs && parsedArgs.isLoginSuccess) {
                loginDialog.close();

                const { msgraphToken, idpToken, msgraphRefreshToken } = parsedArgs;
                const email = Office.context.mailbox.userProfile.emailAddress;

                await saveOfficeLoginDetails(email, msgraphToken);
                await handleLoginToCB(idpToken, email, msgraphRefreshToken, msgraphToken);
            } else {
                LoggingService.Error('Login.processMessage - Login failed');
                showOffice365LoginError(parsedArgs.error);
            }
        } catch (ex) {
            showOffice365LoginError(ex.message);
            ErrorHandlingService.HandleSystemError('L/PM');
        }
    };

    const dialogCallback = async (asyncResult): Promise<void> => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            switch (asyncResult.error.code) {
                case 12004:
                    LoggingService.Error('Login.dialogCallback - Domain is not trusted');
                    break;
                case 12005:
                    LoggingService.Error('Login.dialogCallback - HTTPS is required');
                    break;
                case 12007:
                    LoggingService.Error('Login.dialogCallback - A dialog is already opened');
                    break;
                default:
                    LoggingService.Error('Login.dialogCallback - Error', asyncResult.error.message);
                    break;
            }
        }
    };

    useEffect(() => {
        if (selectedTenant && selectedAuthScheme) {
            loginToTenant();
        } else if (selectedTenant) {
            if (selectedTenant.authSchemes.length === 1) {
                setSelectedAuthScheme(selectedTenant.authSchemes[0]);
            } else {
                handleAuthSchemesSelection(selectedTenant.authSchemes);
            }
        }
        setLoginTenantsSelectionIsActive({ isActive: false });
    }, [selectedTenant, selectedAuthScheme]);

    if (tenantsSelection.active) {
        const onChange = (e: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
            setSelectedTenantId(option.key);
        };
        return (
            <div className="ms-Grid" dir="ltr">
                <ChoiceGroup
                    selectedKey={selectedTenantId}
                    options={tenantsSelection.tenantsOptions}
                    label="Choose a tenant to log in"
                    onChange={onChange}
                    style={{ marginBottom: 10 }}
                    id="login__choice-group_choose-tenant"
                />
                <PrimaryButton
                    text="Next"
                    style={{ width: '100%' }}
                    onClick={() => {
                        setSelectedTenant(tenantsSelection.tenants.find((tenant) => (tenant.id as unknown as string) === selectedTenantId));
                    }}
                    id="login__primary-button_sign-in-option"
                />
            </div>
        );
    }

    if (authSchemeSelection.active) {
        const onChange = (e: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
            setSelectedAuthSchemeId(option.key);
        };

        return (
            <div className="ms-Grid" dir="ltr">
                <ChoiceGroup
                    selectedKey={selectedAuthSchemeId}
                    options={authSchemeSelection.authSchemesOptions}
                    label="Choose auth scheme to log in"
                    onChange={onChange}
                    style={{ marginBottom: 10 }}
                    id="login__choice-group_choose-auth-scheme"
                />
                <PrimaryButton
                    data-automation-id="Sign-in-option"
                    text="Next"
                    style={{ width: '100%' }}
                    onClick={() => {
                        setSelectedAuthScheme(authSchemeSelection.authSchemes.find((authScheme) => authScheme.id === selectedAuthSchemeId));
                    }}
                    id="login__primary-button_sign-in"
                />
            </div>
        );
    }

    if (inProgress) {
        return <Progress id="login__primary-progress" />;
    }

    return (
        <div className={local.loginContainer}>
            {!isCBLoginSuccess && (
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className={classnames(local.logo, 'ms-u-fadeIn500')}>
                            <img height="100" src="assets/cloudbooking-full-logo.png" alt="Cloudbooking" id="login__img_cb-full-logo" />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <PrimaryButton
                                id="login__img_cb-full-logo-sign-in"
                                text="LOG IN"
                                style={{ width: '100%' }}
                                onClick={loginAndAuthenticate}
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <br />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            {CBLoginError.isCBLoginError && (
                                <MessageBar
                                    id="login__message-bar_login-error"
                                    messageBarType={MessageBarType.error}
                                    isMultiline={false}
                                    dismissButtonAriaLabel="Close"
                                >
                                    {CBLoginError.type === 'credentials'
                                        ? localisationService.strings.loginErrorMessage
                                        : localisationService.strings.loginTenantsErrorMessage}
                                </MessageBar>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {isCBLoginSuccess && (
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <MessageBar messageBarType={MessageBarType.success} isMultiline={false} id="login__message-bar_login-success">
                                You have successfully logged into {localisationService.strings.cloudbooking}
                            </MessageBar>
                        </div>
                    </div>

                    {isOffice365LoginError && (
                        <>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12">
                                    <Separator> </Separator>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12">
                                    <MessageBar
                                        messageBarType={MessageBarType.error}
                                        isMultiline={false}
                                        dismissButtonAriaLabel="Close"
                                        id="login__message-bar_auth-failed"
                                    >
                                        Authorization failed.Please try again.
                                    </MessageBar>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default Login;
