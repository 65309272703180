import { DateTime } from 'luxon';
import ConfirmBookingResponse from 'V3Models/BookingService/ConfirmBookingResponse';
import { Room } from 'V3Models/Domain/Room';
import { BookingService } from 'V3Services/BookingService';
import BookingServiceApiClient from 'V3Services/BookingService/BookingServiceApiClient';
import OutlookService from 'V3Services/OutlookService';
import LocalisationService from 'V3Services/LocalisationService';
import TimezoneService from 'V3Services/TimezoneService';
import appointment from 'V3Stores/appointment';
import bookingForm from 'V3Stores/booking';
import { BOOKING_TYPE } from 'V3Stores/booking/types';
import errorState from 'V3Stores/errorState';
import { ErrorLevel } from 'V3Stores/errorState/actionTypes';
import { AppThunk } from 'V3Stores/index';
import navigation from 'V3Stores/navigation';
import organisationalStructure from 'V3Stores/organisationalStructure';
import user from 'V3Stores/user';
import { convertBookingDataToUpdateRequestDTO, convertBookingFormToBookRequestDTO } from 'V3Utilities/bookingUtils';
import { TABS } from 'V3Utilities/constants';
import { getUserNameSurname } from 'V3Utilities/userInfo/getUserName&Surname';
import { SiteWithTZ as SiteWithTZ } from 'V3Models/EntitiesService/SearchEntitiesRequest';
import Booking from 'V3Models/BookingService/Booking/Booking';
import { showNotificationForBooking, updateOutlookAppointment } from './outlookActions';

const timeZoneService = TimezoneService.getInstance();

export const saveBooking = (): AppThunk => async (dispatch, getState) => {         
    dispatch(bookingForm.store.setBookingInProgress(true));
    dispatch(navigation.store.setPreviousTab(TABS.BOOKING));

    const booking = bookingForm.store.getBooking(getState()); 
    const selectedRoom = bookingForm.store.getSelectedRoom(getState()); 

    const appointmentInfo = await OutlookService.getAppointmentInfoFromOutlook(appointment);
    const updatedBooking = {
        ...booking,
        title: appointmentInfo.title
    }
    dispatch(bookingForm.store.setBooking(updatedBooking));
    dispatch(appointment.store.setAppointment(appointmentInfo))

    const isUpdate = bookingForm.store.getIsEdit(getState());
    const isValid = isUpdate ? true : validateSaveBooking(updatedBooking);
    
    if (isValid) {
        let bookingRequest;
        
        const site = organisationalStructure.store.getSelectedSites(getState())[0];
        const siteWithTZ  = <SiteWithTZ> {
            siteId: site.siteID,
            timezone: site.timeZoneName
        }

        if (isUpdate) {
            bookingRequest = convertBookingDataToUpdateRequestDTO(booking, appointmentInfo, siteWithTZ);
        } else {
            const userData = getUserNameSurname(user.store.getUser(getState()));
            bookingRequest = convertBookingFormToBookRequestDTO(booking, selectedRoom.roomID, appointmentInfo, userData, siteWithTZ);
        }        

        dispatch(saveBookingRequest(bookingRequest, isUpdate, selectedRoom));
    }
};

const saveBookingRequest =
    (bookingData, isUpdate: boolean, room: Room): AppThunk =>
    async (dispatch, getState) => {        
        const appointmentInfo = appointment.store.getAppointment(getState());

        let effectiveMaxBookingDateUTCDateTime: DateTime;
        if (room.effectiveMaxBookingDateUTC) {
            effectiveMaxBookingDateUTCDateTime = DateTime.fromISO(room.effectiveMaxBookingDateUTC);
        }

        const bookingInNoticePeriod = appointmentInfo.endDTMUTC < effectiveMaxBookingDateUTCDateTime;

        let errorMessage = '';

        if (!bookingInNoticePeriod && effectiveMaxBookingDateUTCDateTime) {
            const outlookTimeZone = Office.context.mailbox.userProfile.timeZone;
            const outlookTimeZoneIANA = timeZoneService.getIANAFromWindows(outlookTimeZone);
            const effectiveMaxBookingDateOutlook = timeZoneService.getSiteTimeFromUTC(effectiveMaxBookingDateUTCDateTime, outlookTimeZoneIANA);
            errorMessage = `Room must be booked before ${effectiveMaxBookingDateOutlook.toFormat('dd LLLL yyyy')}`;
        }

        const bookingType = isUpdate ? BOOKING_TYPE.UPDATE : BOOKING_TYPE.CREATE;

        try {
            let response: ConfirmBookingResponse = null;

            if (errorMessage === '') {
            
                response = await BookingServiceApiClient.confirmBookingReserve(bookingData); 

                if (response?.errors &&  response.errors.length > 0) {
                    errorMessage = getErrorMessageFromResponse(response, room);           
                }
                else {
                    Office.context.mailbox.item.start.setAsync(new Date(response.startDateTime), null)
                    Office.context.mailbox.item.end.setAsync(new Date(response.endDateTime), null) 
                }
            }

            if (errorMessage.length > 0) {
                
                let errMsg = errorMessage;
                errMsg = handleDateTimeErrorMessage(errorMessage, getState);
                
                dispatch(navigation.store.setAvailableTabs([TABS.BOOKING, TABS.ERROR]));
                dispatch(navigation.store.setCurrentTab(TABS.ERROR));
                dispatch(
                    errorState.store.setError({
                        errorLevel: ErrorLevel.WARNING,
                        errorMessage: errMsg,
                    })
                );

            } 
            else {

                const userData = getUserNameSurname(user.store.getUser(getState()));

                await updateOutlookAppointment(response?.booking, userData);
                await BookingService.saveBookingInfoAsOutlookCustomProperty(response?.booking ?? null);

                response.booking.resources[0].effectiveMaxBookingDateUTC = room.effectiveMaxBookingDateUTC;

                dispatch(bookingForm.store.setBookingComplete({ type: bookingType, item: response.booking }));
                await showNotificationForBooking(isUpdate);
            }

            dispatch(bookingForm.store.setBookingInProgress(false));

        } catch (e) { }
    };

const validateSaveBooking = (bookingFormValues: Booking) => {
    const errors = [];
    const { id, title } = bookingFormValues;
    if (!title && title !== '') {
        errors.push('title');
    }
    if (!id) {
        errors.push('id');
    }    

    if (errors.length) {
        return false;
    }

    return true;
};

const getErrorMessageFromResponse = (response: ConfirmBookingResponse, room: Room) => {

    const localisationService: LocalisationService = LocalisationService.getInstance();

    let errorMsg = response.errors[0]?.errorMessage;
    if (!errorMsg && response.errors.length > 1) {
        errorMsg = response.errors[1]?.errorMessage;
    }

    if (!errorMsg) {
        errorMsg = localisationService.strings.defaultErrorCreatingBooking;
    }

    if (errorMsg.toLowerCase().includes('conflict')) {
        errorMsg = `${room.roomName} ${localisationService.strings.conflictErrorCreatingBooking}`;
    }

    if (errorMsg.toLowerCase().includes('forbidden')) {
        errorMsg = localisationService.strings.forbiddenErrorCreatingBooking;
    }

    return errorMsg;
}


const handleDateTimeErrorMessage = (errorMessage: string, getState) => {
    
    let errMsg = errorMessage;
    
    if(errorMessage.includes('|')){
        const errSections = errorMessage.split('|');
        const is24 = user.store.getUser(getState()).is24HoursTimeFormat;

        const startDateTime = DateTime.fromISO(errSections[1]);
        const startDateTimeString = is24 ? startDateTime.toFormat('HH:ss') : startDateTime.toFormat('hh:ss a');
        
        const endDateTime = DateTime.fromISO(errSections[3]);
        const endDateTimeString = is24 ? endDateTime.toFormat('HH:ss') : endDateTime.toFormat('hh:ss a');

        errMsg = `${errSections[0]}${startDateTimeString}${errSections[2]}${endDateTimeString}${errSections[4]}`
    }

    return errMsg;
}