import React from 'react';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classnames from 'classnames';

import local from './buttons.scss';

interface DefinedButtonProps {
    onClick: Function;
    disabled?: boolean;
    id?: string;
    text?: string;
}

interface BaseButtonProps extends DefinedButtonProps {
    className?: string;
    startIcon?: any;
    endIcon?: any;
    children?: any | any[];
}

// base button

const BaseButton = (props: any): JSX.Element => {
    const { className, startIcon, endIcon, onClick, disabled, children, id } = props;
    return (
        <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={startIcon}
            endIcon={endIcon}
            className={classnames(local.button, className)}
            onClick={onClick}
            disableElevation
            disabled={disabled}
            id={id}
        >
            {children}
        </Button>
    );
};

// color buttons

export const BlueButton = (props: BaseButtonProps): JSX.Element => {
    const { className, startIcon, endIcon, onClick, disabled, children, id} = props;
    return (
        <BaseButton id={id} className={classnames(local.blue, className)} startIcon={startIcon} endIcon={endIcon} onClick={onClick} disabled={disabled}>
            {children}
        </BaseButton>
    );
};

export const GrayButton = (props: BaseButtonProps): JSX.Element => {
    const { className, startIcon, endIcon, onClick, disabled, children, id } = props;
    return (
        <BaseButton id={id} className={classnames(local.gray, className)} startIcon={startIcon} endIcon={endIcon} onClick={onClick} disabled={disabled}>
            {children}
        </BaseButton>
    );
};

export const YellowButton = (props: BaseButtonProps): JSX.Element => {
    const { className, startIcon, endIcon, onClick, disabled, children, id } = props;
    return (
        <BaseButton id={id} className={classnames(local.yellow, className)} startIcon={startIcon} endIcon={endIcon} onClick={onClick} disabled={disabled}>
            {children}
        </BaseButton>
    );
};

export const RedButton = (props: BaseButtonProps): JSX.Element => {
    const { className, startIcon, endIcon, onClick, disabled, children, id } = props;
    return (
        <BaseButton id={id} className={classnames(local.red, className)} startIcon={startIcon} endIcon={endIcon} onClick={onClick} disabled={disabled}>
            {children}
        </BaseButton>
    );
};

// defined buttons

export const ApplyButton = ({ onClick, disabled, id }: DefinedButtonProps): JSX.Element => {
    return (
        <BlueButton id={id} onClick={onClick} startIcon={<ChevronLeftIcon />} disabled={disabled} className={local.buttonBarButton}>
            Apply
        </BlueButton>
    );
};

export const BackButton = ({ onClick, disabled, id }: DefinedButtonProps): JSX.Element => {
    return (
        <GrayButton id={id} onClick={onClick} startIcon={<ChevronLeftIcon />} disabled={disabled} className={local.buttonBarButton}>
            Back
        </GrayButton>
    );
};

export const BookButton = ({ onClick, disabled, id }: DefinedButtonProps): JSX.Element => {
    return (
        <YellowButton id={id} onClick={onClick} disabled={disabled} className={local.buttonBarButton}>
            Book
        </YellowButton>
    );
};

export const CancelButton = ({ onClick, disabled, id }: DefinedButtonProps): JSX.Element => {
    return (
        <GrayButton id={id} onClick={onClick} disabled={disabled} className={local.buttonBarButton}>
            Cancel
        </GrayButton>
    );
};

export const DeleteButton = ({ onClick, disabled, id, text = 'Delete'}: DefinedButtonProps): JSX.Element => {
    return (
        <RedButton id={id} onClick={onClick} disabled={disabled} className={local.buttonBarButton}>
            {text}
        </RedButton>
    );
};

export const DoneButton = ({ onClick, disabled, id }: DefinedButtonProps): JSX.Element => {
    return (
        <GrayButton id={id} onClick={onClick} startIcon={<ChevronLeftIcon />} disabled={disabled} className={local.buttonBarButton}>
            Done
        </GrayButton>
    );
};

export const NextButton = ({ onClick, disabled, id }: DefinedButtonProps): JSX.Element => {
    return (
        <BlueButton id={id} onClick={onClick} endIcon={<ChevronRightIcon />} disabled={disabled} className={local.buttonBarButton}>
            Next
        </BlueButton>
    );
};

export const OkButton = ({ onClick, disabled, id }: DefinedButtonProps): JSX.Element => {
    return (
        <BlueButton id={id} onClick={onClick} disabled={disabled} className={local.buttonBarButton}>
            OK
        </BlueButton>
    );
};

export const ResetButton = ({ onClick, disabled, id }: DefinedButtonProps): JSX.Element => {
    return (
        <GrayButton id={id} onClick={onClick} disabled={disabled} className={local.buttonBarButton}>
            Reset
        </GrayButton>
    );
};

export const SaveButton = ({ onClick, disabled, id }: DefinedButtonProps): JSX.Element => {
    return (
        <YellowButton id={id} onClick={onClick} disabled={disabled} className={local.buttonBarButton}>
            Save
        </YellowButton>
    );
};

export const EditBookingButton = ({ onClick, disabled, id}: DefinedButtonProps): JSX.Element => {
    return (
        <YellowButton id={id} onClick={onClick} disabled={disabled} className={local.buttonBarButton}>
            Edit Booking
        </YellowButton>
    );
};
