import { EntityInfo, EntityInfo as RoomInfoInterface } from 'V3Models/Domain/EntityInfo';
import { Room } from 'V3Models/Domain/Room';
import { Site } from 'V3Models/Domain/Site';
import { Action } from 'V3Utilities/Action';
import { REDUCER_NAMES } from 'V3Utilities/constants';

const reducerName = `${REDUCER_NAMES.ROOMS}/${REDUCER_NAMES.ORGANISATION_STRUCTURE}`;

export const REFERENCE_DATA_LOAD_START = `${reducerName}/REFERENCE_DATA_LOAD_START`;
export const REFERENCE_DATA_LOAD_END = `${reducerName}/REFERENCE_DATA_LOAD_END`;
export const REFERENCE_DATA_LOAD_RESET = `${reducerName}/REFERENCE_DATA_LOAD_RESET`;
export const REFERENCE_DATA_INITIALISE = `${reducerName}/REFERENCE_DATA_INITIALISE`;

export const REFERENCE_DATA_SITES_LOAD_COMPLETE = `${reducerName}/SITES_LOAD_COMPLETE`;
export const REFERENCE_DATA_SITES_LOAD_RESET = `${reducerName}/SITES_LOAD_RESET`;

export const REFERENCE_DATA_ROOMS_LOAD_COMPLETE = `${reducerName}/ROOMS_LOAD_COMPLETE`;
export const REFERENCE_DATA_ROOMS_LOAD_RESET = `${reducerName}/ROOMS_LOAD_RESET`;

export const REFERENCE_DATA_SET_SELECTED_SITES = `${reducerName}/SET_SELECTED_SITES`;
export const REFERENCE_DATA_SELECT_SITES_RESET = `${reducerName}/SELECT_SITES_RESET`;

export const REFERENCE_DATA_ROOM_INFO_LOAD_COMPLETE = `${reducerName}/ROOM_INFO_LOAD_COMPLETE`;
export const REFERENCE_DATA_ROOM_INFO_LOAD_RESET = `${reducerName}/ROOM_INFO_LOAD_RESET`;

export const REFERENCE_DATA_ROOM_BOOKING_INFO_LOAD_COMPLETE = `${reducerName}/ROOM_BOOKING_INFO_LOAD_COMPLETE`;

export const REFERENCE_DATA_FIXED_RESOURCES_LOAD_COMPLETE = `${reducerName}/FIXED_RESOURCES_LOAD_COMPLETE`;
export const REFERENCE_DATA_FIXED_RESOURCES_LOAD_RESET = `${reducerName}/FIXED_RESOURCES_LOAD_RESET`;

export const REFERENCE_DATA_SET_SITES_LOADING_STATUS = `${reducerName}/SET_SITES_LOADING_STATUS`;
export const REFERENCE_DATA_SET_ROOMS_LOADING_STATUS = `${reducerName}/SET_ROOMS_LOADING_STATUS`;

export const SET_ROOM_IMAGE_URL = `${reducerName}/SET_ROOM_IMAGE_URL`;

export type ReferenceDataLoadStartAction = Action;
export type ReferenceDataLoadEndAction = Action;
export type ReferenceDataLoadResetAction = Action;
export type ReferenceDataInitialiseAction = Action;
export type PortalsLoadResetAction = Action;

export interface SetRoomImageURLAction extends Action {
    imageURL: string;
}

export interface SitesLoadCompleteAction extends Action {
    sites: Site[];
}

export type SitesLoadResetAction = Action;

export interface SetSitesLoadingStatusAction extends Action {
    loadingStatus: string;
}

export interface SetRoomsLoadingStatusAction extends Action {
    loadingStatus: string;
}

export interface RoomsLoadCompleteAction extends Action {
    rooms: Room[];
}

export type RoomsLoadResetAction = Action;

export interface RoomInfoLoadCompleteAction extends Action {
    room: EntityInfo;
}

export type RoomInfoLoadResetAction = Action;

export type RoomBookingInfoLoadReset = Action;

export interface SetSelectedSitesAction extends Action {
    selectedSiteIDs: string[];
}

export type ResetSelectedSitesAction = Action;

export type OrganisationalStructureActionType =
    | ReferenceDataLoadStartAction
    | ReferenceDataLoadEndAction
    | ReferenceDataLoadResetAction
    | ReferenceDataInitialiseAction
    | SitesLoadCompleteAction
    | SitesLoadResetAction
    | RoomsLoadCompleteAction
    | RoomsLoadResetAction
    | RoomInfoLoadCompleteAction
    | RoomInfoLoadResetAction
    | RoomBookingInfoLoadReset
    | SetSelectedSitesAction
    | ResetSelectedSitesAction
    | SetSitesLoadingStatusAction
    | SetRoomImageURLAction;


export interface OrganisationalStructureStateInterface {
    loadingStatus: string;
    sites: {
        items: Site[];
        loadingStatus: string;
    };
    rooms: {
        items: Room[];
        loadingStatus: string;
    };
    attendees: any;
    roomInfo: {
        items: RoomInfoInterface[];
        loadingStatus: string;
        imageURL: string
    };
    selectedSiteIDs: string[];
}
