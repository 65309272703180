import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { ReactComponent as WarningIcon } from '../../assets/SVGs/WarningExclaim.svg';
import { Panel } from 'V3Components/common';
import { BlueButton, RedButton } from 'V3Components/common/buttons';
import LocalisationService from 'V3Services/LocalisationService';

import { BookingDeletePanelProps } from '.';

import local from './local.scss';

const BookingDeletePanel = ({ isOpen, deleteBooking }: BookingDeletePanelProps): JSX.Element => {
    const localisationService = LocalisationService.getInstance();

    const buttons = (
        <>
            <RedButton id="booking-delete-panel__Button_Red" onClick={() => deleteBooking(true)} className={local.button}>
                Yes
            </RedButton>
            <BlueButton id="booking-delete-panel__Button_Blue" onClick={() => deleteBooking(false)} className={local.button}>
                No
            </BlueButton>
        </>
    );

    return (
        <Panel icon={DeleteIcon} buttons={buttons} isOpen={isOpen} >
            <div className={local.bookingDeleteContainer}>
                <WarningIcon id={'booking-delete-panel__warning-icon'} style={{ width: '30%', height: 'auto', textAlign:'center'}}/>
                <h3 id='booking-delete-panel__h3'>{localisationService.strings.srbCancellationWarning}</h3>
            </div>
        </Panel>
    );
};

export default BookingDeletePanel;
