import {
    ACTION_TYPES,
    RemoveTabAction,
    SetAvailableTabsAction,
    SetCurrentTabAction,
    SetInfoMessageAction,
    SetOperationInProgressAction,
    SetPreviousTabAction,
    SetShowAppointmentChangedAction,
    SetShowDeleteBookingAction,
} from './types';

export const setAvailableTabs = (tabs: string[]): SetAvailableTabsAction => ({
    type: ACTION_TYPES.SET_AVAILABLE_TABS,
    tabs,
});

export const setCurrentTab = (tab: string): SetCurrentTabAction => ({
    type: ACTION_TYPES.SET_CURRENT_TAB,
    tab,
});

export const setShowDeleteBooking = (value: boolean): SetShowDeleteBookingAction => ({
    type: ACTION_TYPES.SET_SHOW_DELETE_BOOKING,
    value,
});

export const setShowAppointmentChanged = (value = true): SetShowAppointmentChangedAction => ({
    type: ACTION_TYPES.SET_SHOW_APPOINTMENT_CHANGED,
    value,
});

export const setInfoMessage = (value = ''): SetInfoMessageAction => ({
    type: ACTION_TYPES.SET_INFO_MESSAGE,
    value,
});

export const setOperationInProgressAction = (value = false): SetOperationInProgressAction => ({
    type: ACTION_TYPES.SET_OPERATION_IN_PROGRESS,
    value,
});

export const removeTab = (tab: string): RemoveTabAction => ({
    type: ACTION_TYPES.REMOVE_TAB,
    tab,
});

export const setPreviousTab = (tab: string): SetPreviousTabAction => ({
    type: ACTION_TYPES.PREVIOUS_TAB,
    tab,
})