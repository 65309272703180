import AttendeeCount from './AttendeeCount';
import * as constants from './constants';
import OnBehalfOf from './OnBehalfOf';

export default {
    components: {
        AttendeeCount,
        OnBehalfOf,
    },
    constants,
};
