import _ from 'lodash';
import { findOneIana } from 'windows-iana';
import { timezoneAliases } from 'V3Utilities/microsoftTimeZones';
import { DateTime } from 'luxon';

export default class TimezoneService {
    private static instance: TimezoneService;

    public static getInstance(): TimezoneService {
        if (!TimezoneService.instance) {
            TimezoneService.instance = new TimezoneService();
        }
        return TimezoneService.instance;
    }

    public getIANAFromWindows = (windowsTimezone: string): string => {
        if (!windowsTimezone || windowsTimezone.length === 0) throw new Error('You must supply a valid Windows Timezone in IANA format');
        const ianaTimezone = findOneIana(timezoneAliases[windowsTimezone] || windowsTimezone);
        if (!ianaTimezone) throw new Error('Unable to find timezone');
        return ianaTimezone;
    };

    public getSiteTimeFromUTC = (utcTime: DateTime, siteTimezone: string): DateTime => {        
        if (_.isNil(utcTime)) throw new Error('You must supply a valid UTC Time');
        if (!utcTime.isValid) throw new Error('You must supply a valid Timezone in IANA format');

        if (!siteTimezone || siteTimezone.length === 0) {
            throw new Error('You must supply a valid Site Timezone in IANA format');
        }
        return utcTime.setZone(siteTimezone);
    };

    public getSiteTimeFromUTCString = (utcTime: DateTime, siteTimezone: string): DateTime => {
        if (!utcTime) throw new Error('You must supply a valid UTC Time');
        const utcDateTimeString = this.getDateTimeStringFromUTC(utcTime);
        return this.getSiteTimeFromUTC(utcDateTimeString, siteTimezone);
    };

    public getDateTimeStringFromUTC = (utcTime: DateTime): DateTime => {            
        utcTime = utcTime.setZone('utc');
        if (!utcTime) throw new Error('Invalid Date Time Format');
        return utcTime;
    };
}