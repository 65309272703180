import { connect } from "react-redux";
import bookingForm from "V3Stores/booking";
import { RootState } from "V3Stores/index";
import navigation from "V3Stores/navigation";
import organisationalStructure from "V3Stores/organisationalStructure";
import Equipment from "./Equipment";

const mapStateToProps = (state: RootState) =>  ({
    equipment: organisationalStructure.store.getRoomInfo(bookingForm.store.getSelectedRoom(state)?.roomID)(state)?.equipment
});

const mapDispatchToProps = {
    handleEquipmentDone: navigation.store.setCurrentTab
}

export default connect(mapStateToProps, mapDispatchToProps)(Equipment);

export type EquipmentProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
